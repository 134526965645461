<div class="fixed inset-0 z-50" [class.hidden]="!isOpen">
    <!-- Backdrop -->
    <div
      class="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300"
      (click)="closeMenu()"
    ></div>

    <!-- Slide-out menu -->
    <div
      class="fixed inset-y-0 left-0 w-full bg-white transform transition-transform duration-300 ease-in-out"
      [class.translate-x-0]="isOpen"
      [class.-translate-x-full]="!isOpen"
    >
      <!-- Header with close button -->
      <div class="flex justify-end p-4 border-b">
        <button
          (click)="closeMenu()"
          class="p-2 hover:bg-gray-50 rounded-full"
          aria-label="Close menu"
        >
          <fa-icon [icon]="['fas', 'times']" class="h-6 w-6 text-gray-500"></fa-icon>
        </button>
      </div>

      <!-- Navigation Menu -->
      <div class="overflow-y-auto h-full pb-20">
        <!-- Top section -->
        <div class="border-b">
          <button
            *ngFor="let item of navMenu"
            (click)="handleNavigation(item)"
            class="flex items-center justify-between w-full p-4 text-left hover:bg-gray-50 transition-colors"
          >
            <span class="text-gray-700">{{item.label}}</span>
            <fa-icon [icon]="['fas', 'chevron-right']" class="h-5 w-5 text-gray-400"></fa-icon>
          </button>
        </div>

        <!-- Bottom section -->
        <div class="pt-2">
          <button
            (click)="handleContactUs()"
            class="flex items-center justify-between w-full p-4 text-left hover:bg-gray-50 transition-colors"
          >
            <span class="text-gray-700">Contact Us</span>
          </button>
          <button
            (click)="handleOrderStatus()"
            class="flex items-center justify-between w-full p-4 text-left hover:bg-gray-50 transition-colors"
          >
            <span class="text-gray-700">Order Status</span>
          </button>
        </div>
      </div>
    </div>
</div>