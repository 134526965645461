<div class="collections-carousel" *ngIf="collectionsList.length > 0">
    <div class="group-name">
      <p>Shop by {{ groupName }}</p>
    </div>
    <div class="collection-list-container">
      <vsf-collection-card-v2 *ngFor="let collection of displayedCollections; trackBy: trackByCollectionId"  class="collection-card"
        [collection]="collection">
      </vsf-collection-card-v2>
    </div>
    <button class="arrow arrow-left" (click)="showPreviousItems()" *ngIf="startIndex > 0">
      <fa-icon icon="chevron-left"></fa-icon>
    </button>
    <button class="arrow arrow-right" (click)="showNextItems()" *ngIf="startIndex + itemsPerPage < collectionsList.length">
      <fa-icon icon="chevron-right"></fa-icon>
    </button>
</div>