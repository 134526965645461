import { Component, OnInit, ElementRef, Renderer2, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { SearchProductsQuery, SearchProductsQueryVariables, SearchVariantsQuery, SearchVariantsQueryVariables } from '../../../common/generated-types';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SEARCH_PRODUCTS, SEARCH_VARIANTS } from '../../../common/graphql/documents.graphql';
import { NGXLogger } from 'ngx-logger';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { CollectionItemOrderData } from '../../../common/interfaces';
import { CollectionItemType } from '../../../common/enums';

@Component({
  selector: 'vsf-product-carousel-v2',
  templateUrl: './product-carousel-v2.component.html',
  styleUrls: ['./product-carousel-v2.component.scss']
})
export class ProductCarouselV2Component implements OnInit, OnDestroy {
  products$: any;
  displayedItems: SearchProductsQuery['search']['items'] | SearchVariantsQuery['search']['items'] = [] ;
  itemIdsOrder: string[] = [];
  startIndex = 0;
  private destroy$: Subject<void> = new Subject<void>();

  @Input() title: string;
  @Input() subtitle: string;
  @Input() displayType: CollectionItemType = CollectionItemType.Product;
  @Input() collectionSlug: string;
  @Input() collectionName: string;
  @Input() collectionItemOrder: string;
  @Input() channelId: string;
  @Input() itemsPerSlide: number;
  @Input() displayCarouselTitle: boolean = true;
  @Input() displaySubtitle: boolean = false;
  @Input() displayPrice: boolean = false;
  @Input() displayBuyButton: boolean = false;
  @Input() displayAlternativeImage: boolean = false;
  @Input() imgSize: 'sm-h' | 'sm-v' | 'md' | 'lg' = 'md';

  constructor(
    private dataService: DataService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger,
  ) {}

  ngOnInit(): void {
    this.logger.debug('Product Carousel V2 Component Initializing', this.collectionSlug, this.collectionName);
    if (this.collectionItemOrder) {
      const collectionItemOrderData = safeJSONParse<CollectionItemOrderData>(this.collectionItemOrder, this.logger);
      this.itemIdsOrder = (this.displayType === CollectionItemType.Product) ? (collectionItemOrderData?.productIdsInOrder || []) : (collectionItemOrderData?.variantIdsInOrder || []);
    }
    this.products$ = this.createItemQuery(this.displayType);
    this.products$.subscribe((items : (SearchProductsQuery['search']['items'] | SearchVariantsQuery['search']['items'])) => {
      this.itemsPerSlide = Math.min(this.itemsPerSlide, items.length);
      this.displayedItems = items.slice(this.startIndex, this.startIndex + this.itemsPerSlide);
      this.cdr.detectChanges();
      this.setItemsPerSlide();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createItemQuery<T extends CollectionItemType>(queryType: T) {
    const input = {
      take: 100,
      groupByProduct: queryType === CollectionItemType.Product,
      channelId: this.channelId,
      collectionSlug: this.collectionSlug || '',
    };
    const query = queryType === CollectionItemType.Product ? SEARCH_PRODUCTS : SEARCH_VARIANTS;
    return this.dataService.query<
      SearchProductsQuery | SearchVariantsQuery,
      SearchProductsQueryVariables|SearchVariantsQueryVariables
    >(query, {
      input,
    }).pipe(
      map((data: any) => {
        const items = data.search.items;
        if (this.itemIdsOrder?.length > 0) {
          return this.itemIdsOrder
            .map(id => items.find((item: any) => queryType === CollectionItemType.Product ? item?.productId === id : item?.productVariantId === id))
            .filter(notNullOrUndefined);
        }
        return items;
      }),
      takeUntil(this.destroy$)
    );
  }

  private setItemsPerSlide(): void {
    const slideWidth = `${(100 / this.itemsPerSlide).toString()}%`;
    // Check if elementRef is available and slides exist
    if (this.elementRef && this.elementRef.nativeElement) {
      const slides = this.elementRef.nativeElement.querySelectorAll('.card');
      Array.from(slides).forEach((slide:any) => {
        this.renderer.setStyle(slide, 'flex', `0 0 ${slideWidth}`);
        this.renderer.setStyle(slide, 'max-width', `${slideWidth}`);
      });
    }
  }

  trackByProductId(index: number, item: SearchProductsQuery['search']['items'][number] | SearchVariantsQuery['search']['items'][number]): string {
    if(this.displayType === CollectionItemType.Product) {
      return item.productId;
    } else {
      return item.productVariantId;
    }
  }

  showPreviousItems(): void {
    this.startIndex = Math.max(this.startIndex - this.itemsPerSlide, 0);
    this.updateDisplayedProducts();
  }

  showNextItems(): void {
    this.products$.subscribe((products:(SearchProductsQuery['search']['items'] | SearchVariantsQuery['search']['items'])) => {
      this.startIndex = Math.min(this.startIndex + this.itemsPerSlide, products.length - this.itemsPerSlide);
      this.updateDisplayedProducts();
    });
  }

  private updateDisplayedProducts(): void {
    this.products$.subscribe((products:(SearchProductsQuery['search']['items'] | SearchVariantsQuery['search']['items'])) => {
      if (this.startIndex + this.itemsPerSlide >= products.length) {
        this.startIndex = Math.max(products.length - this.itemsPerSlide, 0);
      }
      this.displayedItems = products.slice(this.startIndex, this.startIndex + this.itemsPerSlide);
      this.cdr.detectChanges();
      this.setItemsPerSlide();
    });
  }
}
