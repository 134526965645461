import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ProductModule } from '../product/product.module';
import { CoreModule } from '../core/core.module';

import { CollectionMainPageComponent } from './collection-main-page/collection-main-page.component';

const DECLARATIONS = [
    CollectionMainPageComponent,
];

@NgModule({
    declarations: DECLARATIONS,
    imports: [
        SharedModule,
        ProductModule,
        CoreModule,
    ],
})
export class CollectionModule {
}
