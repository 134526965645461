<div class="card border rounded bg-white p-4 text-sm mb-2">
    <div class="card-header fulfillment-header">
        <div class="fulfillment-state">
            State: <strong>{{ fulfillment?.state }}</strong>
            <br>
            Updated On: <strong>{{ fulfillment?.updatedAt | date: 'medium' }}</strong>
        </div>
    </div>
    <div class="card-block">
        <div>
            Via: <strong>{{ fulfillment?.method }} </strong>
            <a class="text-blue-500 underline" href="{{ trackingUrl }}" target="_blank"><strong>{{ fulfillment?.trackingCode }}</strong></a>
        </div>
        <div>
            Content:
            <div *ngFor="let item of items">
                <p><strong>{{ item.quantity }} x {{ item.name }}</strong></p>
            </div>
        </div>
    </div>
</div>
