import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../data/data.service';
import { GetActiveChannelQuery } from '../../../common/generated-types';
import { GET_ACTIVE_CHANNEL } from './channel.service.graphql';

@Injectable({
  providedIn: 'root'
})
export class ActiveChannelService {
  activeChannel$: Observable<GetActiveChannelQuery['activeChannel']>;

  constructor(private dataService: DataService) {
    this.activeChannel$ = this.dataService.query<GetActiveChannelQuery>(GET_ACTIVE_CHANNEL)
      .pipe(map(result => result.activeChannel));
  }
}
