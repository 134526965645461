<div class="two-collection-cards">
  <!-- First Collection (Top Row) -->
  <div *ngIf="collectionA" class="collection-card-container">
    <!-- Left Cell: Image -->
    <div class="media-container">
      <img [src]="collectionA?.featuredAsset | assetPreview:'large'" class="full-size aspect-[19/20]" />
    </div>
  </div>
  <div *ngIf="collectionA" class="collection-card-container">
    <!-- Right Cell: Text -->
    <div class="text-container">
      <div class="collection-name">
        <span>{{ collectionA?.name }}</span>
      </div>
      <div class="shop-now-button-wrapper">
        <button
          class="shop-now-button"
          [ngStyle]="{
            'background-color': colorConfig ? colorConfig.secondaryColor : '#B6E7B0',
            'color': colorConfig ? '#000000' : '#FFFFFF'
          }"
          (click)="navigateToCollectionPage(collectionA)"
        >SHOP NOW</button>
      </div>
    </div>
  </div>

  <!-- Second Collection (Bottom Row) -->
  <div *ngIf="collectionB" class="collection-card-container">
    <!-- Left Cell: Text -->
    <div class="text-container">
      <div class="collection-name">
        <span>{{ collectionB?.name }}</span>
      </div>
      <div class="shop-now-button-wrapper">
        <button
          class="shop-now-button"
          [ngStyle]="{
            'background-color': colorConfig ? colorConfig.secondaryColor : '#B6E7B0',
            'color': colorConfig ? '#000000' : '#FFFFFF'
          }"
          (click)="navigateToCollectionPage(collectionB)"
        >SHOP NOW</button>
      </div>
    </div>
  </div>
  <div *ngIf="collectionB" class="collection-card-container">
    <!-- Right Cell: Image -->
    <div class="media-container">
      <img [src]="collectionB?.featuredAsset | assetPreview:'large'" class="full-size aspect-[19/20]" />
    </div>
  </div>
</div>
