import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs/operators';
import { ActiveChannelService } from '../../core/providers/channel/channel.service';

/**
 * Cache the resolved promise so that the activeChannel only needs
 * to be fetched once on the first invocation of the pipe.
 */
let channelDataPromise: Promise<any>;

/**
 * A pipe which formats a price (always given as an integer by Vendure) according
 * to the currencyCode of the current Channel.
 */
@Pipe({
    name: 'separatedFormatPrice',
    pure: false,
})
export class SeparatedFormatPricePipe implements PipeTransform {

    private latestValue: any = null;
    private latestReturnedValue: any = null;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private channelService: ActiveChannelService,) {}

    transform(value: number) {
        if (this.latestValue !== value) {
            this.latestValue = value;
            this.formatCurrency(value);
        }
        return this.latestReturnedValue;
    }

    private formatCurrency(value: number) {
        this.channelService.activeChannel$.pipe(take(1))
        .subscribe(channel => {
            const formatter = new Intl.NumberFormat(channel.defaultLanguageCode, {
                style: 'currency',
                currency: channel.currencyCode,
            });
            const formattedPrice = formatter.format(value / 100);
            const [currency, ...priceParts] = formattedPrice.split('');
            this.latestReturnedValue = {
                currency: currency,
                price: priceParts.join('')
            };
            this.changeDetector.markForCheck();
        });
    }
}