import { Injectable } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { StateService } from '../../providers/state/state.service';
import { NotificationService } from '../../providers/notification/notification.service';
import { ADD_TO_CART } from '../../../common/graphql/documents.graphql';
import { GET_ACTIVE_ORDER } from '../../providers/active/active.service.graphql';
import { TRANSITION_TO_ADDING_ITEMS } from '../../../checkout/components/checkout-process/checkout-process.graphql';
import {
  AddToCartMutation,
  AddToCartMutationVariables,
  GetActiveOrderQuery,
  Order,
  Product,
  ProductVariant,
  TransitionToAddingItemsMutation,
} from '../../../common/generated-types';
import { Observable, EMPTY, of, throwError } from 'rxjs';
import { switchMap, catchError, take, map } from 'rxjs/operators';
import { CombinedAnalyticsService } from '../analytics/combined-analytics.service';
import { NGXLogger } from 'ngx-logger';
import { AddToCartResult } from '../../../common/enums';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(
    private dataService: DataService,
    private stateService: StateService,
    private notificationService: NotificationService,
    private analyticsService: CombinedAnalyticsService,
    private logger: NGXLogger,
  ) {}

  addToCartAndOpenDrawer(
    productVariantId: string,
    quantity: number = 1,
  ): Observable<AddToCartResult> {
    return this.dataService
      .query<GetActiveOrderQuery>(GET_ACTIVE_ORDER, {}, 'network-only')
      .pipe(
        take(1),
        switchMap(({ activeOrder }) => {
          if (activeOrder) {
            if (activeOrder.state === 'ArrangingPayment') {
              return this.dataService
                .mutate<TransitionToAddingItemsMutation>(TRANSITION_TO_ADDING_ITEMS)
                .pipe(
                  take(1),
                  switchMap(({ transitionOrderToState }) => {
                    switch (transitionOrderToState?.__typename) {
                      case 'Order':
                        return of(null);
                      case 'OrderStateTransitionError':
                        this.notificationService.error(transitionOrderToState.message).subscribe();
                        return EMPTY;
                      default:
                        this.notificationService.error('Unexpected error occurred').subscribe();
                        return EMPTY;
                    }
                  }),
                  catchError(error => {
                    this.notificationService.error(`Error transitioning order state: ${error.message}`).subscribe();
                    return EMPTY;
                  }),
                );
            } else if (activeOrder.state === 'AddingItems') {
              return of(null);
            } else {
              this.notificationService.error('Cannot add items to the cart at this time').subscribe();
              return EMPTY;
            }
          } else {
            return of(null);
          }
        }),
        switchMap(() => {
          return this.dataService
            .mutate<AddToCartMutation, AddToCartMutationVariables>(ADD_TO_CART, {
              variantId: productVariantId,
              qty: quantity,
            })
            .pipe(
              take(1),
              map(({ addItemToOrder }) => {
                if (!addItemToOrder) {
                  this.notificationService.error('An unexpected error occurred').subscribe();
                  return AddToCartResult.ERROR;
                }
                switch (addItemToOrder.__typename) {
                  case 'Order':
                    this.stateService.setState('activeOrderId', addItemToOrder.id);
                    this.stateService.setState('cartDrawerOpen', true);
                    this.processAddToCartAnalytics(addItemToOrder as Order, productVariantId, quantity);
                    return AddToCartResult.SUCCESS;
                  case 'OrderModificationError':
                  case 'OrderLimitError':
                    this.notificationService.error(addItemToOrder.message).subscribe();
                    return AddToCartResult.ERROR;
                  case 'NegativeQuantityError':
                  case 'InsufficientStockError':
                    this.notificationService.error(addItemToOrder.message).subscribe();
                    return AddToCartResult.OUT_OF_STOCK;
                  default:
                    this.notificationService.error('An unexpected error occurred').subscribe();
                    return AddToCartResult.ERROR;
                }
              }),
              catchError(error => {
                this.notificationService.error(`Error adding item to cart: ${error.message}`).subscribe();
                return throwError(AddToCartResult.ERROR); // Use throwError to propagate the error
              }),
            );
        }),
      );
  }

  private processAddToCartAnalytics(
    order: Order,
    productVariantId: string,
    quantity: number,
  ) {
    const addedLine = order.lines.find(line => line.productVariant.id === productVariantId);
    if (addedLine) {
      const product = addedLine.productVariant.product;
      const variant = addedLine.productVariant;
      this.addToCartEvent(product, variant, quantity);
    } else {
      this.logger.warn('Added line not found in order lines for analytics tracking');
    }
  }

  private addToCartEvent(product: Product, variant: ProductVariant, quantity: number) {
    if (product && variant) {
      try {
        this.analyticsService.addToCart(product, variant, quantity);
        this.logger.debug('addToCartEvent');
      } catch (err) {
        this.logger.error('Error in addToCartEvent', err);
      }
    }
  }
}
