import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    OnDestroy,
    Inject,
    PLATFORM_ID,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import {
    CreateReturnRequestMutation,
    CreateReturnRequestMutationVariables,
    GetReturnRequestsByOrderIdQuery,
    GetReturnRequestsByOrderIdQueryVariables,
    ReturnRequest,
    ReturnItem,
    Fulfillment,
    OrderLine,
    GuestCreateReturnRequestMutation,
    GuestGetReturnRequestsQuery,
    GetActiveCustomerQuery,
    ReturnRequestList,
    FulfillmentLine,
    Channel,
    Scalars,
    GuestCreateReturnRequestMutationVariables,
} from '../../../common/generated-types';
import { DataService } from '../../../core/providers/data/data.service';
import { ModalService } from '../../../core/providers/modal/modal.service';
import { ReturnRequestModalComponent } from '../return-request-modal/return-request-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { NGXLogger } from 'ngx-logger';
import { isPlatformBrowser } from '@angular/common';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { 
    CREATE_RETURN_REQUEST,
    GET_RETURN_REQUESTS_BY_ORDER_ID,
    GUEST_CREATE_RETURN_REQUEST,
    GUEST_GET_RETURN_REQUESTS
} from './order-actions-form.graphql'
import { ReturnCancelPolicyData } from '../../../common/interfaces';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { GET_ACTIVE_CUSTOMER } from '../../../common/graphql/documents.graphql';

export type ExtendedGuestGetReturnRequestsQueryExVariables = {
  orderCode: Scalars['String'];
  email: Scalars['String'];
};

export type GuestCreateReturnRequestMutationExVariables = GuestCreateReturnRequestMutationVariables & {
  orderCode: Scalars['String'];
  email: Scalars['String'];
};

interface DisplayReturnRequestItem {
  quantity: number;
  line: {
    productVariant: {
      name: string;
      sku: string;
    }
  }
}

@Component({
    selector: 'vsf-order-actions-form',
    templateUrl: './order-actions-form.component.html',
    styleUrls: ['./order-actions-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderActionsComponent implements OnInit, OnDestroy {
    @Input() order: any;
    @Input() isGuest = false;
    @Input() emailAddress: string = '';

    activeCustomer$: Observable<GetActiveCustomerQuery['activeCustomer'] | undefined>;
    returnRequests$: any;
    currentReturnRequest$: any;
    returnableItems: { [orderLineId: string]: number } = {};
    cancellableItems: { [orderLineId: string]: number } = {};
    totalReturnableItems = 0;
    totalCancellableItems = 0;
    returnRequestsItems: ReturnRequest[] = [];
    cancellationRequestsItems: ReturnRequest[] = [];
    returnPolicy: ReturnCancelPolicyData | null = null;
    disabledPolicyNotification: string = '';
    enableCancel = true;
    cancellableTimeInMs = OrderActionsComponent.CANCELLABLE_HOURS_IN_MS;
    cancelExpiredNotification: string = 'Your order is outside the cancellation timeframe. Please email our support team(support@dealomega.com) for further help.';
    enableReturn = true;
    returnableTimeInMs = OrderActionsComponent.RETURNABLE_DAYS_IN_MS;
    returnExpiredNotification: string = '';

    private destroy$ = new Subject<void>();

    static readonly RETURNABLE_DAYS_IN_MS = 15 * 24 * 60 * 60 * 1000;
    static readonly CANCELLABLE_HOURS_IN_MS = 6 * 60 * 60 * 1000;
    static readonly SHIPPING_DAYS_IN_MS = 6 * 24 * 60 * 60 * 1000;
    static readonly DEFAULT_CHANNEL_CODE = "__default_channel__";

    constructor(
      private dataService: DataService,
      private changeDetectorRef: ChangeDetectorRef,
      private modalService: ModalService,
      private logger: NGXLogger,
      @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit() {
      if (this.order) {
        this.returnPolicy = safeJSONParse<ReturnCancelPolicyData>(
            this.order?.lines[0].productVariant?.product?.channels?.find(
                (channel: Channel) => channel.code != OrderActionsComponent.DEFAULT_CHANNEL_CODE
            )?.seller?.customFields?.returnPolicy, this.logger);
        if(this.returnPolicy) {
            this.enableCancel = this.returnPolicy.enableCancel;
            this.enableReturn = this.returnPolicy.enableReturn;
            if(!this.enableCancel && !this.enableReturn) {
                this.disabledPolicyNotification = this.returnPolicy.disabledPolicyNotification;
            }
            if(this.enableCancel) {
                this.cancellableTimeInMs = this.returnPolicy.cancellableTimeInHours * 60 * 60 * 1000;
                this.cancelExpiredNotification = this.returnPolicy.cancelExpiredNotification;
            }
            if(this.enableReturn) {
                this.returnableTimeInMs = this.returnPolicy.returnableTimeInDays * 24 * 60 * 60 * 1000;
                this.returnExpiredNotification = this.returnPolicy.returnExpiredNotification;
            }
        }
        if(!this.isGuest) {
          this.activeCustomer$ = this.dataService.query<GetActiveCustomerQuery>(GET_ACTIVE_CUSTOMER)
          .pipe(map(data => data.activeCustomer), takeUntil(this.destroy$));
        }
        this.fetchReturnRequests();
      }
    }

    ngOnDestroy() {
      this.destroy$.next();
      this.destroy$.complete();
    }

    fetchReturnRequests() {
      let returnRequestsQuery$: Observable<any>;
      if (this.isGuest) {
        returnRequestsQuery$ = this.dataService
          .query<GuestGetReturnRequestsQuery, ExtendedGuestGetReturnRequestsQueryExVariables>(
            GUEST_GET_RETURN_REQUESTS,
            {
              orderCode: this.order.code,
              email: this.emailAddress,
            },
          'network-only')
          .pipe(
            map(data => data.guestReturnRequests),
            filter(notNullOrUndefined),
            takeUntil(this.destroy$)
          );
      } else {
        returnRequestsQuery$ = this.activeCustomer$.pipe(
          filter(notNullOrUndefined),
          switchMap(activeCustomer =>
              this.dataService.query<GetReturnRequestsByOrderIdQuery, GetReturnRequestsByOrderIdQueryVariables>(
                  GET_RETURN_REQUESTS_BY_ORDER_ID,
                  {
                      options: {
                          filter: {
                              orderId: {
                                  eq: this.order?.id ?? ''
                              }
                          }
                      }
                  },
                  'network-only'
              )
          ),
          map(data => data.returnRequests),
          filter(notNullOrUndefined),
          takeUntil(this.destroy$)
        );
      }
      this.returnRequests$ = returnRequestsQuery$.pipe(filter(notNullOrUndefined), takeUntil(this.destroy$));
      this.returnRequests$.subscribe((returnRequests:any) => {
        this.logger.debug(`[fetchReturnRequests]returnRequests:${JSON.stringify(returnRequests)}`);
        this.calculateReturnableAndCancellableItems(returnRequests);
        this.changeDetectorRef.markForCheck();
      });
    }

    calculateReturnableAndCancellableItems(returnRequests: ReturnRequestList) {
      this.returnableItems = {};
      this.cancellableItems = {};
      this.totalReturnableItems = 0;
      this.totalCancellableItems = 0;
      this.order?.lines.forEach((orderLine: OrderLine) => {
        const orderLineId = orderLine.id;
        const orderLineQuantity = orderLine.quantity;
        const returnedQuantity = this.getTotalQuantity(returnRequests, 'Return', orderLineId);
        const cancelledQuantity = this.getTotalQuantity(returnRequests, 'Cancellation', orderLineId);
        const fulfillmentQuantity = this.getFulfillmentQuantity(orderLineId);
        this.returnableItems[orderLineId] = Math.max(fulfillmentQuantity - returnedQuantity, 0);
        this.cancellableItems[orderLineId] = Math.max(orderLineQuantity - fulfillmentQuantity - cancelledQuantity, 0);
        this.totalReturnableItems += this.returnableItems[orderLineId];
        this.totalCancellableItems += this.cancellableItems[orderLineId];
      });
      this.returnRequestsItems = returnRequests?.items.filter((returnRequest: ReturnRequest) => returnRequest.type === 'Return');
      this.cancellationRequestsItems = returnRequests?.items.filter((returnRequest: ReturnRequest) => returnRequest.type === 'Cancellation');
      this.logger.debug(`[fetchReturnRequests]totalReturnableItems:${this.totalReturnableItems} totalCancellableItems]${this.totalCancellableItems}`);
    }

    private getTotalQuantity(
      requests: ReturnRequestList,
      type: 'Return' | 'Cancellation',
      orderLineId: string
    ): number {
      return requests?.items.reduce((total, returnRequest) => {
        if (returnRequest.type === type && !['Cancelled', 'Rejected'].includes(returnRequest.status)) {
          const returnedItem = returnRequest.items.find((item: ReturnItem) => item.orderLineId === orderLineId);
          return total + (returnedItem?.quantity || 0);
        }
        return total;
      }, 0);
    }

    private getFulfillmentQuantity(orderLineId: string): number {
      return this.order?.fulfillments?.reduce((total:number, fulfillment:Fulfillment) => {
        if(fulfillment.state !== 'Cancelled') {
            const fulfilledItem = fulfillment.lines.find((line: FulfillmentLine) => line.orderLineId === orderLineId);
            return total + (fulfilledItem?.quantity || 0);
        } else {
            return total + 0;
        }
      }, 0) || 0;
    }

    isReturnable(): boolean {
      const order = this.order;
      if (order?.state === 'Cancelled') {
        return false;
      }
      if (order && ['Shipped', 'PartiallyShipped'].includes(order.state)) {
        const fulfillments = (order?.fulfillments || []).filter((f:Fulfillment) => f.state !== 'Cancelled');
        const shippedFulfillments = fulfillments.filter((f:Fulfillment) => f.state === 'Shipped');
        if (shippedFulfillments.length === fulfillments.length && shippedFulfillments.length > 0) {
          const latestShippedFulfillment = shippedFulfillments.sort(
            (a:Fulfillment, b:Fulfillment) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )[0];
          const timeSinceShipped = new Date().getTime() - new Date(latestShippedFulfillment.updatedAt).getTime();
          return (
            timeSinceShipped <=
            this.returnableTimeInMs + OrderActionsComponent.SHIPPING_DAYS_IN_MS
          );
        } else {
          return true;
        }
      }
      return false;
    }

    isCancellable(): boolean {
      const order = this.order;
      if (order?.state === 'Cancelled') {
        return false;
      }
      if (this.totalCancellableItems > 0 && order?.orderPlacedAt) {
        return (
          new Date().getTime() - new Date(order?.orderPlacedAt).getTime() <=
          this.cancellableTimeInMs
        );
      }
      return false;
    }

    isDisplayCancellableNotification(): boolean {
      const order = this.order;
      if (order?.state === 'Cancelled') {
        return false;
      }
      if (this.totalCancellableItems > 0 && order?.orderPlacedAt) {
        const timeSinceOrderPlaced = new Date().getTime() - new Date(order?.orderPlacedAt).getTime();
        return timeSinceOrderPlaced > this.cancellableTimeInMs;
      }
      return false;
    }

    openReturnRequestForm(type: string) {
      const order = this.order;
      this.modalService
        .fromComponent(ReturnRequestModalComponent, {
          size: 'xl',
          locals: {
            order: order,
            returnableItems: this.returnableItems,
            cancellableItems: this.cancellableItems,
            type,
          },
        })
        .subscribe((result) => {
          const returnReason = result?.reason;
          const returnItems = result?.returnItems;
          if (!returnItems || returnItems.length === 0) {
            this.logger.debug(`[return request] no items to return`);
            return;
          }
          if (this.isGuest && this.emailAddress) {
            this.currentReturnRequest$ =this.dataService.mutate<GuestCreateReturnRequestMutation, GuestCreateReturnRequestMutationExVariables>(GUEST_CREATE_RETURN_REQUEST, {
                orderCode: order.code,
                email: this.emailAddress,
                input: {
                    type: type,
                    reason: returnReason || '',
                    orderId: order?.id,
                    items: returnItems?? [],
                },
            }).pipe(map(data => data?.guestRequestReturn));
            this.currentReturnRequest$.subscribe((data:any) => {
                this.fetchReturnRequests();
                this.changeDetectorRef.markForCheck();
                if(type === 'Return') {
                  this.displayReturnRequestInstructionDialog();
                }
            });
          } else {
            const returnReason = result?.reason;
            const returnItems = result?.returnItems;
            if (!returnItems || returnItems.length === 0) {
                this.logger.debug(`[return request]no items to return`);
                return;
            }
            this.activeCustomer$.pipe(
              filter(notNullOrUndefined),
              switchMap(activeCustomer =>
                this.dataService.mutate<CreateReturnRequestMutation, CreateReturnRequestMutationVariables>(
                        CREATE_RETURN_REQUEST,
                        {
                            input: {
                                type: type,
                                reason: returnReason ?? '',
                                orderId: order?.id ?? '',
                                items: returnItems ?? [],
                            },
                        }
                    )
                ),
                map(data => data?.requestReturn)
              ).subscribe((data) => {
                  this.fetchReturnRequests();
                  this.changeDetectorRef.markForCheck();
                  if(type === 'Return') {
                      this.displayReturnRequestInstructionDialog();
                  }
              });
          }
        });
    }

    displayReturnRequestInstructionDialog() {
      this.modalService.fromComponent(ConfirmationModalComponent, {
        locals: {
          title: 'Return Request Submitted',
          message: `Your product Return Request has been successfully submitted. You will receive an email from us shortly. Please follow the instructions in the email to complete your Return Request. Thank you!`,
          displayCancel: false,
        },
      }).subscribe();
    }

    openReturnRequestRMAForm(returnRequest: ReturnRequest) {
      const order = this.order;
      let returnRequestItems: any = [];
      returnRequest.items.forEach((item: ReturnItem) => {
          returnRequestItems.push({
              quantity: item.quantity,
              line: {
                  productVariant: {
                      name: order?.lines.find((orderLine: OrderLine) => orderLine.id === item.orderLineId)?.productVariant?.name || '',
                      sku: order?.lines.find((orderLine: OrderLine) => orderLine.id === item.orderLineId)?.productVariant?.sku || '',
                  }
              }
          });
      });
      const RmaData = {
          RMA: returnRequest.RMA,
          createdAt: returnRequest.createdAt,
          sellerAddressTo: returnRequest?.seller?.customFields?.mailAddressTo,
          order: {
              code: order?.code,
              createdAt: order?.createdAt,
          },
          items: returnRequestItems,
      };
      const encodedFormData = btoa(JSON.stringify(RmaData));
      if (isPlatformBrowser(this.platformId)) {
        window.open(`./return-form?info=${encodedFormData}`, '_blank');
      }
    }
  }
  