<div class="product-carousel">
  <div class="product-list-container">
    <ng-container *ngIf="cardType === 'hero'">
      <vsf-product-hero-card *ngFor="let product of displayedProducts; trackBy: trackByProductId"
                             [product]="product"
                             [ngClass]="smallCardClass"
                             class="card">
      </vsf-product-hero-card>
    </ng-container>
    <ng-container *ngIf="cardType === 'standard'">
      <vsf-product-card *ngFor="let product of displayedProducts; trackBy: trackByProductId"
                        [item]="product"
                        [displayBuyButton]="displayBuyButton"
                        [displayPrice]="displayPrice"
                        [imgSize]="imgSize"
                        [isMobile]="false"
                        class="card">
      </vsf-product-card>
    </ng-container>
  </div>
  <button class="arrow arrow-left" (click)="showPreviousItems()" *ngIf="startIndex > 0">
    <span><fa-icon icon="chevron-left"></fa-icon></span>
  </button>
  <button class="arrow arrow-right" (click)="showNextItems()" *ngIf="startIndex + itemsPerSlide < (products$ | async)?.length">
    <span><fa-icon icon="chevron-right"></fa-icon></span>
  </button>
</div>