<div class="modal-content">
    <ng-template vsfDialogTitle>{{ title }}</ng-template>
    <div class="modal-body text-left w-full mt-4 mb-4">
        <div class="ml-4">
            <strong>{{authorName}}</strong>
            <vsf-product-review-indicator
                        class="mb-6 align-center"
                        [inputScore]="reviewRating"
                        [displayReviewNumbers]="false">
            </vsf-product-review-indicator>
            <strong>{{reviewSummary}}</strong>
        </div>
        <textarea readonly
                id="review"
                [(ngModel)]="reviewContent"
                class="review-content mt-2 w-full border-transparent"
                rows="10">
                {{reviewContent}}
        </textarea>
    </div>
    <ng-template vsfDialogButtons>
        <div class="flex justify-center items-center ">
            <p>(Click anywhere to close.)</p>
        </div>
    </ng-template>
</div>