<div *ngIf="cart">
    <div class="flex items-center justify-between  mt-6 px-4 overflow-y-auto py-2"
        [ngClass]="useExpandCollapse?'cursor-pointer' : ''"
        (click)="toggleFold()">
        <p class="text-gray-600 font-bold">Apply Promo Code</p>
        <fa-icon *ngIf="useExpandCollapse" [icon]="isFolded ? 'plus' : 'minus'"></fa-icon>
    </div>
    <div class="flex flex-row mb-2 ml-2 mr-2"
        [ngClass]="{'mt-6': !useExpandCollapse}"
        [@expandCollapse]="useExpandCollapse && isFolded ? 'collapsed' : 'expanded'">
        <input type="text"
            class="w-full border-t border-gray-200 p-4"
            placeholder="Promo Code"
            [(ngModel)]="promoCode"
        >
        <button (click)="applyCoupon()"
            [disabled]="!promoCode || processingCoupon"
            class="btn-primary flex w-auto ml-2">
            Apply
        </button>
    </div>
    <div *ngIf="couponError" class="text-red-500 text-sm ml-2">
        {{ couponError }}
    </div>
    <div *ngIf="couponMessage" class="text-green-500 text-sm ml-2">
        {{ couponMessage }}
    </div>
</div>