<ng-template vsfDialogTitle>{{ title }}</ng-template>
<div class="modal-body">
    <p>{{ message }}</p>
</div>
<ng-template vsfDialogButtons>
    <div class="modal-footer">
        <div *ngIf="displayCancel; else hideCancel" class="show-cancel">
            <button type="button" class="btn btn-primary" (click)="confirm()">OK</button>
            <button type="button" class="btn btn-primary" (click)="cancel()">Cancel</button>
        </div>
        <ng-template #hideCancel>
            <div class="hide-cancel">
                <button type="button" class="btn btn-primary" (click)="confirm()">OK</button>
            </div>
        </ng-template>
    </div>
</ng-template>