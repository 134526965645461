import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GET_STORESITE_INFO } from '../../common/graphql/documents.graphql';
import { Collection, GetStoreInfoQuery, GetStoreInfoQueryVariables } from '../../common/generated-types';
import { DataService } from '../../core/providers/data/data.service';
import { NGXLogger } from 'ngx-logger';
import { isPlatformBrowser } from '@angular/common';
import { safeJSONParse } from '../../common/utils/safe-json-parser';
import { CollectionGroupData, ColorConfig } from '../../common/interfaces';
import { setCanonicalURL } from '../../common/utils/canonical-url';

@Component({
  selector: 'vsf-store-shopping-page',
  templateUrl: './store-shopping-page.component.html',
  styleUrls: ['./store-shopping-page.component.scss'],
})
export class StoreShoppingPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  storeInfo: GetStoreInfoQuery['storeSite'];
  sellerChannelId: string;
  isShoppingRoute: boolean;
  isSearchRoute: boolean;

  colorConfig: ColorConfig | null;

  collectionForSeriesList: Collection[];
  collectionListForShopByGroupList: CollectionGroupData[];

  isMobile: boolean | null = null;
  readyToDisplay$ = new BehaviorSubject<boolean>(false);
  pathSegment: string = '';

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: object,
              private logger: NGXLogger
            ) {

  }

  ngOnInit() {
    this.checkViewport();
    this.logger.debug('Store Shopping Page Component Initialized', this.route.params);
    const routeInfo$ = combineLatest([
      this.route.paramMap,
      this.route.url
    ]).pipe(
      map(([params, urlSegments]) => {
        const identifier = params.get('identifier') || '';
        const lastSegment = urlSegments[urlSegments.length - 1].path;
        this.pathSegment = lastSegment;
        const isShoppingRoute = lastSegment === 'shopping';
        const isSearchRoute = lastSegment === 'search';
        setCanonicalURL(`/store/${identifier}/${lastSegment}`, this.platformId);
        return {
          identifier,
          isShoppingRoute,
          isSearchRoute
        };
      })
    );

    routeInfo$.pipe(
      switchMap(({ identifier, isShoppingRoute, isSearchRoute }) => {
        this.isShoppingRoute = isShoppingRoute;
        this.isSearchRoute = isSearchRoute;
        this.isShoppingRoute && this.logger.debug('Is Shopping Route:', this.isShoppingRoute);
        this.isSearchRoute && this.logger.debug('Is Search Route:', this.isSearchRoute);
        return this.dataService.query<GetStoreInfoQuery, GetStoreInfoQueryVariables>(GET_STORESITE_INFO, {
          identifier: identifier,
        });
      })
    ).subscribe(result => {
      this.storeInfo = result.storeSite;
      this.sellerChannelId = this.storeInfo?.sellerChannelId || '';
      this.colorConfig = safeJSONParse<ColorConfig>(this.storeInfo?.colorConfig, this.logger);
      this.collectionForSeriesList = this.storeInfo?.collectionForSeriesListData as Collection[];
      this.collectionListForShopByGroupList = this.storeInfo?.collectionGroupDataList as CollectionGroupData[];
      this.readyToDisplay$.next(true);
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    setCanonicalURL(`/store/${this.storeInfo?.identifier}/${this.pathSegment}`, this.platformId);
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkViewport();
  }

  private checkViewport() {
    if(isPlatformBrowser(this.platformId)){
      const mobileIndicator = document.getElementById('mobile-indicator');
      if(mobileIndicator && window) {
          this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
      }
    }
  }
}