import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { ProductModule } from '../product/product.module';
import { CoreModule } from '../core/core.module';

import { routes } from './store.routes';
import { StoreMainPageComponent } from './store-main-page/store-main-page.component';
import { StoreShoppingPageComponent } from './store-shopping-page/store-shopping-page.component';
import { StorePolicyPageComponent } from './store-policy-page/store-policy-page.component';
import { CollectionModule } from '../collection/collection.module';

const DECLARATIONS = [
    StoreMainPageComponent,
    StoreShoppingPageComponent,
    StorePolicyPageComponent,
];

@NgModule({
    declarations: DECLARATIONS,
    imports: [
        SharedModule,
        ProductModule,
        CollectionModule,
        CoreModule,
        RouterModule.forChild(routes),
    ],
})
export class StoreModule {
}
