<div class="store-icons flex items-center">
  <a [routerLink]="path" class="l">
    <div *ngIf="isMobile">
      <div *ngIf="smallLogo">
        <div style="width: auto;">
          <img
            [src]="smallLogo | assetPreview:'small'"
            style="pointer-events: none"
            [style.height]="logoHeight"
            alt="logo"
            [width]="smallLogo.width"
            [height]="smallLogo.height"
            class="w-auto" />
        </div>
      </div>
    </div>
    <div *ngIf="(isMobile===false)">
      <ng-container *ngIf="nameLogo; else squareLogo">
        <div style="width: auto;">
          <img
            [src]="nameLogo | assetPreview:'small'"
            [style.height]="logoHeight"
            style="pointer-events: none;"
            alt="logo"
            class="object-contain"/>
        </div>
      </ng-container>
      <ng-template #squareLogo>
        <ng-container *ngIf="normalLogo">
          <div style="width: auto;">
            <img
              [src]="normalLogo | assetPreview:'small'"
              [style.height]="logoHeight"
              style="pointer-events: none;"
              alt="logo"
              class="w-auto" />
          </div>
        </ng-container>
        <div *ngIf="!nameLogo && displayName" class="flex items-center">
          <div *ngIf="name" class="store-name">
            <p class="mr-5 ml-10 text-center">{{ name }}</p>
          </div>
        </div>
      </ng-template>
    </div>
  </a>
</div>
