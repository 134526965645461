import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataService } from '../../../core/providers/data/data.service';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { APPLY_COUPON, REMOVE_COUPON } from '../../../common/graphql/documents.graphql';
import { take } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'vsf-coupon-form',
  templateUrl: './coupon-form.component.html',
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({ height: '0', overflow: 'hidden' })),
      state('expanded', style({ height: '*' })),
      transition('collapsed <=> expanded', animate('300ms ease-in-out'))
    ])
  ]
})
export class CouponFormComponent {
  @Input() cart: any;
  @Input() useExpandCollapse: boolean = false;
  @Output() couponApplied = new EventEmitter<void>();

  promoCode: string = '';
  couponMessage: string = '';
  couponError: string = '';
  processingCoupon: boolean = false;
  isFolded: boolean = true;

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private logger: NGXLogger
  ) {}

  toggleFold() {
    if (this.useExpandCollapse) {
        this.isFolded = !this.isFolded;
        this.couponMessage = '';
        this.couponError = '';
    }
  }

  applyCoupon() {
    if (!this.promoCode) {
      this.setCouponError('Please enter a promo code.');
      return;
    }

    if (this.cart?.couponCodes?.includes(this.promoCode)) {
      this.setCouponError(`Promo code "${this.promoCode}" already applied.`);
      this.promoCode = '';
      return;
    }

    this.processingCoupon = true;
    const currentTotalDiscount = this.calculateCurrentTotalDiscount(this.cart);

    this.dataService.mutate<any, { couponCode: string }>(APPLY_COUPON, {
      couponCode: this.promoCode,
    }).pipe(
      take(1),
    ).subscribe({
      next: async (result) => {
        if (result.applyCouponCode.__typename === 'Order') {
          const updatedTotalDiscount = this.calculateCurrentTotalDiscount(result.applyCouponCode);
          if (currentTotalDiscount === updatedTotalDiscount) {
            this.setCouponError('No eligible product found in the cart.');
            try {
              await this.dataService.mutate<any, { couponCode: string }>(REMOVE_COUPON, {
                couponCode: this.promoCode,
              }).pipe(
                take(1),
              ).subscribe();
            } catch (err) {
              this.logger.debug('Error while removing coupon', JSON.stringify(err));
            }
          } else {
            this.setCouponMessage(`Promo code "${this.promoCode}" accepted.`);
            this.couponApplied.emit();
          }
        } else {
          this.setCouponError(result.applyCouponCode.message);
        }
        this.promoCode = '';
        this.processingCoupon = false;
        this.changeDetector.markForCheck();
      },
      error: (err) => {
        this.setCouponError(`Error: ${err} occurred while applying promo code.`);
        this.processingCoupon = false;
        this.changeDetector.markForCheck();
      }
    });
  }

  private calculateCurrentTotalDiscount(order: any): number {
    let totalDiscount = 0;
    if (order && order.discounts && order.discounts.length > 0) {
      order.discounts.forEach((discount: any) => {
        if (discount?.amountWithTax) {
          totalDiscount += discount?.amountWithTax || 0;
        }
      });
    }
    return totalDiscount;
  }

  private setCouponMessage(message: string) {
    this.couponMessage = message;
    this.couponError = '';
  }

  private setCouponError(error: string) {
    this.couponError = error;
    this.couponMessage = '';
  }
}