<div class="modal-content">
    <ng-template vsfDialogTitle>{{ title }}</ng-template>
    <div *ngIf="product" class="review-container">
        <div class="items-center text-center">
          <small class="grey-500">You can update your nickname in <a class="cursor-pointer underline" [routerLink]="['./account', 'details']" target="_blank">Personal details</a> page.</small>
        </div>
        <div class="product-info">
            <img [src]="product.featuredAsset | assetPreview: 'small'" alt="Product Image">
            <p>{{ product.name }}</p>
        </div>
        <hr>
        <div class="rating-section flex-col">
            <div class="justify-between flex items-center mb-2">
                <label><strong>Overall rating</strong></label>
                <button class="btn-clear"
                    (click)="setRating(0)">Clear</button>
            </div>
            <div class="stars">
              <span *ngFor="let star of [1,2,3,4,5]"
                    (click)="setRating(star)"
                    style="cursor: pointer; display: inline-block; margin-right: 0.5rem;">
                  <fa-icon [icon]="'star'"
                          [ngClass]="{'orange': star <= rating, 'hollow': star > rating}">
                  </fa-icon>
              </span>
            </div>
        </div>
        <hr>
        <div class="form-group flex-col">
            <div>
              <label for="headline"><strong>Your review headline</strong></label>
            </div>
            <div>
              <input id="headline"
                     type="text"
                     [(ngModel)]="summary"
                     placeholder="What's crucial to share?"
                     class="form-control mt-2 w-full">
            </div>
        </div>
        <hr>
        <div class="form-group flex-col">
            <div>
              <label for="review"><strong>Write your review</strong></label>
            </div>
            <div>
              <textarea id="review"
                        [(ngModel)]="content"
                        placeholder="Share your likes or dislikes. How did you use this product?"
                        class="form-control mt-2 w-full"
                        rows="6">
              </textarea>
            </div>
        </div>
    </div>
    <div *ngIf="!product" class="alert alert-danger" role="alert">
        Product not found
    </div>
    <ng-template vsfDialogButtons>
        <hr>
        <div class="modal-footer mt-2" style="display: flex; justify-content: center;">
            <div class="button-container" style="width: 70%; display: flex; justify-content: center;">
                <button type="button" class="btn btn-primary" [disabled]="!isFormValid()" (click)="select()">
                    Submit
                </button>
            </div>
        </div>
    </ng-template>
</div>