<div class="modal-content" style="max-height: calc(100vh - 200px); overflow-y: auto; display: flex; flex-direction: column; align-items: center;">
    <ng-template vsfDialogTitle>{{type==='Return'?'Return':'Cancel'}} Order</ng-template>
    <ng-container *ngIf="order && order.lines && order.lines.length > 0; else emptyOrder">
        <div class="table-responsive ml-5" style="margin-top: 20px;">
            <table id="return-cancel-order" class="table" style="border-collapse: collapse; margin-top: 20px;">
                <thead>
                    <tr>
                        <th style="border: 1px solid #ddd; padding: 8px;">Image</th>
                        <th style="border: 1px solid #ddd; padding: 8px;">Product Name</th>
                        <th style="border: 1px solid #ddd; padding: 8px;">SKU</th>
                        <th style="border: 1px solid #ddd; padding: 8px;">Unit Price</th>
                        <th style="border: 1px solid #ddd; padding: 8px;">Total</th>
                        <th style="border: 1px solid #ddd; padding: 8px;">{{type==='Return'?'Returnable':'Cancellable'}}</th>
                        <th style="border: 1px solid #ddd; padding: 8px;">{{type==='Return'?'Return':'Cancel'}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of order.lines">
                        <td id="image" style="border: 1px solid #ddd; padding: 8px; max-width: 100%; max-height: 100%;">
                            <div class="image-placeholder" style="width: 48px; height: 48px;">
                                <img *ngIf="line.featuredAsset as asset; else imagePlaceholder"
                                    [src]="asset | assetPreview: 'thumb'"
                                    [alt]="line.productVariant.name"
                                    style="pointer-events: none;"
                                    class="w-full h-full object-cover aspect-[1/1]" />
                                <ng-template #imagePlaceholder>
                                    <div class="placeholder">
                                        <clr-icon shape="image" size="48"></clr-icon>
                                    </div>
                                </ng-template>
                            </div>
                        </td>
                        <td id="product-name" style="border: 1px solid #ddd; padding: 8px; position: relative;">
                            <div class="product-name" [title]="line.productVariant.name">
                                {{ line.productVariant.name }}
                            </div>
                        </td>
                        <td id="product-sku" style="border: 1px solid #ddd; padding: 8px;">
                            {{ line.productVariant.sku }}
                        </td>
                        <td id="unit-price" style="border: 1px solid #ddd; padding: 8px;">
                            {{ line.unitPriceWithTax | formatPrice}}
                        </td>
                        <td id="quantity" style="border: 1px solid #ddd; padding: 8px;">
                            {{ line.quantity }}
                        </td>
                        <td id="returnable" style="border: 1px solid #ddd; padding: 8px;">
                            {{ type==='Return' ? returnableItems[line.id] : cancellableItems[line.id] }}
                        </td>
                        <td id="refund-quantity" style="border: 1px solid #ddd; padding: 8px;">
                            <ng-container *ngIf="((type ==='Return' && returnableItems[line.id] > 0) || (type ==='Cancellation' && cancellableItems[line.id] > 0)); else notAvailable">
                                <input [(ngModel)]="lineQuantities[line.id].quantity"
                                    type="number"
                                    [max]="maxRefundableQuantity(line.id)"
                                    min="0"
                                    (ngModelChange)="onRefundQuantityChange(line.id, $event)" />
                            </ng-container>
                            <ng-template #notAvailable>
                                N/A
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
    <ng-template #emptyOrder>
        <div class="alert alert-warning" style="margin-top: 20px;">
            No order lines found.
        </div>
    </ng-template>
    <div *ngIf="type === 'Return'" class="form-group ml-5 mt-5" style="margin-top: 20px;">
        <label for="returnReason">Return Reason (Required):</label>
        <select id="returnReason" class="form-control ml-5" [(ngModel)]="selectedReturnReason" (change)="onReasonChange()">
          <option value="" disabled selected>Select a reason</option>
          <option *ngFor="let reason of returnReasonValues" [value]="reason">{{ reason }}</option>
        </select>
        <div *ngIf="selectedReturnReason === otherReturnReasonValue" class="mt-3">
          <label for="otherReturnReason">Please specify the reason:</label>
          <input type="text" id="otherReturnReason" class="form-control ml-5" [(ngModel)]="otherReturnReason" placeholder="Enter the reason for return">
        </div>
    </div>
    <div  *ngIf="type === 'Cancellation'" class="form-group ml-5 mt-5" style="margin-top: 20px;">
        <label for="cancellationReason">Cancellation Reason (Required):</label>
        <select id="cancellationReason" class="form-control ml-5" [(ngModel)]="selectedCancellationReason" (change)="onReasonChange()">
          <option value="" disabled selected>Select a reason</option>
          <option *ngFor="let reason of cancellationReasonValues" [value]="reason">{{ reason }}</option>
        </select>
        <div *ngIf="selectedCancellationReason === otherCancellationReasonValue" class="mt-3">
          <label for="otherCancellationReason">Please specify the reason:</label>
          <input type="text" id="otherCancellationReason" class="form-control ml-5" [(ngModel)]="otherCancellationReason" placeholder="Enter the reason for cancellation">
        </div>
    </div>
    <ng-template vsfDialogButtons>
        <div class="modal-footer mt-5" style="display: flex; justify-content: center;">
            <div class="button-container" style="width: 70%; display: flex; justify-content: space-between;">
              <button type="button" class="btn btn-secondary" (click)="cancel()">
                Cancel
              </button>
              <button type="button"
                      class="btn btn-primary"
                      [disabled]="((type === 'Return' && (!selectedReturnReason || (selectedReturnReason === otherReturnReasonValue && !otherReturnReason)))
                                  || (type === 'Cancellation' && (!selectedCancellationReason || (selectedCancellationReason === otherCancellationReasonValue && !otherCancellationReason))))
                                  || !isQuantitySelected()"
                      (click)="select()">
                OK
              </button>
            </div>
          </div>
    </ng-template>
</div>