import { Component, OnInit } from '@angular/core';
import { Dialog } from '../../../core/providers/modal/modal-types';
import { Product } from '../../../common/generated-types';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'vsf-customer-review-form',
  templateUrl: './input-review-form.component.html',
  styleUrls: ['./input-review-form.component.scss']
})
export class InputReviewModalComponent implements OnInit, Dialog<{ summary: string; content: string; rating: number; }>{
  title: string;
  product: Product;
  resolveWith: (result?: { summary: string; content: string; rating: number }) => void;
  rating: number = 0;
  summary: string = '';
  content: string = '';

  constructor(private logger: NGXLogger) { }

  ngOnInit() {
    this.logger.info('InputReviewModalComponent initialized.', this.title, this.product);
  }

  setRating(stars: number): void {
    this.logger.info('Rating set to:', stars);
    this.rating = stars;
  }

  select() {
      this.resolveWith({
          summary: this.summary,
          content: this.content,
          rating: this.rating,
      });
  }

  cancel() {
      this.resolveWith();
  }

  isFormValid(): boolean {
      return this.rating > 0 && this.summary.trim() !== '' && this.content.trim() !== '';
  }
}