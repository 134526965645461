<div *ngIf="keySellingPoints && keySellingPoints.length > 0"
    class="mb-4 lg:block product-banner"
    style="min-height: 145px;"
    [ngStyle]="{'background-color': backgroundColor}"
>
    <div *ngFor="let keypoint of keySellingPoints"
        class="key-point"
        [ngClass]="layout">
        <div class="icon">
            <img [src]="keypoint.icon | assetPreview:'tiny'"
                class="w-full h-full object-contain"
                style="pointer-events: none;"
                alt="Key Selling Point Icon">
        </div>
        <div class="key-point-text" [ngStyle]="{'color': textColor}">
            <p class="title" [ngStyle]="{'font-size': isMobile?'16px':'20px'}" style="font-weight: 450;">{{keypoint.title}}</p>
            <p class="content" style="font-size: 14px; font-weight: 60;">{{keypoint.value}}</p>
        </div>
    </div>
</div>