<!-- <div id="mobile-indicator" class="is-mobile"></div> -->
<div *ngIf="channel$ | async as channel">
    <ng-container *ngIf="isMobile !== null">
        <!-- Hero section, display featured-porduct-slug if it exists else display store-hero-materials -->
        <div *ngIf="channelHeroSection && channelHeroSection?.medias?.length > 0" class="hero-banner">
            <vsf-hero-section
                [heroSection]="channelHeroSection"
            >
            </vsf-hero-section>
        </div>
        <!-- Product list -->
        <div id="for-you" *ngIf="channel.customFields?.DefaultCollectionSlug" class="section py-4" style="min-height: 520px;">
            <ng-container *ngIf="isMobile===false; else mobileForYou" style="min-height: 520px;">
                <vsf-product-carousel
                    [collectionSlug]="channel.customFields?.DefaultCollectionSlug"
                    [cardType]="'standard'"
                    [itemsPerSlide]=3
                    [displayBuyButton]="true"
                    [imgSize]="'lg'">
                </vsf-product-carousel>
            </ng-container>
            <ng-template #mobileForYou style="min-height: 620px;">
                <vsf-product-swiper
                    [collectionSlug]="channel.customFields?.DefaultCollectionSlug"
                    [layoutMode]="'horizontal'"
                    [itemsPerRow]=1
                    [itemsPerSlide]=3
                    [displayBuyButton]="true"
                    [imgSize]="'sm-h'">
                </vsf-product-swiper>
            </ng-template>
        </div>
        <div id="sales-offers" *ngIf="channel.customFields?.HotSalesCollectionSlug" class="section py-4" style="min-height: 328px;">
            <ng-container *ngIf="isMobile===false; else mobileSalesOffers" style="min-height: 440px;">
                <div class="flex">
                    <p class="fa-vertical-bar-medium">{{channel.customFields?.HotSalesCollectionLabel}}</p>
                </div>
                <vsf-product-carousel
                    [collectionSlug]="channel.customFields?.HotSalesCollectionSlug"
                    [cardType]="'standard'"
                    [itemsPerSlide]=4
                    [displayPrice]="true"
                    [imgSize]="'md'">
                </vsf-product-carousel>
            </ng-container>
            <ng-template #mobileSalesOffers  style="min-height: 328px;">
                <vsf-product-swiper
                    [collectionSlug]="channel.customFields?.HotSalesCollectionSlug"
                    [layoutMode]="'vertical'"
                    [headerTitle]="channel.customFields?.HotSalesCollectionLabel"
                    [itemsPerRow]=2
                    [itemsPerSlide]=6
                    [displayPrice]="true"
                    [displayPagination]="true"
                    [imgSize]="'sm-v'">
                </vsf-product-swiper>
            </ng-template>
        </div>
        <div id="new" *ngIf="channel.customFields?.NewArrivalsCollectionSlug" class="section py-4" style="min-height: 328px;">
            <ng-container *ngIf="isMobile===false; else mobileNewArrivals" style="min-height: 440px;">
                <div class="flex">
                    <p class="fa-vertical-bar-medium">{{channel.customFields?.NewArrivalsCollectionLabel}}</p>
                </div>
                <vsf-product-carousel
                    [collectionSlug]="channel.customFields?.NewArrivalsCollectionSlug"
                    [cardType]="'standard'"
                    [itemsPerSlide]=4
                    [displayPrice]="true"
                    [imgSize]="'md'">
                </vsf-product-carousel>
            </ng-container>
            <ng-template #mobileNewArrivals style="min-height: 328px;">
                <vsf-product-swiper
                    [collectionSlug]="channel.customFields?.NewArrivalsCollectionSlug"
                    [layoutMode]="'vertical'"
                    [headerTitle]="channel.customFields?.NewArrivalsCollectionLabel"
                    [itemsPerRow]=2
                    [itemsPerSlide]=20
                    [displayPrice]="true"
                    [imgSize]="'sm-v'">
                </vsf-product-swiper>
            </ng-template>
        </div>
    </ng-container>
</div>
