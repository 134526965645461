<div class="vimeo-player-container" (click)="onPlayerClick($event)" (keydown)="onPlayerKeydown($event)" tabindex="0" role="button" aria-label="Vimeo player controls" [style.width.px]="width" [style.height.px]="height">
  <div class="vimeo-player flex justify-center items-center" [id]="vimeoElementId" [style.width.px]="width"></div>
  <button class="mute-toggle" (click)="toggleMute($event)" aria-label="Toggle mute">
    <i [style]="isMuted ? 'display: inline-block': 'display: none'">
        <svg t="1728653394014" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4265" width="32" height="32"><path d="M611.547429 470.918095v358.253715c0 49.859048-61.561905 74.044952-96.060953 37.741714l-156.793905-143.164953 51.760762-51.760761 127.951238 116.784761V544.085333l73.142858-73.142857z m209.92-209.944381a353.01181 353.01181 0 0 1 127.951238 241.371429v64.048762c-10.800762 120.539429-82.115048 223.646476-183.344762 278.723047l-33.767619-64.902095A280.30781 280.30781 0 0 0 877.714286 534.381714a279.893333 279.893333 0 0 0-108.251429-221.354666l52.028953-52.053334z m-108.495239-63.878095l51.736381 51.712L213.016381 800.475429l-51.712-51.687619L712.97219 197.071238z m11.532191 160.865524a218.819048 218.819048 0 0 1 79.286857 168.96 218.819048 218.819048 0 0 1-105.618286 187.684571l-33.889523-65.097143a145.700571 145.700571 0 0 0 66.364952-122.563047c0-47.957333-22.918095-90.453333-58.221714-116.906667l52.077714-52.077714z m-112.956952-152.868572v24.478477l-73.142858 73.142857V249.417143l-149.894095 157.817905-229.205333-3.82781v243.980191l34.425905-0.024381-65.999238 66.023619a73.142857 73.142857 0 0 1-41.569524-65.999238V403.407238a73.142857 73.142857 0 0 1 68.827428-73.020952l4.924953-0.121905 197.680762 3.291429 157.915428-166.229334c34.474667-36.327619 96.060952-12.117333 96.060953 37.741714z" p-id="4266" fill="#ffffff"></path></svg>
    </i>
    <i [style]="isMuted ? 'display: none': 'display: inline-block'">
        <svg t="1728654062195" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5581" width="32" height="32"><path d="M96 309.12h178.56L521.6 64l54.4 22.4v832l-54.4 21.12-247.04-246.4H96l-32-32v-320l32-32zM310.4 640L512 840.96V163.84L310.4 363.52l-22.4 9.6H128v256h160l22.4 10.88zM960 501.12a446.08 446.08 0 0 1-100.992 283.392l-45.568-45.44A382.4 382.4 0 0 0 896 501.12c0-89.6-30.72-172.096-82.176-237.44l45.568-45.44A446.144 446.144 0 0 1 960 501.12z m-128 0a318.592 318.592 0 0 1-64.128 192.256l-45.824-45.824A254.848 254.848 0 0 0 768 501.12c0-54.208-16.832-104.512-45.632-145.92l45.824-45.824c40.064 53.44 63.808 119.808 63.808 191.744z m-128 0c0 36.736-10.24 71.04-28.16 100.224l-47.296-47.232a127.552 127.552 0 0 0 0.32-105.408l47.296-47.296c17.664 29.056 27.84 63.232 27.84 99.712z" fill="#ffffff" p-id="5582"></path></svg>
    </i>
  </button>
</div>