import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
    CartFragment,
    GetActiveOrderQuery,
    GetProductReviewQuery,
    GetProductReviewQueryVariables,
    Product,
    ProductReview,
    SubmitProductReviewMutation,
    SubmitProductReviewMutationVariables
} from '../../../common/generated-types';

import { ModalService } from '../../../core/providers/modal/modal.service';
import { DataService } from '../../../core/providers/data/data.service';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { InputReviewModalComponent } from '../input-review-form/input-review-form.component';
import { NGXLogger } from 'ngx-logger';
import { GET_PRODUCT_REVIEW, SUBMIT_PRODUCT_REVIEW } from '../../../common/graphql/documents.graphql';
import { take } from 'rxjs/operators';
import { extractStoreUrl } from '../../../common/utils/extract-store-url';
import { ActivatedRoute } from '@angular/router';

type ReviewStatus = GetProductReviewQuery['productReview'] | null | 'loading';
@Component({
    selector: 'vsf-cart-contents',
    templateUrl: './cart-contents.component.html',
    styleUrls: ['./cart-contents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartContentsComponent implements OnInit {
    @Input() cart: GetActiveOrderQuery['activeOrder'];
    @Input() canAdjustQuantities = false;
    @Input() enableReview = false;
    @Output() setQuantity = new EventEmitter<{ itemId: string; quantity: number; }>();
    static readonly DEFAULT_NICKNAME = "Dealomega Customer";
    reviewStatusMap: Map<string, ReviewStatus> = new Map();
    storePath: string;
    baseProductUrl = '/dp/';

    constructor(private modalService: ModalService,
                private dataService: DataService,
                private route: ActivatedRoute,
                private notificationService: NotificationService,
                private changeDetectorRef: ChangeDetectorRef,
                private logger: NGXLogger) { }

    ngOnInit(): void {
        if(this.enableReview && this.cart?.lines) {
            this.cart?.lines.forEach(line => {
                const productId = line.productVariant.product.id;
                this.reviewStatusMap.set(productId, 'loading');
            });
            const promises = this.cart?.lines.map(async (line) => {
                const result = await this.getReview(line);
                this.reviewStatusMap.set(line.productVariant.product.id, result);
            })||[];
            Promise.all(promises).then(() => {
                this.logger.debug('Review status map:', this.reviewStatusMap);
                this.changeDetectorRef.markForCheck();
            });
        }
        const snapshot = this.route.snapshot;
        this.baseProductUrl = `${extractStoreUrl(snapshot)}/dp`;
    }

    increment(item: CartFragment['lines'][number]) {
        this.setQuantity.emit({ itemId: item.id, quantity: item.quantity + 1 });
    }

    decrement(item: CartFragment['lines'][number]) {
        this.setQuantity.emit({ itemId: item.id, quantity: item.quantity - 1 });
    }

    trackByFn(index: number, line: { id: string; }) {
        return line.id;
    }

    trackByDiscount(index: number, discount: CartFragment['discounts'][number]) {
        return discount.adjustmentSource;
    }

    isDiscounted(line: CartFragment['lines'][number]): boolean {
        return line.discountedLinePriceWithTax < line.linePriceWithTax;
    }

    /**
     * Filters out the Promotion adjustments for an OrderLine and aggregates the discount.
     */
    getLinePromotions(adjustments: CartFragment['discounts']) {
        const groupedPromotions = adjustments.filter(a => a.type === 'PROMOTION')
            .reduce((groups, promotion) => {
                if (!groups[promotion.description]) {
                    groups[promotion.description] = promotion.amount;
                } else {
                    groups[promotion.description] += promotion.amount;
                }
                return groups;
            }, {} as { [description: string]: number; });
        return Object.entries(groupedPromotions).map(([key, value]) => ({ description: key, amount: value }));
    }

    async getReview(line: CartFragment['lines'][number]): Promise<GetProductReviewQuery['productReview']|null> {
        if (!this.enableReview) {
            return null;
        }
        try {
            const result = await this.dataService.query<GetProductReviewQuery, GetProductReviewQueryVariables>(
                GET_PRODUCT_REVIEW,
                { productId: line.productVariant.product.id, customerId: this.cart?.customer?.id }
            ).pipe(take(1)).toPromise();
            return result?.productReview;
        } catch (error) {
            this.logger.error('Error fetching review:', error);
            return null;
        }
    }

    async openInputProductReviewForm(line: CartFragment['lines'][number]) {
        this.logger.debug('Opening review form for product', line.productVariant.product.slug);

        this.modalService.fromComponent(InputReviewModalComponent, {
            size: 'xl',
            locals: {
                title: 'Write your review',
                product: line.productVariant.product as Product,
            },
            closable: true,
        }).subscribe(async (result) => {
            this.logger.debug('Review form submitted', result);
            if (result) {
                const reviewVariantId = line.productVariant.id;
                const reviewCustomerId = this.cart?.customer?.id;
                const reviewProductId = line.productVariant.product?.id || '';
                const reviewSummary = result.summary;
                const reviewContent = result.content;
                const reviewRating = result.rating;
                const reviewAuthorName = this.cart?.customer?.customFields?.nickname || CartContentsComponent.DEFAULT_NICKNAME;
                // Here you would call your mutation to submit the review
                try {
                    this.dataService.mutate<SubmitProductReviewMutation, SubmitProductReviewMutationVariables>(
                        SUBMIT_PRODUCT_REVIEW,
                        {
                            input: {
                                productId: reviewProductId,
                                variantId: reviewVariantId,
                                customerId: reviewCustomerId,
                                summary: reviewSummary,
                                body: reviewContent,
                                rating: reviewRating,
                                authorName: reviewAuthorName,
                            },
                        }
                    ).subscribe((result) => {
                        switch (result.submitProductReview.__typename) {
                            case 'ProductReview':
                                this.logger.debug(`Review submitted result: ${JSON.stringify(result)}`);
                                this.notificationService.success('Thank you for your review!').subscribe();
                                this.dataService.query<GetProductReviewQuery, GetProductReviewQueryVariables>(
                                    GET_PRODUCT_REVIEW,
                                    { productId: line.productVariant.product.id, customerId: this.cart?.customer?.id },
                                    'network-only'
                                ).pipe(take(1)).subscribe((result) => {});

                                this.reviewStatusMap.set(line.productVariant.product.id, result.submitProductReview);
                                this.changeDetectorRef.markForCheck();
                                break;
                            case 'SubmitProductReviewError':
                                this.logger.error('Error submitting review', result.submitProductReview);
                                this.notificationService.error(result.submitProductReview.submitProductReviewErrorMessage).subscribe();
                                break;
                        }
                    });
                } catch (error) {
                    // Handle error
                    this.logger.error('Error submitting review', error);
                    this.notificationService.error((error as any).message).subscribe();
                };
            }
        });
    }
}
