<div class="flex justify-center min-h-screen">
    <div class="w-4/5 mx-auto mt-10 items-center">
        <form [formGroup]="orderForm" *ngIf="!(order$ | async)" class="w-full max-w-md mx-auto">
            <div class="mt-2 mb-6">
                <p class="block mb-2 font-bold text-gray-700">Order Status</p>
                <p class="block mb-2 text-sm text-gray-700">To check the status of a recent order, please enter the information provided on your order confirmation email.</p>
            </div>
            <div class="mb-6">
                <label for="orderCode" class="block mb-2 font-bold text-gray-700">Order Code:</label>
                <input type="text" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter your order code (required)" id="orderCode" formControlName="orderCode" name="orderCode" required>
            </div>
            <div class="mb-6">
                <label for="emailAddress" class="block mb-2 font-bold text-gray-700">Email Address (used for the order):</label>
                <input type="text" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter your email address (required)" id="emailAddress" formControlName="emailAddress" name="emailAddress" required>
                <div *ngIf="(orderForm.get('emailAddress').invalid && orderForm.get('emailAddress').touched) || orderForm.get('emailAddress').dirty" class="text-red-500 text-sm mt-2">
                    <small *ngIf="orderForm.get('emailAddress').errors?.required">Email is required.</small>
                    <small *ngIf="orderForm.get('emailAddress').errors?.email">Please enter a valid email address.</small>
                </div>
            </div>
            <div class="text-center">
                <button type="button" class="btn-primary w-full" [disabled]="orderForm.invalid || orderForm.pristine" (click)="onSubmit()">Track Order</button>
            </div>
            <div class="text-center mt-4">
                <div class="relative text-center my-4">
                    <div class="absolute inset-0 flex justify-center items-center">
                      <hr class="w-full border-t" />
                    </div>
                    <div class="relative bg-white px-4 inline-block">
                      <p class="text-gray-800">Have an account with us?</p>
                    </div>
                </div>
                <button type="button" class="btn-primary  w-full" (click)="navigateToSignIn()">Sign In</button>
            </div>
        </form>
        <ng-container *ngIf="errorMessage">
            <div class="text-center mt-4 text-red-600">{{ errorMessage }}</div>
        </ng-container>
        <ng-container *ngIf="order$ | async as order">
            <div class="w-4/5 mx-auto mt-20">
                <h2 class="text-2xl mb-2 text-center">Order <span class="font-medium">{{ order.code }}</span></h2>
                <div class="mb-2 text-gray-600 text-center">Placed on <span class="font-medium">{{ order.orderPlacedAt | date:'medium' }}</span></div>
                <div class="mb-4 text-gray-600 text-center">Status: <span class="font-medium">{{ order.state }}</span></div>
                <div class="md:grid grid-cols-3"> <div class="col-span-2"> <div *ngIf="order" class="flow-root">
                    <ul role="list" class="divide-y divide-gray-200">
                        <ng-container *ngFor="let line of order.lines; trackBy: trackByFn">
                            <li class="py-6 flex">
                                <div class="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                                    <img [src]="(line.featuredAsset || line.productVariant.featuredAsset) | assetPreview:'thumb'"
                                    style="pointer-events: none;"
                                    [alt]="line.productVariant.name"
                                    class="rounded w-full h-full aspect-[1/1]"/>
                                </div>
                                <div class="ml-4 flex-1 flex flex-col">
                                    <div>
                                        <div class="flex justify-between text-base font-medium text-gray-900">
                                            <p>
                                                {{ line.productVariant.name }}
                                            </p>
                                            <p class="ml-4">
                                                {{ (line.unitPrice*line.quantity) | formatPrice }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex items-center justify-between text-sm text-gray-600">
                                        <div class="flex space-x-4">
                                            <div class="qty">{{ line.quantity }}</div>
                                        </div>
                                        <div class="total">
                                            <div class="">
                                                {{ line.unitPrice | formatPrice }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                        </div>
                        <dl class="border-t mt-6 border-gray-200 py-6 space-y-6">
                            <div class="flex items-center justify-between">
                                <dt class="text-sm">Subtotal</dt>
                                <dd class="text-sm font-medium text-gray-900">{{ order.subTotal | formatPrice }}</dd>
                            </div>
                            <div class="flex items-center justify-between">
                                <dt class="text-sm">
                                    Shipping
                                    <span class="text-gray-600"><span class="" *ngFor="let line of order.shippingLines">({{ line.shippingMethod.name }})</span></span>
                                </dt>
                                <dd class="text-sm font-medium text-gray-900">
                                    {{ order.shippingWithTax | formatPrice }}
                                </dd>
                            </div>
                            <div class="flex items-center justify-between">
                                <!-- Conditionally display Tax value or 'calculate later' message -->
                                <ng-container *ngIf="order.state==='AddingItems' && (order.subTotalWithTax-order.subTotal) == 0; else taxValue">
                                    <dt class="text-sm">Tax(Calculate Later)</dt>
                                    <dd class="text-sm font-medium text-gray-900"> ---- </dd>
                                </ng-container>
                                <ng-template #taxValue>
                                    <dt class="text-sm">Estimated tax</dt>
                                    <dd class="text-sm font-medium text-gray-900">{{ (order.subTotalWithTax-order.subTotal)  | formatPrice }}</dd>
                                </ng-template>
                            </div>
                            <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                                <dt class="text-base font-medium">Total</dt>
                                <dd class="text-base font-medium text-gray-900">
                                    {{ order.totalWithTax | formatPrice }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <vsf-order-actions-form
                        [order]="order"
                        [isGuest]="true"
                        [emailAddress]="orderForm.value.emailAddress"
                    ></vsf-order-actions-form>
                </div>
            </div>
        </ng-container>
    </div>
</div>
