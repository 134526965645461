<div *ngIf="order" class="pl-6">
    <vsf-address-card title="Shipping address" [address]="order.shippingAddress"></vsf-address-card>
    <vsf-card *ngIf="order.fulfillments && order.fulfillments.length > 0">
        <p class="text-center text-gray-600 mt-15">Tracking Info</p>
        <div *ngFor="let fulfillment of order.fulfillments">
            <vsf-tracking-card
                *ngIf="!!fulfillment"
                [fulfillment]="fulfillment"
                [order]="order"
            >
            </vsf-tracking-card>
        </div>
    </vsf-card>
    <vsf-card *ngIf="!enableCancel && !enableReturn && disabledPolicyNotification">
        <div class="px-2 py-2">
            <p class="text-left text-gray-900" [innerHtml]="disabledPolicyNotification | safeHtml">
            </p>
        </div>
    </vsf-card>
    <vsf-card  class="mt-15" *ngIf="enableCancel && ((isCancellable() && totalCancellableItems > 0) || cancellationRequestsItems?.length > 0)">
        <p class="text-center text-gray-600 mt-5">Cancellation Info</p>
        <button *ngIf="isCancellable() && totalCancellableItems > 0"
            class="btn btn-primary w-full mb-2"
            (click)="openReturnRequestForm('Cancellation')">
            Cancel
        </button>
        <div *ngIf="cancellationRequestsItems?.length > 0">
            <div *ngFor="let cancellationRequest of cancellationRequestsItems" class="flex flex-col items-center mb-2">
                <vsf-return-card
                    *ngIf="!!cancellationRequest"
                    [returnRequest]="cancellationRequest"
                    [order]="order"
                >
                </vsf-return-card>
            </div>
        </div>
    </vsf-card>
    <vsf-card *ngIf="enableCancel && (isDisplayCancellableNotification() && totalCancellableItems > 0)">
        <div class="px-2 py-2">
            <p class="text-left text-gray-900" [innerHtml]="cancelExpiredNotification | safeHtml">
            </p>
        </div>
    </vsf-card>
    <vsf-card  class="mt-15" *ngIf="enableReturn && ((isReturnable() && totalReturnableItems > 0) || returnRequestsItems?.length > 0)">
        <p class="text-center text-gray-600 mt-5">Return Info</p>
        <button *ngIf="isReturnable() && totalReturnableItems > 0"
            class="btn btn-primary w-full mb-2"
            (click)="openReturnRequestForm('Return')">
            Return
        </button>
        <div *ngIf="returnRequestsItems?.length > 0">
            <div *ngFor="let returnRequest of returnRequestsItems" class="flex flex-col mb-2 items-center">
                <vsf-return-card
                    *ngIf="!!returnRequest"
                    [returnRequest]="returnRequest"
                    [order]="order"
                >
                </vsf-return-card>
                <button
                    *ngIf="returnRequest.type === 'Return'"
                    class="btn border rounded bg-primary-400 font-bold text-white whitespace-nowrap px-3 py-1"
                    (click)="openReturnRequestRMAForm(returnRequest)">
                RMA Form</button>
            </div>
        </div>
    </vsf-card>
    <vsf-card *ngIf="enableReturn && (isReturnable() && order?.state !== 'Cancelled') && returnExpiredNotification">
        <div class="px-2 py-2">
            <p class="text-left text-gray-900" [innerHtml]="returnExpiredNotification | safeHtml" >
            </p>
        </div>
    </vsf-card>
</div>