import { Component, OnInit, ElementRef, Renderer2, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Collection } from '../../../common/generated-types';
import { Subject } from 'rxjs';
import { CollectionGroupData } from '../../../common/interfaces';

@Component({
  selector: 'vsf-collection-group-carousel',
  templateUrl: './collection-group-carousel.component.html',
  styleUrls: ['./collection-group-carousel.component.scss']
})
export class CollectionGroupCarouselComponent implements OnInit, OnDestroy {
  @Input() collectionGroup: CollectionGroupData;
  collectionsList: Collection[] = [];
  displayedCollections: Collection[] = [];
  startIndex = 0;
  itemsPerPage = 5;
  groupName: string;
  private destroy$ = new Subject();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.groupName = this.collectionGroup?.groupName;
    this.collectionsList = this.collectionGroup?.collections;
    if(this.collectionsList && this.collectionsList.length > 0) {
      this.updateDisplayedCollections();
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackByCollectionId(index: number, item:any): string {
    return item.id;
  }

  showPreviousItems(): void {
    this.startIndex -= this.itemsPerPage;
    this.updateDisplayedCollections();
  }

  showNextItems(): void {
    this.startIndex += this.itemsPerPage;
    this.updateDisplayedCollections();
  }

  setItemsPerPage(itemsPerPage: number = this.itemsPerPage): void {
    this.itemsPerPage = itemsPerPage;
    this.renderer.setProperty(this.elementRef.nativeElement, 'style', `--items-per-page: ${this.itemsPerPage}`);
    this.updateDisplayedCollections();
  }

  private updateDisplayedCollections(): void {
      this.displayedCollections = this.collectionsList.slice(this.startIndex, this.startIndex + this.itemsPerPage);
  }
}