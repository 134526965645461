import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductReview } from '../../../common/generated-types';

@Component({
    selector: 'vsf-top-reviews',
    templateUrl: './top-reviews.component.html',
    styleUrls: ['./top-reviews.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopReviewsComponent {
    @Input() reviews: ProductReview[] = [];
    @Input() isMobile: boolean;
}
