import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'vsf-product-review-indicator',
  template: `
    <div class="review-indicator">
      <div class="stars">
        <fa-icon *ngFor="let _ of fullStars" [icon]="'star'" class="mr-2 orange"></fa-icon>
        <fa-icon *ngIf="halfStar" [icon]="'star-half-alt'" class="mr-2 orange"></fa-icon>
        <fa-icon *ngFor="let _ of emptyStars" [icon]="'star'" class="mr-2 hollow"></fa-icon>
      </div>
      <div *ngIf="displayReviewNumbers" class="review-number">{{ inputReviewNumber }} reviews</div>
    </div>
  `,
  styles: [`
    .orange {
      color: orange;
    }
    .hollow {
      color: lightgray;
    }
    .mr-2 {
      margin-right: 0.5rem;
    }
    .review-indicator {
      display: flex;
      align-items: center;
    }
    .review-number {
      margin-left: 1rem;
    }
  `]
})
export class ProductReviewIndicatorComponent implements OnInit, OnChanges {
  @Input() inputScore: number = 5.0;
  @Input() inputReviewNumber: number = 1;
  @Input() displayReviewNumbers: boolean = true;

  fullStars: number[] = [];
  halfStar: boolean = false;
  emptyStars: number[] = [];

  constructor(private logger: NGXLogger) { }

  ngOnInit(): void {
      if (!this.inputScore) {
          this.inputScore = 5;
      }
      if (!this.inputReviewNumber) {
          this.inputReviewNumber = 1;
      }
      this.calculateStars();
  }

  ngOnChanges() {
      this.calculateStars();
  }

  calculateStars() {
    this.logger.debug(`[ReviewIndicatorComponent.ngOnChanges]score: ${this.inputScore}, reviewNumber: ${this.inputReviewNumber}`);
    const fullStarCount = Math.floor(this.inputScore) + (this.inputScore % 1 > 0.67 ? 1 : 0);
    const hasHalfStar = (this.inputScore % 1 >= 0.33) && (this.inputScore % 1 <= 0.67);
    this.fullStars = Array(fullStarCount).fill(0);
    this.halfStar = hasHalfStar;
    this.emptyStars = Array(5 - fullStarCount - (hasHalfStar ? 1 : 0)).fill(0);
    this.logger.debug(`[ReviewIndicatorComponent.ngOnChanges]fullStars: ${this.fullStars}, halfStar: ${this.halfStar}, emptyStars: ${this.emptyStars}`);
  }
}
