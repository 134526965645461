import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { from, interval, merge, Observable, of, Subject, timer, zip } from 'rxjs';
import { delay, distinctUntilChanged, map, refCount, share, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { GetActiveOrderQuery, GetCartTotalsQuery } from '../../../common/generated-types';
import { DataService } from '../../providers/data/data.service';
import { StateService } from '../../providers/state/state.service';

import { GET_CART_TOTALS } from './cart-toggle.graphql';
import { GET_ACTIVE_ORDER } from '../../providers/active/active.service.graphql';
import { retry } from 'ts-retry-promise';
import { isPlatformBrowser } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { FetchPolicy } from '@apollo/client';

@Component({
    selector: 'vsf-cart-toggle',
    templateUrl: './cart-toggle.component.html',
    styleUrls: ['./cart-toggle.component.scss'],
})
export class CartToggleComponent implements OnInit, OnDestroy {
    @Output() toggle = new EventEmitter<void>();
    activeOrderId: string | null = null;
    isQueryValid: boolean = false;
    cart$: Observable<{ total: number; quantity: number; }>;
    cartChangeIndication$: Observable<boolean>;
    private static readonly MAX_QUERY_RETRIES = 15;
    private static readonly QUERY_INTERVAL = 200;
    private destroy$: Subject<void> = new Subject<void>();
    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private dataService: DataService,
                private stateService: StateService,
                private logger: NGXLogger) {
    }

    ngOnInit() {
        this.cart$ = merge(
            this.stateService.select(state => state.activeOrderId),
            this.stateService.select(state => state.signedIn),
        ).pipe(
            switchMap(() => this.dataService.query<GetCartTotalsQuery>(GET_CART_TOTALS, {}, 'network-only')),
            map(({activeOrder}) => {
                if(activeOrder?.active) {
                    return {
                        total: activeOrder ? activeOrder.totalWithTax : 0,
                        quantity: activeOrder ? activeOrder.totalQuantity : 0,
                    };
                } else {
                    return {
                        total: 0,
                        quantity: 0,
                    };
                }
            }),
            shareReplay(1),
            takeUntil(this.destroy$)
        );
        this.stateService.select(state => state.activeOrderId).pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((activeOrderId) => {
            this.activeOrderId = activeOrderId;
        });
        this.cartChangeIndication$ = this.cart$.pipe(
            map(cart => cart.quantity),
            distinctUntilChanged(),
            switchMap(() => zip(
                    from([true, false]),
                    timer(0, 1000),
                    val => val,
                ),
            ),
        );
        this.stateService.select(state => state.clientInitState).pipe(distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe((clientInitState) => {
            if (isPlatformBrowser(this.platformId) && !clientInitState) {
                this.fetchActiveOrderWithRetry().then(() => {
                    this.stateService.setState('clientInitState', true);
                });
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private fetchActiveOrder(type = 'cache-first') {
        this.dataService.query<GetActiveOrderQuery>(GET_ACTIVE_ORDER, {}, type as FetchPolicy)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(data => {
            if(data?.activeOrder) {
                this.stateService.setState('activeOrderId', data?.activeOrder.id);
                this.activeOrderId = data?.activeOrder.id;
                this.logger.debug(`[${new Date().toISOString()}][cart-toggle.component] fetched activeOrder.`);
            }
            this.isQueryValid = true;
        });
    }

    async fetchActiveOrderWithRetry() {
        try {
            await retry(
                async() => this.fetchActiveOrder('network-only'),
                {
                    retries: CartToggleComponent.MAX_QUERY_RETRIES,
                    delay: CartToggleComponent.QUERY_INTERVAL,
                    until: () => this.isQueryValid,
                }
            );
        } catch (error) {
            this.logger.error(`[${new Date().toISOString()}][cart-toggle.component] Fetching after max retries.`);
        }
    }

}
