import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Asset, GetStoreContactInfoQuery, GetStoreContactInfoQueryVariables } from '../../../common/generated-types';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../providers/data/data.service';
import { filter, map, take } from 'rxjs/operators';
import { GET_STORESITE_CONTACT_INFO } from '../../../common/graphql/documents.graphql';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { isPlatformBrowser } from '@angular/common';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { ColorConfig, ContactPageData } from '../../../common/interfaces';
import { BehaviorSubject } from 'rxjs';

interface ContactMessage {
    name: string;
    surname: string;
    email: string;
    message: string;
};

@Component({
    selector: 'vsf-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPageComponent implements OnInit {
    identifier: string;
    vendorEmail: string;
    contactPage: ContactPageData | null;
    colorConfig: ColorConfig | null;
    posterAsset: Asset;
    contactFormType: string;
    featuredAsset: Asset;
    maxMessageLength = 600;
    isMobile: boolean | null = null;
    readyToDisplay$ = new BehaviorSubject<boolean>(false);

    contact: ContactMessage = {
        name: '',
        surname: '',
        email: '',
        message: ''
    };

    constructor(
        private logger: NGXLogger,
        private route: ActivatedRoute,
        private dataService: DataService,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit(): void {
        this.checkViewport();
        this.logger.info('[Contact page] init');
        this.route.paramMap.pipe(
            map(paramMap => paramMap.get('identifier')),
            filter(notNullOrUndefined),
            take(1),
        ).subscribe(identifier => {
            this.logger.info('[Contact page] identifier:', identifier);
            this.dataService.query<GetStoreContactInfoQuery, GetStoreContactInfoQueryVariables>(GET_STORESITE_CONTACT_INFO, {
                identifier,
            }).subscribe(result => {
                this.vendorEmail = result.storeSite?.seller?.customFields?.email || '';
                this.colorConfig = safeJSONParse<ColorConfig>(result.storeSite?.colorConfig, this.logger);
                this.contactPage = result?.storeSite?.contactPageData as ContactPageData;
                if(this.contactPage) {
                    this.logger.info('[Contact page] content:', this.contactPage);
                    this.posterAsset = this.contactPage.posterAsset || this.posterAsset;
                    this.contactFormType = this.contactPage.contactFormType || this.contactFormType;
                    this.featuredAsset = this.contactPage.featuredAsset || this.featuredAsset;
                }
                this.readyToDisplay$.next(true);
                this.changeDetectorRef.markForCheck();
            });
        })
    }

    @HostListener('window:resize', [])
    onResize() {
      this.checkViewport();
    }

    private checkViewport() {
      if(isPlatformBrowser(this.platformId)){
        const mobileIndicator = document.getElementById('mobile-indicator');
        if(mobileIndicator && window) {
            this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
        }
      }
    }

    onMessageInput(message: string): void {
        // No need to cut off the message, just track it for length
        this.contact.message = message;
    }

    onSubmit(): void {
        const mailtoLink = `mailto:${this.vendorEmail}?subject=Contact%20Form%20Submission&body=Name:%20${encodeURIComponent(this.contact.name)}%0A
        Surname:%20${encodeURIComponent(this.contact.surname)}%0A
        Email:%20${encodeURIComponent(this.contact.email)}%0A
        Message:%20${encodeURIComponent(this.contact.message)}`;
      
        // Open the mail client with the constructed mailto link
        if(window) {
            window.location.href = mailtoLink;
        }
    }
}
