import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AddressFragment,
    CountryFragment,
    CreateAddressInput,
    CreateAddressMutation,
    CreateAddressMutationVariables,
    GetAvailableCountriesQuery,
    ValidateAddressMutation,
    ValidateAddressMutationVariables
} from '../../../common/generated-types';
import { GET_AVAILABLE_COUNTRIES } from '../../../common/graphql/documents.graphql';
import { DataService } from '../../../core/providers/data/data.service';
import { Dialog } from '../../../core/providers/modal/modal-types';

import { CREATE_ADDRESS } from './address-modal.graphql';
import { VALIDATE_ADDRESS } from '../../../checkout/components/checkout-shipping/checkout-shipping.graphql';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'vsf-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AddressModalComponent implements Dialog<AddressFragment>, OnInit {
    resolveWith: (result?: any) => void;
    address: AddressFragment;
    title: string;
    addressValidationErrorMessage: string | undefined;
    availableCountries$: Observable<CountryFragment[]>;
    constructor(private dataService: DataService,
                private logger: NGXLogger) {}

    ngOnInit() {
        this.availableCountries$ = this.dataService.query<GetAvailableCountriesQuery>(GET_AVAILABLE_COUNTRIES).pipe(
            map(data => data.availableCountries),
        );
    }

    async save(value: CreateAddressInput) {
        this.addressValidationErrorMessage = '';
        const isAddressValid = await this.validateAddress(value);
        if (isAddressValid) {
            this.dataService.mutate<CreateAddressMutation, CreateAddressMutationVariables>(CREATE_ADDRESS, {
                input: value,
            }).subscribe(data => {
                this.resolveWith(data.createCustomerAddress);
            });
        }
    }

    async validateAddress(address: CreateAddressInput): Promise<boolean> {
        try {
          const result = await this.dataService.mutate<ValidateAddressMutation, ValidateAddressMutationVariables>(
            VALIDATE_ADDRESS,
            { input: address }
          ).toPromise();

          if (result?.validateAddress.success) {
            this.addressValidationErrorMessage = result?.validateAddress?.message;
            return true;
          } else {
            this.addressValidationErrorMessage = result?.validateAddress?.message || 'Address validation failed';
            return false;
          }
        } catch (error) {
          this.logger.error('An error occurred during address validation', error);
          this.addressValidationErrorMessage = 'An error occurred during address validation';
          return false;
        }
    }

    cancel() {
        this.resolveWith(undefined);
    }
}
