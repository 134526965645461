import { Component, OnInit, ElementRef, Renderer2, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { Collection, GetCollectionsQuery, GetCollectionsQueryVariables } from '../../../common/generated-types';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { GET_COLLECTIONS } from '../../../common/graphql/documents.graphql';
import { NGXLogger } from 'ngx-logger';
import { ColorConfig } from '../../../common/interfaces';

@Component({
  selector: 'vsf-collections-carousel',
  templateUrl: './collections-carousel.component.html',
  styleUrls: ['./collections-carousel.component.scss']
})
export class CollectionsCarouselComponent implements OnInit, OnDestroy {
  @Input() collectionsList: Collection[] = [];
  @Input() colorConfig: ColorConfig | null;
  collections$: Observable<GetCollectionsQuery['collections']['items']>;
  displayedCollections: GetCollectionsQuery['collections']['items'];
  collectionLength: number;
  startIndex = 0;
  itemsPerPage = 3;
  private destroy$ = new Subject();

  constructor(
    private dataService: DataService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private logger: NGXLogger
  ) {}

  ngOnInit(): void {
    this.logger.info(`[Collections carousel] init ${JSON.stringify(this.collectionsList)}`);
    if(this.collectionsList && this.collectionsList.length > 0) {
      this.displayedCollections = this.collectionsList.slice(this.startIndex, this.startIndex + this.itemsPerPage);
      this.collectionLength = this.collectionsList.length;
      this.changeDetectorRef.detectChanges();
    } else {
      this.collections$ = this.dataService.query<GetCollectionsQuery, GetCollectionsQueryVariables>(GET_COLLECTIONS, {
        options: { take: 100 },
      }).pipe(map(({collections}) => collections.items), takeUntil(this.destroy$));
      this.collections$.subscribe(collections => {
        this.displayedCollections = collections.slice(this.startIndex, this.startIndex + this.itemsPerPage);
        this.collectionLength = collections.length;
        this.logger.debug(collections, this.displayedCollections);
        this.changeDetectorRef.detectChanges();
      });
    }
    this.setItemsPerPage(this.itemsPerPage);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackByCollectionId(index: number, item: GetCollectionsQuery['collections']['items'][number]): string {
    return item.id;
  }

  showPreviousItems(): void {
    this.startIndex = Math.max(this.startIndex - this.itemsPerPage, 0);
    this.updateDisplayedCollections();
  }

  showNextItems(): void {
    if(this.collectionsList && this.collectionsList.length > 0) {
      this.startIndex = Math.min(this.startIndex + this.itemsPerPage, this.collectionsList.length - this.itemsPerPage);
      this.updateDisplayedCollections();
    } else {
      this.collections$.subscribe(collections => {
        this.startIndex = Math.min(this.startIndex + this.itemsPerPage, collections.length - this.itemsPerPage);
        this.collectionLength = collections.length;
        this.updateDisplayedCollections();
      });
    }
  }

  setItemsPerPage(itemsPerPage: number = this.itemsPerPage): void {
    this.itemsPerPage = itemsPerPage;
    this.renderer.setProperty(this.elementRef.nativeElement, 'style', `--items-per-page: ${this.itemsPerPage}`);
    this.updateDisplayedCollections();
  }

  private updateDisplayedCollections(): void {
    if(this.collectionsList && this.collectionsList.length > 0) {
      this.displayedCollections = this.collectionsList.slice(this.startIndex, this.startIndex + this.itemsPerPage);
    } else {
      this.collections$.subscribe(collections => {
        this.displayedCollections = collections.slice(this.startIndex, this.startIndex + this.itemsPerPage);
        this.collectionLength = collections.length;
      });
    }
  }
}