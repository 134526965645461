import { Injectable , Inject , PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { NGXLogger } from 'ngx-logger';

export interface AppState {
    signedIn: boolean;
    activeOrderId: string | null;
    lastCollectionSlug: string | null;
    mobileNavMenuIsOpen: boolean;
    cartDrawerOpen: boolean;
    clientInitState: boolean;
    storePath: string;
    fullscreenPreviewOpen: number | null;
}

export const initialState: AppState = {
    signedIn: false,
    activeOrderId: null,
    lastCollectionSlug: null,
    mobileNavMenuIsOpen: false,
    cartDrawerOpen: false,
    clientInitState: false,
    storePath: '',
    fullscreenPreviewOpen: null,
};

/**
 * A simple, observable store of global app state.
 */
@Injectable({
    providedIn: 'root',
})
export class StateService {
    private state: AppState = initialState;
    private readonly stateSubject = new BehaviorSubject<AppState>(initialState);

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private logger: NGXLogger) {
        let savedState = undefined;
        if (isPlatformBrowser(this.platformId) && typeof localStorage !== 'undefined') {
            savedState = localStorage.getItem('appState');
        }
        this.state = savedState ? safeJSONParse<AppState>(savedState, this.logger)||initialState : initialState;
        this.state.clientInitState = false;
        this.stateSubject = new BehaviorSubject<AppState>(this.state);

        if (typeof window !== 'undefined') {
            Object.defineProperty(window, 'appState', {
                get: () => this.stateSubject.value,
            });
        }

        this.stateSubject.subscribe(state => {
            if (isPlatformBrowser(this.platformId) && typeof localStorage !== 'undefined') {
                localStorage.setItem('appState', JSON.stringify(state));
            }
        });
    }

    setState<T extends keyof AppState>(key: T, value: AppState[T]) {
        this.state[key] = value;
        this.stateSubject.next(this.state);
    }

    select<R>(selector: (state: AppState) => R): Observable<R> {
        return this.stateSubject.pipe(
            map(selector),
            distinctUntilChanged(),
        );
    }
}
