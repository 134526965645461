import { ActivatedRouteSnapshot } from "@angular/router";

function getFullUrl(route: ActivatedRouteSnapshot): string {
    const segments: string[] = [];
    let currentRoute: ActivatedRouteSnapshot | null = route;

    while (currentRoute) {
        if (currentRoute.url) {
            const segment = currentRoute.url.map(s => s.path).join('/');
            if (segment) {
                segments.unshift(segment);
            }
        }
        currentRoute = currentRoute.parent;
    }

    return '/' + segments.join('/');
}

export function extractStoreUrl(route: ActivatedRouteSnapshot): string {
    const url = getFullUrl(route).split('?')[0];
    const storeMatch = url.match(/^\/store\/([^\/?]+)(\/|$|\?)/);
    if (storeMatch){
        return `/store/${storeMatch[1]}`;
    }
    return '';
}