import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DialogButtonsDirective } from '../core/components/modal-dialog/dialog-buttons.directive';
import { DialogComponentOutletComponent } from '../core/components/modal-dialog/dialog-component-outlet.component';
import { DialogTitleDirective } from '../core/components/modal-dialog/dialog-title.directive';
import { ModalDialogComponent } from '../core/components/modal-dialog/modal-dialog.component';
import { NotificationComponent } from '../core/components/notification/notification.component';

import { AddressCardComponent } from './components/address-card/address-card.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { CartContentsComponent } from './components/cart-contents/cart-contents.component';
import { CartTotalsComponent } from './components/cart-totals/cart-totals.component';
import { CenteredCardComponent } from './components/centered-card/centered-card.component';
import { DropdownContentDirective } from './components/dropdown/dropdown-content.directive';
import { DropdownTriggerDirective } from './components/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { RadioCardFieldsetComponent } from './components/radio-card/radio-card-fieldset.component';
import { RadioCardComponent } from './components/radio-card/radio-card.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AssetPreviewPipe } from './pipes/asset-preview.pipe';
import { FormatPricePipe } from './pipes/format-price.pipe';
import { ProductUrlPipe } from './pipes/product-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { TrackingCardComponent } from '../shared/components/tracking-card/tracking-card.component';
import { ReturnCardComponent } from '../shared/components/return-card/return-card.component';
import { ReturnRequestModalComponent } from '../shared/components/return-request-modal/return-request-modal.component';
import { ReturnRequestRmaFormComponent } from './components/return-request-rma-form/return-request-rma-form.component';
import { InputReviewModalComponent } from './components/input-review-form/input-review-form.component';
import { SeparatedFormatPricePipe } from './pipes/separated-format-price.pip';
import { ProductReviewIndicatorComponent } from './components/product-review-indicator/product-review-indicator';
import { ProductReviewModalComponent } from './components/product-review-modal/product-review-modal.component';
import { HeroSectionComponent } from './components/hero-section/hero-section.component';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { CollectionCardV2Component } from './components/collection-card-v2/collection-card-v2.component';
import { ProductCardV2Component } from './components/product-card-v2/product-card-v2.component';
import { StoreLogoComponent } from './components/store-logo/store-logo.component';
import { TwoCollectionCardComponent } from './components/two-collection-card/two-collection-card.component';
import { CouponFormComponent } from './components/coupon-form/coupon-form.component';
import { TwoCollectionCardComponentV2 } from './components/two-collection-card-v2/two-collection-card-v2.component';
import { OrderActionsComponent } from './components/order-actions-form/order-actions-form.component';
import { VimeoPlayerComponent } from './components/vimeo-player/vimeo-player.component';
import {VimeoFullscreenPreviewComponent } from './components/vimeo-fullscreen-preview/vimeo-fullscreen-preview.component';
import { LayoutModule } from '@angular/cdk/layout';

const SHARED_DECLARATIONS = [
    CartContentsComponent,
    AddressCardComponent,
    SignInComponent,
    AddressFormComponent,
    ProductCardComponent,
    ProductCardV2Component,
    HeroSectionComponent,
    CenteredCardComponent,
    CollectionCardComponent,
    CollectionCardV2Component,
    TwoCollectionCardComponent,
    TwoCollectionCardComponentV2,
    DropdownComponent,
    DropdownTriggerDirective,
    DropdownContentDirective,
    DialogButtonsDirective,
    DialogTitleDirective,
    DialogComponentOutletComponent,
    ModalDialogComponent,
    AddressModalComponent,
    NotificationComponent,
    FormatPricePipe,
    SeparatedFormatPricePipe,
    ProductUrlPipe,
    AssetPreviewPipe,
    SafeHtmlPipe,
    RadioCardComponent,
    RadioCardFieldsetComponent,
    CartTotalsComponent,
    ConfirmationModalComponent,
    CouponFormComponent,
    TrackingCardComponent,
    ReturnCardComponent,
    ReturnRequestModalComponent,
    StoreLogoComponent,
    InputReviewModalComponent,
    ProductReviewIndicatorComponent,
    ProductReviewModalComponent,
    OrderActionsComponent,
    ReturnRequestRmaFormComponent,
    VimeoPlayerComponent,
    VimeoFullscreenPreviewComponent,
];

const IMPORTS = [
    FontAwesomeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    RouterModule,
    LayoutModule,
];

@NgModule({
    declarations: SHARED_DECLARATIONS,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: IMPORTS,
    exports: [...IMPORTS, ...SHARED_DECLARATIONS],
})
export class SharedModule {
}
