<div class="modal-content" style="max-height: calc(100vh - 200px); overflow-y: auto;">
    <ng-template vsfDialogTitle>{{ title }}</ng-template>
    <vsf-address-form #addressFormComponent
                    [address]="address"
                    [availableCountries]="availableCountries$ | async">
    </vsf-address-form>
    <div class="bg-orange-500 text-white p-4 rounded-md text-3xl flex items-center space-x-2 sm:text-3xl font-light tracking-tight my-8" *ngIf="addressValidationErrorMessage">
        {{ addressValidationErrorMessage }}
    </div>
    <ng-template vsfDialogButtons>
        <div class="modal-footer">
            <button type="button"
                class="btn btn-primary"
                [disabled]="addressFormComponent.addressForm.invalid"
                (click)="save(addressFormComponent.addressForm.value)">OK</button>
            <button type="button"
                class="btn btn-primary"
                (click)="cancel()">Cancel</button>
        </div>
    </ng-template>
</div>
