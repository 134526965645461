import { NgxLoggerLevel } from "ngx-logger";
export const environment = {
    name: 'production',
    production: true,
    loggerLevel: NgxLoggerLevel.INFO,
    shopHost: 'https://dealomega.com',
    apiHost: 'https://api.dealomega.com',
    apiPort: 443,
    shopApiPath: 'shop-api',
    baseHref: '/',
    tokenMethod: 'cookie',
    stripePublishableKey: 'pk_live_51OwmXMP61fl65SW8ocaPMS50P5YkW9BK11BFtdEAdIvqd99hV2ZCsXCOqvoWxT6A3cIf6FJ50n1REN6BbHj7Bot000Yb8t77ZJ',
    channelToken: '',
    gaTrackingId: 'G-BPMQN2X9CC',
    reCAPTCHA_siteKey: '6LcnSwIqAAAAANS7IVZfytcDtE-gIF013_-O8Wkv',
    clarityTrackingId: 'njv1wxisfm',
    omniSendAccountId: '66b921bb3245832d9dfc958c',
    version: '0.3.1-1042-prod'
};
