import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { CartService } from '../../../core/providers/cart/cart.service';
import { ButtonData, HeroSectionData } from '../../../common/interfaces';
import { NotificationService } from '../../../core/providers/notification/notification.service';
import { Asset } from '../../../common/generated-types';
import { extractStoreUrl } from '../../../common/utils/extract-store-url';

const DELAY_TIME = 1000;

@Component({
    selector: 'vsf-hero-section',
    templateUrl: './hero-section.component.html',
    styleUrls: ['./hero-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroSectionComponent implements OnInit, AfterViewInit {
    @Input() heroSection: HeroSectionData;
    isMobile = false;

    layoutType: 'left-text-full-media' | 'right-text-full-media' | 'middle-text-full-media' = 'left-text-full-media';
    title: string;
    subtitle: string;
    titleFontSize: string;
    titleFontSizeMobile: string;
    textColor: string;
    medias: Asset[];
    button: ButtonData | null;
    buttonPosition: string;
    buttonLocationX: number;
    buttonLocationY: number;
    buttonPositionMobile: string;
    buttonLocationXMobile: number;
    buttonLocationYMobile: number;
    shadowOpacity = 0;
    inFlight = false;
    defaultHeroFontSize = 40;
    mobileHeroFontSizeRatio = 0.4;
    baseUrl = '';
    notificationService: NotificationService;
    private subscription = new Subscription();
    swiperSlideWidth = Math.min(document.documentElement.clientWidth, 1152);
    swiperSlideHeight = Math.min(Math.floor(this.swiperSlideWidth * 9 / 16), 658);
    
    private static readonly BREAK_POINT_CONFIG = [
      { query: '(max-width: 300px)', ratio: 0.3 },
      { query: '(max-width: 400px)', ratio: 0.37 },
      { query: '(max-width: 500px)', ratio: 0.45 },
      { query: '(max-width: 600px)', ratio: 0.57 },
      { query: '(max-width: 700px)', ratio: 0.65 },
      { query: '(min-width: 701px)', ratio: 0.75 }
    ];

    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private changeDetector: ChangeDetectorRef,
                private router: Router,
                private route: ActivatedRoute,
                private cartService: CartService,
                private logger: NGXLogger,
                private elementRef: ElementRef,
                private cdr: ChangeDetectorRef,
                private breakpointObserver: BreakpointObserver) {}

    ngOnInit() {
        this.checkViewport();
        this.baseUrl = extractStoreUrl(this.route.snapshot);
        this.logger.info('Hero Section', JSON.stringify(this.heroSection));
        this.layoutType = this.heroSection?.layoutType;
        this.title = this.heroSection?.title;
        this.titleFontSize = `${this.heroSection?.titleFontSize}px`;
        if (isPlatformBrowser(this.platformId)) {
          const breakpointQueries = HeroSectionComponent.BREAK_POINT_CONFIG.map(config => config.query);
          this.breakpointObserver
            .observe(breakpointQueries)
            .subscribe(result => {
              const breakpoints = result.breakpoints;
              const matchedBreakpoint = HeroSectionComponent.BREAK_POINT_CONFIG.find(config => breakpoints[config.query]);
              if (matchedBreakpoint) {
                this.mobileHeroFontSizeRatio = matchedBreakpoint.ratio;
              } else {
                this.mobileHeroFontSizeRatio = 0.75;
              }
              this.titleFontSizeMobile = `${(this.heroSection?.titleFontSize || this.defaultHeroFontSize) * this.mobileHeroFontSizeRatio}px`;
              this.changeDetector.markForCheck();
            });
        }
        this.subtitle = this.heroSection?.subtitle;
        this.textColor = this.heroSection?.textColor;
        this.medias = this.isMobile ?
          (this.heroSection?.mobileMedias?.length > 0 ? this.heroSection?.mobileMedias : this.heroSection?.medias)
          : this.heroSection?.medias;
        this.shadowOpacity = (1.0-this.heroSection?.shadowTransparency) || this.shadowOpacity;
        this.button = this.heroSection?.button;
        this.buttonPosition = this.heroSection?.buttonPosition;
        this.buttonLocationX = this.heroSection?.buttonLocationX;
        this.buttonLocationY = this.heroSection?.buttonLocationY;
        this.buttonPositionMobile = this.heroSection?.buttonPositionMobile;
        this.buttonLocationXMobile = this.heroSection?.buttonLocationXMobile;
        this.buttonLocationYMobile = this.heroSection?.buttonLocationYMobile;
        this.changeDetector.markForCheck();
    }

    ngAfterViewInit() {
        // Delay the execution of getSwiperSlideSize
        setTimeout(() => {
          this.getSwiperSlideSize();
          // Trigger change detection to update the view
          this.cdr.detectChanges();
        }, DELAY_TIME);
      }

    @HostListener('window:resize', [])
    onResize() {
      this.checkViewport();
      this.getSwiperSlideSize();
    }

    private checkViewport() {
      if(isPlatformBrowser(this.platformId)){
        const mobileIndicator = document.getElementById('mobile-indicator');
        if(mobileIndicator && window) {
            this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
        }
      }
    }

    buttonClick(event: Event) {
        this.logger.debug('Button clicked', this.button);
        if(this.button && this.button.actionType) {
            if(this.button.actionType === 'navigate-to') {
                if(this.button.navigateType === 'navigate-to-product') {
                    this.router.navigate([`${this.baseUrl}/dp`, this.button.targetProduct?.slug]);
                } else if(this.button.navigateType === 'navigate-to-collection') {
                    this.router.navigate([`${this.baseUrl}/gp`, this.button.targetCollection?.slug]);
                } else if(this.button.navigateType === 'navigate-to-page') {
                    this.router.navigate([this.button.targetPage]);
                }
            } else if(this.button.actionType === 'add-variant-to-cart') {
                if(this.button.selectedVariantId) {
                    this.addToCartAndOpenDrawer(this.button.selectedVariantId);
                }
            }
        }
    }

    addToCartAndOpenDrawer(variantId: string) {
        this.inFlight = true;
        this.changeDetector.markForCheck();

        this.subscription.add(
          this.cartService.addToCartAndOpenDrawer(variantId).subscribe({
            next: () => {
              this.inFlight = false;
              this.changeDetector.markForCheck();
            },
            error: err => {
              this.inFlight = false;
              this.logger.error('Error in addToCartAndOpenDrawer', err);
              this.changeDetector.markForCheck();
            },
          }),
        );
    }

    getButtonStyles(): { [key: string]: string } {
      const isCustomPosition = (this.isMobile ? this.buttonPositionMobile : this.buttonPosition) === 'custom';
      if (!isCustomPosition) {
        return {};
      }
      const locationX = (this.isMobile ? this.buttonLocationXMobile : this.buttonLocationX) || 0.5;
      const locationY = (this.isMobile ? this.buttonLocationYMobile : this.buttonLocationY) || 0.5;
      return {
        position: 'absolute',
        left: `${locationX * 100}%`,
        top: `${locationY * 100}%`,
        transform: 'translate(-50%, -50%)',
        'pointer-events': 'auto',
        'z-index': '5',
      };
    }

    getVimeoId(source: string): number {
        const match = source.match(/vimeo\.com\/(\d+)/);     
        return match ? parseInt(match[1], 10) : 0;
    }

    // use native elements to calculate the swiper-slide width and height
    getSwiperSlideSize() {
        const rect = this.elementRef.nativeElement.parentElement.getBoundingClientRect();
        this.swiperSlideWidth = rect.width;
        this.swiperSlideHeight = rect.height || this.swiperSlideHeight;      
    }
}
