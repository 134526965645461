import { Component, OnInit, Inject } from '@angular/core';
import { Dialog, MODAL_OPTIONS, ModalOptions } from '../../../core/providers/modal/modal-types';

@Component({
    selector: 'vsf-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements Dialog, OnInit {
  resolveWith: (result?: boolean) => void;
  title = 'Confirmation';
  message = 'Are you sure?';
  displayCancel = true;

  constructor(@Inject(MODAL_OPTIONS) private options: ModalOptions<ConfirmationModalComponent>) {}

  ngOnInit() {
    if (this.options.locals) {
      this.title = this.options.locals.title || this.title;
      this.message = this.options.locals.message || this.message;
    }
  }

  confirm() {
    this.resolveWith(true);
  }

  cancel() {
    this.resolveWith(false);
  }
}