<!-- <div id="mobile-indicator" class="is-mobile"></div> -->
<div class="max-w-6xl mx-auto md:px-4">
    <div class="md:grid md:grid-cols-2 md:gap-x-8 md:items-start md:mt-12">
        <div class="md:top-1 md:sticky">
            <vsf-asset-swiper-gallery-v2
            [assets]="selectedVariant?.assets"
            [selectedAssetId]="selectedVariant?.featuredAsset?.id"></vsf-asset-swiper-gallery-v2>
        </div>
        <div class="px-0 mt-8 px-4 md:mt-0">
            <h1>
                <div class="product-name font-bold mb-4">
                    {{product?.name}}
                </div>
                <div class="product-subtitle mb-2">
                    {{product?.customFields.ProductSubtitle}}
                </div>
            </h1>
            <div *ngIf="selectedVariant?.sku" class="product-sku text-gray-400 mb-4">
              <h2>SKU: {{getSku()}}</h2>
            </div>
            <div *ngIf="(isMobile===false)" class="flex items-center mb-2">
                <p class="font-[#2E3742] mr-4">
                    <span class="currency-tag">{{ (selectedVariant?.price | separatedFormatPrice)?.currency }}</span>
                    <span class="product-price">{{ (selectedVariant?.price | separatedFormatPrice)?.price }}</span>
                </p>
                <ng-container *ngIf="selectedVariantMSRP && discountPercentage">
                    <div class="flex flex-row items-start">
                        <p class="mrsp-price text-gray-300 line-through">
                            {{ (selectedVariantMSRP | separatedFormatPrice)?.price }}
                        </p>
                        <span class="save-label whitespace-nowrap ml-2">
                            -{{ discountPercentage | number:'1.0-0' }}%
                        </span>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="product?.customFields?.reviewCount > 0">
                <vsf-product-review-indicator
                    class="mb-6 cursor-pointer"
                    [inputScore]="product?.customFields?.reviewRating"
                    [inputReviewNumber]="product?.customFields?.reviewCount"
                    (click)="scrollToReviews()"
                >
                </vsf-product-review-indicator>
            </div>
            <div *ngIf="product?.customFields?.salesNumber && product?.customFields?.salesStatement">
                <span>{{product?.customFields?.salesNumber}}{{product?.customFields?.salesStatement}}</span>
            </div>
            <div class="mt-4" *ngIf="product?.optionGroups.length > 0">
              <div *ngIf="sizeOptions.length > 0" class="flex flex-row items-center">
                  <label
                      for="size:"
                      class="size-option-title block mr-2"
                  >
                      {{sizeOptionsName}}:
                  </label>
                  <div *ngIf="sizeOptions.length === 1" class="flex items-center">
                    <span>{{ selectedSize }}</span>
                  </div>
                  <div *ngIf="sizeOptions.length > 1">
                      <select [(ngModel)]="selectedSize" (ngModelChange)="onSizeChange($event)"
                      class="size-options mt-1 block h-auto pl-3 pr-10 py-2 text-16 border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 rounded-md">
                          <option *ngFor="let size of sizeOptions" [value]="size.code">{{ size.name }}</option>
                      </select>
                  </div>
              </div>
              <div *ngIf="colorOptions.length > 0" class="mt-4">
                  <div class="flex flex-row items-center mb-2">
                    <label class="color-option-title block mr-2">
                        {{colorOptionsName}}:
                    </label>
                    <div class="flex items-center">
                        <span>{{ selectedColorName }}</span>
                    </div>
                  </div>
                  <div class="flex flex-wrap grid grid-cols-6 gap-2 w-[350px]">
                    <div *ngFor="let color of colorOptions" (click)="onColorChange(color)"
                         [class.selected]="selectedColor === color.code" class="color-options cursor-pointer flex justify-center items-center">
                        <ng-container *ngIf="getVariantImage(color.code); else emptyIcon">
                            <img [src]="getVariantImage(color.code) | assetPreview:'medium'"
                                 alt="color variant"
                                 style="pointer-events: none;"
                                 class="rounded-lg object-cover aspect-[1/1]"
                            >
                        </ng-container>
                        <ng-template #emptyIcon>
                            <fa-icon icon="image" class="flex justify-center items-center empty-icon"></fa-icon>
                        </ng-template>
                    </div>
                  </div>
              </div>
            </div>
            <div *ngIf="(isMobile===false)">
                <vsf-product-shop-now-banner
                    [product]="product"
                    [selectedVariant]="selectedVariant"
                    [inStock]="inStock"
                    [buttonBackgroundColor]="colorConfig?.secondaryColor">
                </vsf-product-shop-now-banner>
            </div>
            <div class="flex text-20 align-baseline mt-4 mb-4">
              <p class="text-[#29319C]">Your payment:</p>
              <fa-icon [icon]="['fab', 'cc-visa']" class="mr-1 ml-1"></fa-icon>
              <fa-icon [icon]="['fab', 'cc-mastercard']" class="mr-1 ml-1"></fa-icon>
              <fa-icon [icon]="['fab', 'cc-amex']" class="mr-1 ml-1"></fa-icon>
              <fa-icon [icon]="['fab', 'cc-discover']" class="mr-1 ml-1"></fa-icon>
              <fa-icon [icon]="['fab', 'cc-diners-club']" class="mr-1 ml-1"></fa-icon>
              <fa-icon [icon]="['fab', 'cc-jcb']" class="mr-1 ml-1"></fa-icon>
              <fa-icon [icon]="['fab', 'cc-apple-pay']" class="mr-1 ml-1"></fa-icon>
              <fa-icon [icon]="['fab', 'google-pay']" class="mr-1 ml-1"></fa-icon>
              <fa-icon [icon]="['fab', 'alipay']" class="mr-1 ml-1"></fa-icon>
            </div>
            <div class="mt-4 mb-4">
                <div class="flex items-center">
                    <h2 class="product-description">Description</h2>
                </div>
                <div>
                    <p class="product-html text-base text-gray-700 mt-1 mb-1"
                        [innerHTML]="product?.description | safeHtml"></p>
                </div>
            </div>
            <div>
              <div *ngIf="productFeatures.length > 0">
                <div *ngFor="let feature of productFeatures" class="mt-4 bg-gray-100 rounded-lg">
                    <div class="flex items-center justify-between cursor-pointer px-4 py-2" (click)="toggleFold(feature.title)">
                        <h2 class="text-gray-600 font-bold">{{ feature.title }}</h2>
                        <fa-icon [icon]="isFolded(feature.title) ? 'plus' : 'minus'"></fa-icon>
                    </div>
                    <div class="text-gray-500 space-y-1 px-4" [@expandCollapse]="isFolded(feature.title) ? 'collapsed' : 'expanded'">
                        <span class="product-html" [innerHTML]="feature.text | safeHtml"></span>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

<ng-template #addedToCartTemplate let-variant="variant" let-quantity="quantity" let-close="closeFn">
    <div class="flex">
        <div class="mr-8">
            <img
                class="rounded"
                [src]="variant.featuredAsset?.preview | assetPreview:'medium'"
                style="pointer-events: none;"
                alt="product thumbnail"
            />
        </div>
        <div class="text-sm">{{ quantity }} x {{ variant.name }}</div>
    </div>
    <div class="flex justify-end">
        <button type="button"
                (click)="viewCartFromNotification(close)"
                class="inline-flex items-center rounded border border-transparent bg-primary-100 px-2.5 py-1.5 text-xs font-medium text-primary-700 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            View cart
        </button>
    </div>
</ng-template>
