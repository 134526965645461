<div class="collections-carousel">
  <div *ngIf="groupedCollections.length === 1" class="group-name">
    <vsf-two-collection-card-v2
      [collectionA]="groupedCollections[0][0]"
      [collectionB]="groupedCollections[0][1]"
      [colorConfig]="colorConfig"
      class="card">
    </vsf-two-collection-card-v2>
  </div>
  <div *ngIf="groupedCollections.length > 1" class="group-name">
    <swiper-container
      appSwiper
      #swiper
      [slidesPerView]="1"
      [slidesPerGroup]="1"
      [direction]="swipeDirection"
      spaceBetween="10"
      [pagination]="{ clickable: true }"
      [centeredSlides]="true"
      auto-height="true"
      rewind="true"
      class="flex object-cover"
    >
      <swiper-slide *ngFor="let group of groupedCollections; let i = index">
        <div class="flex flex-row justify-center">
          <div class="flex flex-wrap">
            <div class="slide-item">
              <vsf-two-collection-card-v2
                [collectionA]="group[0]"
                [collectionB]="group[1]"
                [colorConfig]="colorConfig"
                class="card">
              </vsf-two-collection-card-v2>
            </div>
          </div>  
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</div>