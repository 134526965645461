import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Dialog, MODAL_OPTIONS, ModalOptions } from '../../../core/providers/modal/modal-types';
import { Review } from '../../../common/interfaces';

@Component({
  selector: 'vsf-product-review-modal',
  templateUrl: './product-review-modal.component.html',
  styleUrls: ['./product-review-modal.component.scss']
})
export class ProductReviewModalComponent implements Dialog, OnInit, AfterViewInit {
  resolveWith: () => void;
  title = 'Review Details';
  review: Review;

  private clickListener: () => void;

  authorName: string;
  reviewRating: number;
  reviewSummary: string;
  reviewContent: string;

  constructor(@Inject(MODAL_OPTIONS) private options: ModalOptions<ProductReviewModalComponent>) {
  }

  ngOnInit() {
    this.authorName = this.review?.name;
    this.reviewRating = this.review?.rating;
    this.reviewSummary = this.review?.summary;
    this.reviewContent = this.review?.content || '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
    this.clickListener = this.cancel.bind(this);
      document.addEventListener('click', this.clickListener);
    }, 1000);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.clickListener);
  }

  cancel() {
    this.resolveWith();
  }
}