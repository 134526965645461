import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { NGXLogger } from 'ngx-logger';
import { isPlatformBrowser } from '@angular/common';
import { IAnalyticsService } from './analytics.interface';
import { Order, Product, ProductVariant } from '../../../common/generated-types';

declare function odgainit(gaTrackingId: string, onSucess: any, onError: any): void;
declare function viewItem(product_event: any): void;
declare function selectItem(product_event: any): void;
declare function addToCart(variant_event: any): void;
declare function beginCheckout(order_event: any, callback: any): void;
declare function addShippingInfo(order_event: any, callback: any): void;
declare function purchase(order_event: any, callback: any): void;
declare function setEmail(emailHash: string): void;
declare function login(callback: any): void;

enum GAInitState {
    Loading = 'Loading',
    Initialized = 'Initialized',
    Failed = 'Failed',
    NoBrower = 'NoBrower',
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements IAnalyticsService {
    private email: string = '';
    private gaInitState: GAInitState = GAInitState.Loading;
    private gaIsBlocked = true;
    private eventsQueue: any[] = [];
    private static readonly MAX_QUEUE_SIZE = 100;

    constructor(private logger: NGXLogger,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        if (isPlatformBrowser(this.platformId)) {
            odgainit(environment.gaTrackingId, () => {
                this.logger.debug('[init]', environment.gaTrackingId);
                this.gaInitState = GAInitState.Initialized;
                if (login) {
                    try {
                        login(()=>{
                            this.logger.debug('[AnalyticsService] login event sent');
                            this.gaIsBlocked = false;
                            this.processQueue();
                        });
                    } catch (error) {
                        this.logger.error('[AnalyticsService] login event error', error);
                        this.gaInitState = GAInitState.Failed;
                        this.dumpQueue();
                    }
                }
            }, (error: any) => {
                this.logger.error('[init] Error', error);
                this.gaInitState = GAInitState.Failed;
                this.dumpQueue();
            });
        } else {
            this.gaInitState = GAInitState.NoBrower;
        }
    }

    private processQueue(): void {
        while (this.eventsQueue.length > 0) {
            const event = this.eventsQueue.shift();
            event();
        }
    }

    private dumpQueue(): void {
        this.eventsQueue = [];
    }

    private queueEvent(event: any): void {
        this.logger.debug('[queueEvent]', event);
        this.eventsQueue.push(event);
        if(this.eventsQueue.length > AnalyticsService.MAX_QUEUE_SIZE) {
            this.logger.warn('[queueEvent] Queue size exceeded, dumping events');
            this.eventsQueue.shift();
        }
    }

    private sendGaEvent(methodName:string, funcEvent:any, param:any, callback: any = undefined) {
        const event = (callback: any) => {
            if (funcEvent && (!callback || (callback && !this.gaIsBlocked))) {
                try {
                    funcEvent(param,callback);
                    this.logger.debug(`[${methodName}]`, param);
                    return; // to avoid calling callback again
                } catch (error) {
                    this.logger.error(`[${methodName}] Error: `, error);
                }
            }
            // call callback if function failed or funcEvent is undefined
            if (callback) {
                callback();
            }
        };

        if (this.gaInitState === GAInitState.Initialized) {
            event(callback);
            return; // to avoid calling callback again
        } else if (this.gaInitState === GAInitState.Loading) {
            this.queueEvent(event);
        }

        // call callback for other cases
        if (callback) {
            callback();
        }
    }

    public initialize(): void {
    }

    public setEmail(email: string): void {
        if (email !== this.email) {
            if (this.gaInitState === GAInitState.NoBrower) {
                return;
            }
            this.email = email;
            const emailHash = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
            this.sendGaEvent('setEmail', setEmail , emailHash);
        }
    }

    public selectItem(product: Product): void {
        if (this.gaInitState === GAInitState.NoBrower) {
            return;
        }
        const variant = product.variants[0];
        const product_event = {
            item_list_id: product?.slug,
            item_list_name: product?.name,
            items: [
                {
                    item_id: variant?.sku,
                    item_name: variant?.name,
                    index: 0,
                    item_list_id: product?.slug,
                    item_list_name: product?.name,
                    item_variant: variant?.options?.map((option: any) => option.name).join(', '),
                    price: variant.price / 100.0,
                    quantity: 1,
                }
            ]
        };
        this.sendGaEvent('selectItem', selectItem , product_event);
    }

    public viewItem(product: Product): void {
        if (this.gaInitState === GAInitState.NoBrower) {
            return;
        }
        const variant = product.variants[0];
        const product_event = {
            currency: 'USD',
            value: variant.price / 100.0,
            items: [
                {
                    item_id: variant?.sku,
                    item_name: variant?.name,
                    index: 0,
                    item_list_id: product?.slug,
                    item_list_name: product?.name,
                    item_variant: variant?.options?.map((option: any) => option.name).join(', '),
                    price: variant.price / 100.0,
                    quantity: 1,
                }
            ]
        };
        this.sendGaEvent('viewItem', viewItem , product_event);
    }

    public addToCart(product: Product, variant: ProductVariant, quantity: number): void {
        if (this.gaInitState === GAInitState.NoBrower) {
            return;
        }
        const variant_event = {
            currency: 'USD',
            value: variant.price * quantity / 100.0,
            items: [
                {
                    item_id: variant?.sku,
                    item_name: variant?.name,
                    index: 0,
                    item_list_id: product?.slug,
                    item_list_name: product?.name,
                    item_variant: variant?.options?.map((option: any) => option.name).join(', '),
                    price: variant.price / 100.0,
                    quantity: quantity,
                }
            ],
        };
        this.sendGaEvent('addToCart', addToCart , variant_event);
    }

    public beginCheckout(order: Order, callback: any): void {
        if (this.gaInitState === GAInitState.NoBrower) {
            if(callback) {
                callback();
            }
            return;
        }
        const order_event = {
            currency: 'USD',
            value: order?.totalWithTax / 100.0,
            coupon: order?.couponCodes.join(', '),
            items: order?.lines?.map((line: any, index: number) => {
                return {
                    item_id: line.productVariant?.sku,
                    item_name: line.productVariant?.name,
                    index: index,
                    item_list_id: line.productVariant?.product?.slug,
                    item_list_name: line.productVariant?.product?.name,
                    item_variant: line.productVariant?.options?.map((option: any) => option.name).join(', '),
                    price: line.unitPriceWithTax / 100.0,
                    quantity: line.quantity,
                    discount: (line.discounts.map((discount: any) => discount.amountWithTax).reduce((a: number, b: number) => a + b, 0)) / 100.0,
                    coupon: line.discounts.map((discount: any) => discount.description).join(', '),
                };
            }),
        };
        this.sendGaEvent('beginCheckout', beginCheckout , order_event , callback);
    }

    public addShippingInfo(order: Order, callback: any): void {
        if (this.gaInitState === GAInitState.NoBrower) {
            if(callback) {
                callback();
            }
            return;
        }
        const order_event = {
            currency: 'USD',
            value: order?.totalWithTax / 100.0,
            tax: (order?.subTotalWithTax - order?.subTotal) / 100.0,
            shipping_tier: order?.shippingLines?.[0]?.shippingMethod?.name,
            coupon: order?.couponCodes.join(', '),
            items: order?.lines?.map((line: any, index: number) => {
                return {
                    item_id: line.productVariant?.sku,
                    item_name: line.productVariant?.name,
                    index: index,
                    item_list_id: line.productVariant?.product?.slug,
                    item_list_name: line.productVariant?.product?.name,
                    item_variant: line.productVariant?.options?.map((option: any) => option.name).join(', '),
                    price: line.unitPriceWithTax / 100.0,
                    quantity: line.quantity,
                    discount: (line.discounts.map((discount: any) => discount.amountWithTax).reduce((a: number, b: number) => a + b, 0)) / 100.0,
                    coupon: line.discounts.map((discount: any) => discount.description).join(', '),
                };
            }),
        };
        this.sendGaEvent('addShippingInfo', addShippingInfo , order_event , callback);
    }

    public purchase(order: Order, callback: any): void {
        if (this.gaInitState === GAInitState.NoBrower) {
            if(callback) {
                callback();
            }
            return;
        }
        const order_event = {
            transaction_id: order?.code,
            value: order?.totalWithTax / 100.0,
            tax: (order?.subTotalWithTax - order?.subTotal) / 100.0,
            shipping: order?.shippingWithTax / 100.0,
            coupon: order?.couponCodes.join(', '),
            currency: 'USD',
            items: order?.lines?.map((line: any, index: number) => {
                return {
                    item_id: line.productVariant?.sku,
                    item_name: line.productVariant?.name,
                    index: index,
                    item_list_id: line.productVariant?.product?.slug,
                    item_list_name: line.productVariant?.product?.name,
                    item_variant: line.productVariant?.options?.map((option: any) => option.name).join(', '),
                    price: line.unitPriceWithTax / 100.0,
                    quantity: line.quantity,
                    discount: (line.discounts.map((discount: any) => discount.amountWithTax).reduce((a: number, b: number) => a + b, 0)) / 100.0,
                    coupon: line.discounts.map((discount: any) => discount.description).join(', '),
                };
            }),
        };
        this.sendGaEvent('purchase', purchase , order_event , callback);
    }
}
