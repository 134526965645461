<div *ngIf="isMobile!==null">
    <ng-container *ngIf="type === 'key-point-list' || type === 'hero-section'; else regularBanner">
        <div *ngIf="type === 'key-point-list'">
            <vsf-key-selling-points-banner
                [keySellingPoints]="keyPoints"
                [layout]="keyPointsLayoutType"
                [backgroundColor]="backgroundColor"
                [textColor]="textColor"
                [isMobile]="isMobile"
                >
            </vsf-key-selling-points-banner>
        </div>
        <div *ngIf="type === 'hero-section'">
            <vsf-hero-section
                [heroSection]="heroSection"
                >
            </vsf-hero-section>
        </div>
    </ng-container>
    <ng-template #regularBanner>
        <div class="product-marketing-banner" [ngStyle]="{'background-color': backgroundColor }">
            <div *ngIf="type === 'left-media-right-text'" class="banner-container left-media-right-text w-full">
                <div *ngIf="!isMobile" class="flex flex-row relative w-full">
                    <div class="banner-background overflow-hidden" *ngIf="backgroundMedia">
                        <img *ngIf="backgroundMedia.type === 'IMAGE'" [src]="backgroundMedia | assetPreview:'full'" class="w-full h-full object-cover" alt="Marketing Banner Image"/>
                        <video *ngIf="backgroundMedia.type === 'VIDEO' && backgroundMedia.mimeType === 'video/mp4'" [src]="backgroundMedia.source" class="banner-background-video" autoplay muted loop playsinline></video>
                        <vsf-vimeo-player *ngIf="backgroundMedia.type === 'VIDEO' && backgroundMedia.mimeType === 'video/vimeo'" [source]="backgroundMedia.customFields?.videoUrl || backgroundMedia.source" [width]="backgroundMediaWidth"></vsf-vimeo-player>
                    </div>
                    <div class="media-container mr-10" [style.height.px]="400"
                        [ngStyle]="{'width': calculateWidths().media }">
                        <div class="media-item">
                            <swiper-container appSwiper #leftMediaDesktopSwiper pagination.type=progressbar pagination-clickable=true
                                centered-slides=true autoplay-delay=5000 autoplay-disable-on-interaction=false lazy="true"
                                rewind=true class="flex-grow object-cover custom-pagination" autoplay="true">
                                <swiper-slide *ngFor="let media of bannerMedias" class="w-full h-full" [style.height.px]="400">
                                <div  class="flex justify-center md:justify-start items-center w-full h-full">
                                    <div *ngIf="media.type === 'IMAGE'">
                                        <img [src]="media | assetPreview: 'large'"
                                            loading="eager"
                                            alt="Marketing Banner Image"
                                            style="pointer-events: none; height: 400px;"
                                            [width]="media.width"
                                            [height]="media.height"
                                            class="swiper-lazy w-full h-full object-contain"/>
                                        <div class="swiper-lazy-preloader"></div>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'">
                                        <video [muted]=true class="object-cover w-full h-full object-contain"
                                            alt="Marketing Banner Video" style="pointer-events: none; height: 400px;" autoplay loop playsinline>
                                            <source [src]="media.source" type="video/mp4">
                                        </video>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo'" class="w-full h-full">
                                        <vsf-vimeo-player [source]="media.customFields?.videoUrl || media.source" [height]="'400'" [width]="mediasWidth"></vsf-vimeo-player>
                                    </div>
                                </div>
                                </swiper-slide>
                            </swiper-container>
                        </div>
                    </div>
                    <div class="text-container flex flex-col justify-center"
                        [ngStyle]="{ 'width': calculateWidths().text, 'text-align': textAlignment }">
                        <!-- Title -->
                        <div class="order-1">
                            <p class="banner-title font-bold mb-8">{{ title }}</p>
                        </div>
                        <!-- Text Content -->
                        <div class="banner-text mb-8 order-2"
                            [ngStyle]="{ 'margin-left': textIndentation, 'width': textWidth }"
                            [innerHTML]="text | safeHtml"></div>
                        <!-- Button Container -->
                        <div *ngIf="button?.enabled && buttonPosition === 'below-text'"
                            class="button-container flex-row justify-end text-right order-3">
                            <button
                            (click)="buttonClick()"
                            class="button"
                            [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                            [disabled]="inFlight"
                            >
                            {{ button.text }}
                            </button>
                        </div>
                    </div>
                    <div *ngIf="button?.enabled && buttonPosition === 'custom'" [ngStyle]="getButtonStyles()">
                        <button
                            (click)="buttonClick($event)"
                            class="button"
                            [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                            [disabled]="inFlight"
                        >
                            {{ button.text }}
                        </button>
                    </div>
                </div>
                <div *ngIf="isMobile" class="w-full">
                    <div class="flex flex-col mx-2 my-2">
                        <div class="flex flex-row overflow-hidden relative">
                            <div class="banner-background" *ngIf="backgroundMobileMedia">
                                <img *ngIf="backgroundMobileMedia.type === 'IMAGE'" [src]="backgroundMobileMedia | assetPreview:'medium'" class="w-full h-full object-cover" alt="Marketing Banner Image"/>
                                <video *ngIf="backgroundMobileMedia.type === 'VIDEO' && backgroundMobileMedia.mimeType === 'video/mp4'" [src]="backgroundMobileMedia.source" class="banner-background-video" autoplay muted loop playsinline></video>
                                <vsf-vimeo-player *ngIf="backgroundMobileMedia.type === 'VIDEO' && backgroundMobileMedia.mimeType === 'video/vimeo'" [source]="backgroundMobileMedia.customFields?.videoUrl || backgroundMobileMedia.source" [width]="backgroundMediaWidth"></vsf-vimeo-player>
                            </div>
                            <div class="media-container" [ngStyle]="{'width': button?.enabled ? '55%' : '100%' }">
                                <div class="media-item">
                                    <swiper-container appSwiper #leftMediaMobileSwiper pagination.type=progressbar pagination-clickable=true
                                        centered-slides=true autoplay-delay=5000 autoplay-disable-on-interaction=false lazy="true"
                                        rewind=true class="flex-grow object-cover custom-pagination">
                                        <swiper-slide *ngFor="let media of bannerMedias">
                                            <div  class="flex justify-center md:justify-end items-center w-full h-full">
                                                <div *ngIf="media.type === 'IMAGE'" >
                                                    <img [src]="media | assetPreview: 'small'"
                                                        loading="eager"
                                                        alt="Marketing Banner Image"
                                                        style="pointer-events: none; height: auto;"
                                                        [width]="media.width"
                                                        [height]="media.height"
                                                        class="swiper-lazy w-full h-auto object-contain"/>
                                                    <div class="swiper-lazy-preloader"></div>
                                                </div>
                                                <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'">
                                                    <video [muted]=true class="object-cover w-full h-full object-contain"
                                                        alt="Marketing Banner Video" style="pointer-events: none; height: auto;" autoplay loop playsinline>
                                                        <source [src]="media.source" type="video/mp4">
                                                    </video>
                                                </div>
                                                <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo'" class="w-full h-full">
                                                    <vsf-vimeo-player [source]="media.customFields?.videoUrl || media.source" [width]="mediasWidth"></vsf-vimeo-player>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    </swiper-container>
                                </div>
                            </div>
                            <div *ngIf="button?.enabled && buttonPositionMobile === 'below-text'"
                                class="flex flex-row justify-end" style="width: 45%;">
                                <div class="text-container grid grid-rows-2">
                                    <div class="mt-6 text-right my-auto" style="width: 90%;">
                                        <p class="banner-title font-bold">{{ title }}</p>
                                    </div>
                                    <div class="w-auto text-right my-auto">
                                        <button (click)="buttonClick()" class="button" [ngStyle]="{'background-color': button.backgroundColor, 'color': button.textColor, 'white-space': 'nowrap'}">{{ button.text }}</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="button?.enabled && buttonPositionMobile === 'custom'" [ngStyle]="getButtonStyles()">
                                <button
                                    (click)="buttonClick($event)"
                                    class="button"
                                    [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                                    [disabled]="inFlight"
                                >
                                    {{ button.text }}
                                </button>
                            </div>
                        </div>
                        <div class="text-container mt-2 mb-4">
                            <div *ngIf="!(button?.enabled)">
                                <p class="banner-title font-bold mb-4">{{ title }}</p>
                            </div>
                            <div class="banner-text mb-4" [innerHTML]="text | safeHtml"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="type === 'left-text-right-media'" class="banner-container left-text-right-media w-full">
                <div *ngIf="!isMobile" class="flex flex-row relative justify-between w-full">
                    <div class="banner-background overflow-hidden" *ngIf="backgroundMedia">
                        <img *ngIf="backgroundMedia.type === 'IMAGE'" [src]="backgroundMedia | assetPreview:'full'" class="w-full h-full object-cover" alt="Marketing Banner Image"/>
                        <video *ngIf="backgroundMedia.type === 'VIDEO' && backgroundMedia.mimeType === 'video/mp4'" [src]="backgroundMedia.source" class="banner-background-video" autoplay muted loop playsinline></video>
                        <vsf-vimeo-player *ngIf="backgroundMedia.type === 'VIDEO' && backgroundMedia.mimeType === 'video/vimeo'" [source]="backgroundMedia.customFields?.videoUrl || backgroundMedia.source" [width]="backgroundMediaWidth"></vsf-vimeo-player>
                    </div>
                    <div class="text-container flex flex-col justify-center"
                        [ngStyle]="{ 'width': calculateWidths().text, 'text-align': textAlignment }">
                        <!-- Title -->
                        <div class="order-1">
                            <p class="banner-title font-bold mb-8">{{ title }}</p>
                        </div>
                        <!-- Text Content -->
                        <div class="banner-text mb-8 order-2"
                            [ngStyle]="{ 'margin-left': textIndentation, 'width': textWidth }"
                            [innerHTML]="text | safeHtml"></div>
                        <!-- Button Container -->
                        <div *ngIf="button?.enabled && buttonPosition === 'below-text'"
                            class="button-container flex-row justify-start text-left order-3">
                            <button
                            (click)="buttonClick()"
                            class="button"
                            [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                            [disabled]="inFlight"
                            >
                            {{ button.text }}
                            </button>
                        </div>
                    </div>
                    <div class="media-container ml-10" [style.height.px]="400"
                        [ngStyle]="{'width': calculateWidths().media }">
                        <div class="media-item">
                            <swiper-container appSwiper #rightMediaDesktopSwiper pagination.type=progressbar pagination-clickable=true
                                centered-slides=true autoplay-delay=5000 autoplay-disable-on-interaction=false lazy="true"
                                rewind=true class="flex-grow object-cover custom-pagination">
                                <swiper-slide *ngFor="let media of bannerMedias" [style.height.px]="400">
                                    <div  class="flex justify-center md:justify-end items-center w-full h-full">
                                        <div *ngIf="media.type === 'IMAGE'" >
                                            <img [src]="media | assetPreview: 'large'"
                                                loading="eager"
                                                alt="Marketing Banner Image"
                                                style="pointer-events: none; height: 400px;"
                                                [width]="media.width"
                                                [height]="media.height"
                                                class="swiper-lazy w-full h-auto object-contain"/>
                                            <div class="swiper-lazy-preloader"></div>
                                        </div>
                                        <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'">
                                            <video [muted]=true class="object-cover w-full h-full"
                                                alt="Marketing Banner Video" style="pointer-events: none; height: 400px;" autoplay loop playsinline>
                                                <source [src]="media.source" type="video/mp4">
                                            </video>
                                        </div>
                                        <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo'">
                                            <vsf-vimeo-player [source]="media.customFields?.videoUrl || media.source" [height]="'400'" [width]="mediasWidth"></vsf-vimeo-player>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper-container>
                        </div>
                    </div>
                    <div *ngIf="button?.enabled && buttonPosition === 'custom'" [ngStyle]="getButtonStyles()">
                        <button
                            (click)="buttonClick($event)"
                            class="button"
                            [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                            [disabled]="inFlight"
                        >
                            {{ button.text }}
                        </button>
                    </div>
                </div>
                <div *ngIf="isMobile" class="w-full">
                    <div class="flex flex-col mx-2">
                        <div class="flex flex-row overflow-hidden relative">
                            <div class="banner-background" *ngIf="backgroundMobileMedia">
                                <img *ngIf="backgroundMobileMedia.type === 'IMAGE'" [src]="backgroundMobileMedia | assetPreview:'medium'" class="w-full h-full object-cover" alt="Marketing Banner Image"/>
                                <video *ngIf="backgroundMobileMedia.type === 'VIDEO' && backgroundMobileMedia.mimeType === 'video/mp4'" [src]="backgroundMobileMedia.source" class="banner-background-video" autoplay muted loop playsinline></video>
                                <vsf-vimeo-player *ngIf="backgroundMobileMedia.type === 'VIDEO' && backgroundMobileMedia.mimeType === 'video/vimeo'" [source]="backgroundMobileMedia.customFields?.videoUrl || backgroundMobileMedia.source" [width]="backgroundMobileMediaWidth"></vsf-vimeo-player>
                            </div>
                            <div *ngIf="button?.enabled && buttonPositionMobile === 'below-text'" class="flex flex-row justify-start" style="width: 45%;">
                                <div class="text-container grid grid-rows-2">
                                    <div class="mt-6 text-left  my-auto" style="width: 90%;">
                                        <p class="banner-title font-bold">{{ title }}</p>
                                    </div>
                                    <div class="w-auto text-left  my-auto">
                                        <button (click)="buttonClick()" class="button" [ngStyle]="{'background-color': button.backgroundColor, 'color': button.textColor, 'white-space': 'nowrap'}">{{ button.text }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="media-container" [ngStyle]="{'width': (button?.enabled && buttonPositionMobile === 'below-text') ? '55%' : '100%' }">
                                <div class="media-item">
                                    <swiper-container appSwiper #rightMediaMobileSwiper pagination.type=progressbar pagination-clickable=true
                                        centered-slides=true autoplay-delay=5000 autoplay-disable-on-interaction=false lazy="true"
                                        rewind=true class="flex-grow object-cover custom-pagination">
                                        <swiper-slide *ngFor="let media of bannerMedias">
                                            <div  class="flex justify-center md:justify-end items-center w-full h-full">
                                                <div *ngIf="media.type === 'IMAGE'" >
                                                    <img [src]="media | assetPreview: 'small'"
                                                        loading="eager"
                                                        alt="Marketing Banner Image"
                                                        style="pointer-events: none; height: auto;"
                                                        [width]="media.width"
                                                        [height]="media.height"
                                                        class="swiper-lazy w-full h-auto object-contain"/>
                                                    <div class="swiper-lazy-preloader"></div>
                                                </div>
                                                <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'">
                                                    <video [muted]=true class="object-cover w-full h-full object-contain"
                                                        alt="Marketing Banner Video" style="pointer-events: none; height: autopx;" autoplay loop playsinline>
                                                        <source [src]="media.source" type="video/mp4">
                                                    </video>
                                                </div>
                                                <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo'">
                                                    <vsf-vimeo-player [source]="media.customFields?.videoUrl || media.source" [width]="mediasWidth"></vsf-vimeo-player>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    </swiper-container>
                                </div>
                            </div>
                            <div *ngIf="button?.enabled && buttonPositionMobile === 'custom'" [ngStyle]="getButtonStyles()">
                                <button
                                    (click)="buttonClick($event)"
                                    class="button"
                                    [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                                    [disabled]="inFlight"
                                >
                                    {{ button.text }}
                                </button>
                            </div>
                        </div>
                        <div class="text-container mt-2 mb-4" [ngStyle]="{ 'text-align': textAlignment }">
                            <div *ngIf="!(button?.enabled) || buttonPositionMobile === 'custom'">
                                <p class="banner-title font-bold mb-4">{{ title }}</p>
                            </div>
                            <div class="banner-text mb-4" [innerHTML]="text | safeHtml"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="type === 'top-text-below-media'" class="banner-container top-text-below-media">
                <div class="text-container align-top w-full" [ngStyle]="{'text-align': textAlignment}">
                    <p class="banner-title font-bold mb-4">{{ title }}</p>
                    <div class="banner-text mb-4"
                        [ngStyle]="{ 'margin-left': textIndentation, 'width': textWidth }"
                        [innerHTML]="text | safeHtml"></div>
                </div>
                <div class="media-container">
                    <div class="media-item">
                        <ng-container *ngIf="bannerMedias.length >= 3; else singleSlide">
                            <swiper-container appSwiper #belowMediaMultipleSwiper pagination.type=progressbar pagination-clickable=true
                                                autoplay=true autoplay-delay=5000 autoplay-disable-on-interaction=false
                                                [slidesPerView]="slidesPerView" space-between=30 rewind=true lazy="true"
                                                class="flex-grow object-cover custom-pagination">
                                <swiper-slide *ngFor="let media of bannerMedias" class="swiper-slide">
                                <div class="flex justify-center items-center w-full h-full">
                                    <div *ngIf="media.type === 'IMAGE'">
                                        <img [src]="media | assetPreview: isMobile ? 'medium' : 'large'"
                                            loading="eager"
                                            alt="Marketing Banner Image"
                                            style="pointer-events: none;"
                                            [width]="media.width"
                                            [height]="media.height"
                                            class="swiper-lazy multi-slide-media w-full h-auto object-cover aspect-[1/1]"/>
                                        <div class="swiper-lazy-preloader"></div>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'">
                                        <video [muted]=true class="multi-slide-media object-cover w-full h-full aspect-[1/1]"
                                            alt="Marketing Banner Video" style="pointer-events: none;" autoplay loop playsinline>
                                            <source [src]="media.source" type="video/mp4">
                                        </video>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo'">
                                        <vsf-vimeo-player [source]="media.customFields?.videoUrl || media.source" [width]="mediasWidth"></vsf-vimeo-player>
                                    </div>
                                </div>
                                </swiper-slide>
                            </swiper-container>
                        </ng-container>
                        <ng-template #singleSlide>
                            <swiper-container appSwiper #belowMediaSingleSwiper pagination.type=progressbar pagination-clickable=true
                                                autoplay=false space-between=30 rewind=true lazy="true"
                                                [slidesPerView]="slidesPerView"
                                                class="flex-grow object-cover custom-pagination">
                                <swiper-slide *ngFor="let media of bannerMedias" class="swiper-slide">
                                <div class="flex justify-center items-center w-full">
                                    <div *ngIf="media.type === 'IMAGE'">
                                        <img style="pointer-events: none;"
                                            loading="eager"
                                            alt="Marketing Banner Image"
                                            [src]="media | assetPreview: isMobile ? 'medium' : 'large'"
                                            class="swiper-lazy single-slide-media w-full h-auto object-contain aspect-[16/9]"/>
                                        <div class="swiper-lazy-preloader"></div>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'">
                                        <video [muted]=true class="single-slide-media w-full h-full object-contain aspect-[16/9]"
                                            alt="Marketing Banner Video" style="pointer-events: none;" autoplay loop playsinline>
                                            <source [src]="media.source" type="video/mp4">
                                        </video>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo'">
                                        <vsf-vimeo-player [source]="media.customFields?.videoUrl || media.source" [width]="mediasWidth"></vsf-vimeo-player>
                                    </div>
                                </div>
                                </swiper-slide>
                            </swiper-container>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="button?.enabled && (isMobile ? buttonPositionMobile : buttonPosition) === 'custom'" [ngStyle]="getButtonStyles()">
                    <button
                      (click)="buttonClick($event)"
                      class="button"
                      [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                      [disabled]="inFlight"
                    >
                      {{ button.text }}
                    </button>
                </div>
            </div>

            <div *ngIf="type === 'media-only'" class="banner-container media-only">
                <div class="media-container">
                    <div class="media-item">
                        <swiper-container appSwiper #mediaOnlySwiper pagination.type=progressbar pagination-clickable=true
                            centered-slides=true autoplay-delay=5000 autoplay-disable-on-interaction=false lazy="true"
                            auto-height=true
                            rewind=true class="flex-grow object-cover custom-pagination">
                            <swiper-slide *ngFor="let media of bannerMedias">
                                <div  class="flex justify-center items-center">
                                    <div *ngIf="media.type === 'IMAGE'">
                                        <img [src]="media | assetPreview: 'full'"
                                            loading="eager"
                                            alt="Marketing Banner Image"
                                            style="pointer-events: none;"
                                            [width]="media.width"
                                            [height]="media.height"
                                            class="swiper-lazy w-full h-auto object-cover"/>
                                        <div class="swiper-lazy-preloader"></div>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'">
                                        <video [muted]=true class="w-full h-full object-cover"
                                            alt="Marketing Banner Video" style="pointer-events: none;" autoplay loop playsinline>
                                            <source [src]="media.source" type="video/mp4">
                                        </video>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo'" style="min-height: 400px;">
                                        <vsf-vimeo-player [source]="media.customFields?.videoUrl || media.source" [width]="mediasWidth" [height]="mediasHeight"></vsf-vimeo-player>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </div>
                <div *ngIf="button?.enabled && (isMobile ? buttonPositionMobile : buttonPosition) === 'custom'" [ngStyle]="getButtonStyles()">
                    <button
                      (click)="buttonClick($event)"
                      class="button"
                      [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                      [disabled]="inFlight"
                    >
                      {{ button.text }}
                    </button>
                </div>
            </div>

            <div *ngIf="type === 'titled-media'" class="banner-container media-only flex flex-col">
                <div class="media-title" [ngStyle]="{'width': '100%', 'text-align': textAlignment}">
                    <p>{{ title }}</p>
                    <div class="title-underline"></div>
                </div>
                <div class="media-container">
                    <div class="media-item">
                        <swiper-container appSwiper #titledMediaSwiper pagination.type=progressbar pagination-clickable=true
                            auto-height=true
                            centered-slides=true autoplay-delay=5000 autoplay-disable-on-interaction=false lazy="true"
                            rewind=true class="flex-grow object-cover custom-pagination">
                            <swiper-slide *ngFor="let media of bannerMedias">
                                <div  class="flex justify-center items-center">
                                    <div *ngIf="media.type === 'IMAGE'">
                                        <img [src]="media | assetPreview: 'full'"
                                            loading="eager"
                                            alt="Marketing Banner Image"
                                            style="pointer-events: none;"
                                            [width]="media.width"
                                            [height]="media.height"
                                            class="swiper-lazy w-full h-auto object-cover"/>
                                        <div class="swiper-lazy-preloader"></div>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/mp4'">
                                        <video [muted]=true class="w-full h-full object-cover"
                                            alt="Marketing Banner Video" style="pointer-events: none;" autoplay loop playsinline>
                                            <source [src]="media.source" type="video/mp4">
                                        </video>
                                    </div>
                                    <div *ngIf="media.type === 'VIDEO' && media.mimeType === 'video/vimeo'">
                                        <vsf-vimeo-player [source]="media.customFields?.videoUrl || media.source" [width]="mediasWidth" [height]="mediasHeight"></vsf-vimeo-player>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </div>
                <div *ngIf="button?.enabled && (isMobile ? buttonPositionMobile : buttonPosition) === 'custom'" [ngStyle]="getButtonStyles()">
                    <button
                      (click)="buttonClick($event)"
                      class="button"
                      [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                      [disabled]="inFlight"
                    >
                      {{ button.text }}
                    </button>
                </div>
            </div>

            <div *ngIf="(type === 'right-media-slide-with-background' || type === 'left-media-slide-with-background')&&!isMobile; else slideMediaMobile">
                <div *ngIf="type === 'left-media-slide-with-background'" class="banner-container left-media-slide-with-background">
                    <div class="banner-background" *ngIf="backgroundMedia">
                        <img *ngIf="backgroundMedia.type === 'IMAGE'" [src]="backgroundMedia | assetPreview:'full'" class="background-image" alt="Marketing Banner Image"/>
                    </div>
                    <div class="media-container mr-10" [style.height.px]="460" [ngStyle]="{'width': calculateWidths().media}">
                        <swiper-container
                            appSwiper #leftSlideSwiper
                            [slidesPerView]="1"
                            pagination.type=progressbar
                            pagination-clickable=true
                            centered-slides=true
                            autoplay-delay=5000
                            effect="fade"
                            autoplay-disable-on-interaction=false
                            lazy="true"
                            rewind=true
                            class="flex-grow object-cover custom-pagination">
                            <swiper-slide *ngFor="let media of bannerMedias" [style.height.px]="330">
                                <div class="flex justify-center items-center w-full h-full">
                                    <img *ngIf="media.type === 'IMAGE'"
                                        style="width: 330px; height: 330px;"
                                        [src]="media | assetPreview: 'large'"
                                        [width]="media.width"
                                        [height]="media.height"
                                        class="swiper-lazy w-full h-full object-contain"
                                        alt="Marketing Banner Image" />
                                    <div class="swiper-lazy-preloader"></div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                    <div *ngIf="button?.enabled && buttonPosition === 'custom'" [ngStyle]="getButtonStyles()">
                        <button
                          (click)="buttonClick($event)"
                          class="button"
                          [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                          [disabled]="inFlight"
                        >
                          {{ button.text }}
                        </button>
                    </div>
                </div>
                <div *ngIf="type === 'right-media-slide-with-background'" class="banner-container right-media-slide-with-background">
                    <div class="banner-background" *ngIf="backgroundMedia">
                        <img *ngIf="backgroundMedia.type === 'IMAGE'" [src]="backgroundMedia | assetPreview:'full'" class="background-image" alt="Marketing Banner Image"/>
                    </div>
                    <div class="media-container ml-10" [style.height.px]="460" [ngStyle]="{'width': calculateWidths().media}">
                        <swiper-container
                            [observer]="true"
                            appSwiper #rightSlideSwiper
                            [slidesPerView]="1"
                            pagination.type=bullets
                            pagination-clickable=true
                            centered-slides=true
                            autoplay-delay=5000
                            autoplay-disable-on-interaction=false
                            lazy="true"
                            effect="fade"
                            rewind=true
                            class="flex-glow object-cover custom-pagination">
                            <swiper-slide *ngFor="let media of bannerMedias" [style.height.px]="330">
                                <div class="flex justify-center items-center w-full h-full">
                                    <img *ngIf="media.type === 'IMAGE'"
                                        style="width: 330px; height: 330px;"
                                        [src]="media | assetPreview: 'large'"
                                        [width]="media.width"
                                        [height]="media.height"
                                        class="swiper-lazy w-full h-full object-contain"
                                        alt="Marketing Banner Image"/>
                                    <div class="swiper-lazy-preloader"></div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                    <div *ngIf="button?.enabled && buttonPosition === 'custom'" [ngStyle]="getButtonStyles()">
                        <button
                          (click)="buttonClick($event)"
                          class="button"
                          [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                          [disabled]="inFlight"
                        >
                          {{ button.text }}
                        </button>
                    </div>
                </div>
            </div>
            <ng-template #slideMediaMobile>
                <div *ngIf="isMobile && (type === 'right-media-slide-with-background' || type === 'left-media-slide-with-background')"
                     class="slide-media-mobile relative h-auto flex flex-col">
                    <div class="w-full h-auto object-cover"
                        *ngIf="backgroundMobileMedia">
                        <img *ngIf="backgroundMobileMedia.type === 'IMAGE'"
                            [src]="backgroundMobileMedia | assetPreview:'medium'"
                            [width]="backgroundMobileMedia.width"
                            [height]="backgroundMobileMedia.height"
                            loading="eager"
                            decoding="async"
                            fetchpriority="high"
                            class="background-image w-full h-auto object-cover"
                            alt="Marketing Banner Image" />
                    </div>
                    <div class="media-container absolute"
                        [ngClass]="getMediaContainerClass(mobileMediaPosition)">
                        <swiper-container
                            appSwiper #mobileSlideSwiper
                            [observer]="true"
                            [slidesPerView]="1"
                            pagination.type=bullets
                            pagination-clickable=true
                            centered-slides=true
                            effect="fade"
                            autoplay-delay=5000
                            autoplay-disable-on-interaction=false
                            lazy="true"
                            rewind=true
                            class="flex-glow object-cover custom-pagination">
                            <swiper-slide *ngFor="let media of bannerMedias">
                                <div class="flex justify-center items-center w-full h-full">
                                    <img *ngIf="media.type === 'IMAGE'"
                                        style="width: 75%; height: 75%;"
                                        [src]="media | assetPreview: 'small'"
                                        [width]="media.width"
                                        [height]="media.height"
                                        loading="eager"
                                        decoding="async"
                                        fetchpriority="low"
                                        class="swiper-lazy w-full h-full object-contain"
                                        alt="Marketing Banner Image" />
                                    <div class="swiper-lazy-preloader"></div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                    <div *ngIf="button?.enabled && buttonPositionMobile === 'custom'" [ngStyle]="getButtonStyles()">
                        <button
                          (click)="buttonClick($event)"
                          class="button"
                          [ngStyle]="{'background-color': button?.backgroundColor, 'color': button?.textColor, 'white-space': 'nowrap'}"
                          [disabled]="inFlight"
                        >
                          {{ button.text }}
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-template>
</div>
