import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ApolloModule } from 'apollo-angular';


import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';

import { APOLLO_CLIENT_PROVIDER } from './apollo-client-provider';
import { AccountButtonComponent } from './components/account-button/account-button.component';
import { CartDrawerComponent } from './components/cart-drawer/cart-drawer.component';
import { CartToggleComponent } from './components/cart-toggle/cart-toggle.component';
import { CollectionsMenuComponent } from './components/collections-menu/collections-menu.component';
import { LayoutFooterComponent } from './components/layout/layout-footer.component';
import { LayoutHeaderComponent } from './components/layout/layout-header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MobileMenuOverlayComponent } from './components/mobile-menu-overlay/mobile-menu-overlay.component';
import { ProductListControlsComponent } from './components/product-list-controls/product-list-controls.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductSearchBarComponent } from './components/product-search-bar/product-search-bar.component';
import { TopReviewsComponent } from './components/top-reviews/top-reviews.component';
import { buildIconLibrary } from './icon-library';
import { DefaultInterceptor } from './providers/data/interceptor';
import { OrderStatusFormComponent } from './components/order-status-form/order-status-form.component';
import { CartPageComponent } from './components/cart-page/cart-page.component';
import { FacetsCarouselComponent } from './components/facets-carousel/facets-carousel.component';
import { CollectionGroupCarouselComponent } from './components/collection-group-carousel/collection-group-carousel.component';
import { ProductCarouselComponent } from './components/product-carousel/product-carousel.component';
import { MarketingBannerComponent } from './components/marketing-banner/marketing-banner.component';
import { KeySellingPointsBannerComponent } from './components/key-selling-points-banner/key-selling-points-banner.component';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { FaqPageComponent } from './components/faq-page/faq-page.component';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { ProductCarouselV2Component } from './components/product-carousel-v2/product-carousel-v2.component';
import { EmailSubscriptionFormComponent } from './components/email-subscription-form/email-subscription-form.component';
import { CollectionsCarouselComponent } from './components/collections-carousel/collections-carousel.component';
import { ProductSwiperComponent } from './components/product-swiper/product-swiper.component';
import { CollectionSwiperComponent } from './components/collection-group-swiper/collection-group-swiper.component';
import { DualCollectionsSwiperComponent } from './components/dual-collections-swiper/dual-collections-swiper.component';
import { LayoutModule } from '@angular/cdk/layout';

const CORE_COMPONENTS = [
    ProductListComponent,
    CartToggleComponent,
    AccountButtonComponent,
    CartDrawerComponent,
    CartPageComponent,
    LayoutComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    MobileMenuOverlayComponent,
    CollectionsMenuComponent,
    ProductListControlsComponent,
    ProductCarouselComponent,
    ProductCarouselV2Component,
    MarketingBannerComponent,
    KeySellingPointsBannerComponent,
    ProductSearchBarComponent,
    OrderStatusFormComponent,
    FacetsCarouselComponent,
    EmailSubscriptionFormComponent,
    CollectionsCarouselComponent,
    CollectionGroupCarouselComponent,
    CollectionSwiperComponent,
    DualCollectionsSwiperComponent,
    TopReviewsComponent,
    AboutPageComponent,
    FaqPageComponent,
    ContactPageComponent,
    ProductSwiperComponent,
];

@NgModule({
    declarations: [
        ...CORE_COMPONENTS,
        TopReviewsComponent,
    ],
    imports: [
        HttpClientModule,
        SharedModule,
        ApolloModule,
        LayoutModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
        { provide: APP_BASE_HREF, useValue: environment.baseHref },
        APOLLO_CLIENT_PROVIDER,
    ],
    exports: [
        ...CORE_COMPONENTS,
    ],
})
export class CoreModule {
    constructor(library: FaIconLibrary) {
        buildIconLibrary(library);
    }
}
