<form #signInForm="ngForm"
      class="space-y-6"
      (submit)="signIn()">
    <div>
        <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
        <div class="mt-1">
            <input [(ngModel)]="emailAddress" id="email" name="email" type="email" autocomplete="email" required
                   email class="input-text" #email="ngModel" (change)="interactedWithEmail=true"
                   (ngModelChange)="onEmailChange($event)">
            <div *ngIf="(email.invalid && interactedWithEmail)" class="text-red-500 text-sm mt-2">
                <small *ngIf="email.errors?.required">Email is required.</small>
                <small *ngIf="email.errors?.email">Please enter a valid email address.</small>
            </div>
        </div>
    </div>

    <div>
        <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
        <div class="mt-1">
            <input [(ngModel)]="password" id="password" name="password" type="password"
                   autocomplete="current-password" required
                   class="input-text">
        </div>
    </div>

    <div class="flex items-center justify-between">
        <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox"
                   [(ngModel)]="rememberMe"
                   class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500">
            <label for="remember-me" class="ml-2 block text-sm text-gray-900">Remember me</label>
        </div>

        <div class="text-sm">
            <a [routerLink]="forgotPasswordLink$ | async"
               [queryParams]="{ email: emailAddress ?? '' }"
               class="font-medium text-primary-600 hover:text-primary-500">Forgot your password?</a>
        </div>
    </div>
    <button type="submit"
            [disabled]="signInForm.invalid || signInForm.pristine"
            class="w-full btn-primary">
        Sign in
    </button>
    <div *ngIf="displayRegisterLink" class="text-sm font-medium text-primary-600 hover:text-primary-500 mx-auto">
        <a [routerLink]="registerLink$ | async">No account? Register here</a>
    </div>
    <div class="alert alert-danger text-3xl flex items-center space-x-2 sm:text-3xl font-light tracking-tight text-bold text-red-600 my-8" role="alert" *ngIf="invalidCredentials">
        Your email address or password is incorrect. Please try again.
    </div>
</form>
