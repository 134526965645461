import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { SignInMutation, SignInMutationVariables } from '../../../common/generated-types';
import { DataService } from '../../../core/providers/data/data.service';
import { StateService } from '../../../core/providers/state/state.service';
import { CombinedAnalyticsService } from '../../../core/providers/analytics/combined-analytics.service';

import { SIGN_IN } from './sign-in.graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'vsf-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {
    @Input() navigateToOnSuccess: any[] | undefined;
    @Input() displayRegisterLink = true;

    emailAddress: string;
    password: string;
    rememberMe = false;
    invalidCredentials = false;
    interactedWithEmail = false;

    forgotPasswordLink$: Observable<any[]>;
    registerLink$: Observable<any[]>;

    constructor(private dataService: DataService,
                private stateService: StateService,
                private router: Router,
                private changeDetector: ChangeDetectorRef,
                private analyticsService: CombinedAnalyticsService) {

        // Create observables for navigation links using select
        this.forgotPasswordLink$ = this.stateService.select(state => state.storePath).pipe(
            map(storePath => {
                const pathPrefix = storePath ? [storePath] : ['/'];
                return [...pathPrefix, 'account', 'forgotten-password'];
            })
        );

        this.registerLink$ = this.stateService.select(state => state.storePath).pipe(
            map(storePath => {
                const pathPrefix = storePath ? [storePath] : ['/'];
                return [...pathPrefix, 'account', 'register'];
            })
        );
    }

    signIn() {
        this.dataService.mutate<SignInMutation, SignInMutationVariables>(SIGN_IN, {
            emailAddress: this.emailAddress,
            password: this.password,
            rememberMe: this.rememberMe,
        }).subscribe({
            next: ({login}) => {
                switch (login.__typename) {
                    case 'CurrentUser':
                        this.stateService.setState('signedIn', true);
                        const commands = this.navigateToOnSuccess || ['/'];
                        this.analyticsService.setEmail(this.emailAddress);
                        this.router.navigate(commands);
                        break;
                    case 'NativeAuthStrategyError':
                    case 'InvalidCredentialsError':
                        this.displayCredentialsError();
                        break;
                }
            },
        });
    }

    private displayCredentialsError() {
        this.invalidCredentials = false;
        this.changeDetector.markForCheck();
        setTimeout(() => {
            this.invalidCredentials = true;
            this.changeDetector.markForCheck();
        }, 50);
    }

    onEmailChange(value: string) {
        this.emailAddress = value;
        this.changeDetector.markForCheck();
    }
}
