import { Component, OnInit, ElementRef, Renderer2, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { SearchProductsQuery, SearchProductsQueryVariables } from '../../../common/generated-types';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SEARCH_PRODUCTS } from '../../../common/graphql/documents.graphql';

@Component({
  selector: 'vsf-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss']
})
export class ProductCarouselComponent implements OnInit, OnDestroy {
  products$: Observable<SearchProductsQuery['search']['items']>;
  displayedProducts: SearchProductsQuery['search']['items'] = [];
  startIndex = 0;
  private destroy$: Subject<void> = new Subject<void>();

  @Input() cardType: string = 'standard';
  @Input() collectionSlug: string;
  @Input() channelId: string;
  @Input() itemsPerSlide: number;
  @Input() displayBuyButton: boolean = false;
  @Input() displayPrice: boolean = false;
  @Input() imgSize: 'sm-h' | 'sm-v' | 'md' | 'lg' = 'md';

  constructor(
    private dataService: DataService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.products$ = this.dataService.query<SearchProductsQuery, SearchProductsQueryVariables>(SEARCH_PRODUCTS, {
      input: {
        channelId: this.channelId,
        take: 100,
        groupByProduct: true,
        collectionSlug: this.collectionSlug || '',
      },
    }).pipe(
      map(data => {
        return data.search.items;
      }),
      takeUntil(this.destroy$)
    );

    this.products$.subscribe(products => {
      this.displayedProducts = products.slice(this.startIndex, this.startIndex + this.itemsPerSlide);
      this.cdr.detectChanges();
      this.setItemsPerSlide();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setItemsPerSlide(): void {
    const slideWidth = `${(100 / this.itemsPerSlide).toString()}%`;
    // Check if elementRef is available and slides exist
    if (this.elementRef && this.elementRef.nativeElement) {
      const slides = this.elementRef.nativeElement.querySelectorAll('.card');
      Array.from(slides).forEach((slide:any) => {
        this.renderer.setStyle(slide, 'flex', `0 0 ${slideWidth}`);
        this.renderer.setStyle(slide, 'max-width', `${slideWidth}`);
      });
    }
  }

  trackByProductId(index: number, item: SearchProductsQuery['search']['items'][number]): string {
    return item.productId;
  }

  showPreviousItems(): void {
    this.startIndex = Math.max(this.startIndex - this.itemsPerSlide, 0);
    this.updateDisplayedProducts();
  }

  showNextItems(): void {
    this.products$.subscribe(products => {
      this.startIndex = Math.min(this.startIndex + this.itemsPerSlide, products.length - this.itemsPerSlide);
      this.updateDisplayedProducts();
    });
  }

  private updateDisplayedProducts(): void {
    this.products$.subscribe(products => {
      if (this.startIndex + this.itemsPerSlide >= products.length) {
        this.startIndex = Math.max(products.length - this.itemsPerSlide, 0);
      }
      this.displayedProducts = products.slice(this.startIndex, this.startIndex + this.itemsPerSlide);
      this.cdr.detectChanges();
      this.setItemsPerSlide();
    });
  }
}
