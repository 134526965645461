import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faXTwitter,
    faFacebook,
    faInstagram,
    faTwitter,
    faYoutube,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faAlipay,
    faCcApplePay,
    faGooglePay,
    faCcDinersClub,
    faCcJcb,
} from '@fortawesome/free-brands-svg-icons';
import {
    faBars, faCheck, faChevronLeft, faChevronRight,
    faCreditCard,
    faFilter, faHeart,
    faHome,
    faMinus,
    faPlus,
    faSadCry,
    faSearch,
    faShoppingCart,
    faStar,
    faStarHalfAlt,
    faTimes,
    faUserCircle,
    faMapMarkerAlt,
    faShoppingBag,
    faCheckCircle,
    faPlusCircle,
    faMinusCircle,
    faArrowRight,
    faCaretRight,
    faSpinner,
    faImage,
    faQuoteLeft,
    faQuoteRight,
} from '@fortawesome/free-solid-svg-icons';

import {
    faStar as farStar,
    faUser as farUser,
} from '@fortawesome/free-regular-svg-icons'

/**
 * All the icons used in the library are declared here. This allows us to
 * ship only the needed icons so that the bundle does not get bloated.
 */
export function buildIconLibrary(library: FaIconLibrary) {
    library.addIcons(
        faXTwitter,
        faTwitter,
        faFacebook,
        faInstagram,
        faYoutube,
        faTimes,
        faMinus,
        faPlus,
        faShoppingCart,
        faUserCircle,
        faCreditCard,
        faSearch,
        faSadCry,
        faFilter,
        faBars,
        faHome,
        faHeart,
        faStar,
        faChevronLeft,
        faChevronRight,
        faCheck,
        faShoppingBag,
        faMapMarkerAlt,
        faPlusCircle,
        faMinusCircle,
        faCheckCircle,
        faCcVisa,
        faCcMastercard,
        faCcAmex,
        faCcDiscover,
        faAlipay,
        faCcApplePay,
        faGooglePay,
        faCcDinersClub,
        faCcJcb,
        faArrowRight,
        faCaretRight,
        farStar,
        farUser,
        faStarHalfAlt,
        faSpinner,
        faImage,
        faQuoteLeft,
        faQuoteRight,
    );
}
