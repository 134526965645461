import { NGXLogger } from 'ngx-logger';

export function safeJSONParse<T>(jsonString: string | undefined, logger: NGXLogger): T | null {
    if (!jsonString) {
        return null;
    }
    try {
        return JSON.parse(jsonString) as T;
    } catch (error) {
        logger.error(`Failed to parse JSON: ${error}`);
        return null;
    }
}