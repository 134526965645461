<!-- <div id="mobile-indicator" class="is-mobile"></div> -->
<div *ngIf="product as productDetail" class="max-w-6xl mx-auto">
    <div class="mb:pb-24">
        <div *ngIf="heroSection && heroSection?.medias?.length > 0; else deprecatedHeroSection">
            <vsf-hero-section
                [heroSection]="heroSection">
            </vsf-hero-section>
        </div>
        <ng-template #deprecatedHeroSection>
            <div>
                <vsf-product-hero-section
                    [product]="productDetail">
                </vsf-product-hero-section>
            </div>
        </ng-template>
        <div *ngIf="productDetail.productKeySellingPoints && productDetail.productKeySellingPoints.length > 0">
            <vsf-key-selling-points-banner
                [keySellingPoints]="productDetail.productKeySellingPoints"
                [layout]="'horizontal'"
                >
            </vsf-key-selling-points-banner>
        </div>
        <div *ngIf="selectedVariant" class="pb-[60px]">
            <vsf-product-info
                [product]="productDetail"
                [selectedVariant]="selectedVariant"
                [colorConfig]="colorConfig"
                (variantChanged)="onVariantChange($event)">
            </vsf-product-info>
        </div>
        <hr>
        <div id="product-reviews" *ngIf="productDetail.customFields?.featuredReview">
            <vsf-porduct-review-section
                [productId]="productDetail.id"
                [reviewRating]="productDetail.customFields?.reviewRating"
                [reviewCount]="productDetail.customFields?.reviewCount"
                [featuredReview]="productDetail.customFields?.featuredReview"
                [isMobile]="isMobile"
            ></vsf-porduct-review-section>
        </div>
        <div>
            <div *ngFor="let markingBanner of productDetail.marketingBanners" class="mt-4 mx-4">
                <vsf-marketing-banner  style="min-height: 400px;"
                    [marketingBanner]="markingBanner"
                    [isMobile]="isMobile">
                </vsf-marketing-banner>
            </div>
        </div>
        <div *ngIf="crossSellingFeaturedCollection">
            <div class="corss-selling-product-collection-title mt-8">
                <p>You May Also Like</p>
                <div class="corss-selling-title-underline"></div>
            </div>
            <div *ngIf="(isMobile===false)">
                <vsf-product-carousel-v2
                    [title]="crossSellingFeaturedCollection.title"
                    [subtitile]="crossSellingFeaturedCollection.subtitle"
                    [displayType] = "crossSellingFeaturedCollection.displayType"
                    [collectionItemOrder]="crossSellingFeaturedCollection?.collection?.customFields?.itemOrderData"
                    [collectionSlug]="crossSellingFeaturedCollection?.collection?.slug"
                    [collectionName]="crossSellingFeaturedCollection?.collection?.name"
                    [channelId]="storeSite?.sellerChannelId"
                    [itemsPerSlide]=4
                    [displayCarouselTitle]="false"
                    [displayPrice]="true"
                    [displayAlternativeImage]="true"
                    [imgSize]="'md'">
                </vsf-product-carousel-v2>
            </div>
            <div *ngIf="isMobile" class="mt-4 mb-8">
                <vsf-product-swiper
                    [displayType] = "crossSellingFeaturedCollection.displayType"
                    [collectionSlug]="crossSellingFeaturedCollection?.collection?.slug"
                    [collectionItemOrder]="crossSellingFeaturedCollection?.collection?.customFields?.itemOrderData"
                    [channelId]="storeSite?.sellerChannelId"
                    [layoutMode]="'vertical'"
                    [itemsPerRow]=2
                    [itemsPerSlide]=6
                    [displayPrice]="true"
                    [displaySubtitle]="false"
                    [displayPagination]="false"
                    [imgSize]="'sm-v'">
                </vsf-product-swiper>
            </div>
        </div>
        <div *ngIf="collectionListForShopByGroupList?.length > 0">
            <div *ngFor="let collectionList of collectionListForShopByGroupList">
                <div *ngIf="isMobile"  class="mb-4">
                    <vsf-collection-group-swiper
                        [collectionGroup]="collectionList"
                        [displayPagination]=true>
                </vsf-collection-group-swiper>
                </div>
                <div *ngIf="(isMobile===false)" class="facet-group mb-12">
                    <vsf-collection-group-carousel
                        [collectionGroup]="collectionList"
                        >
                    </vsf-collection-group-carousel>
                </div>
            </div>
        </div>
        <div *ngIf="productDetail.customFields.ProductDisclaimer && isMobile">
            <div class="border rounded shadow p-4">
                <span class="disclaimer-html" [innerHtml]="productDetail.customFields.ProductDisclaimer | safeHtml"></span>
            </div>
        </div>
        <hr>
    </div>
    <div *ngIf="isMobile" [style.z-index]="9" class="fixed bottom-0 left-0 right-0 bg-white shadow-lg">
        <vsf-product-shop-now-banner
            [product]="productDetail"
            [selectedVariant]="selectedVariant"
            [inStock]="inStock"
            [buttonBackgroundColor]="colorConfig?.secondaryColor"
            >
        </vsf-product-shop-now-banner>
    </div>
</div>
