<div id="mobile-indicator" class="is-mobile"></div>
<div *ngIf="readyToDisplay$ | async" class="faq-container">
  <!-- FAQ Poster Section -->
  <div *ngIf="posterAsset" class="faq-poster">
    <img [src]="posterAsset | assetPreview:'full'"
        [ngClass]="isMobile? 'object-cover w-full h-full aspect-[16/9]' : ''"
        alt="Poster for FAQ Page" />
  </div>

  <!-- Frequently Asked Questions Section -->
  <div *ngIf="faqList?.length > 0" class="faq-section">
    <p class="faq-title">{{title}}</p>
    <div class="faq-item" *ngFor="let faq of getVisibleFaqs()">
      <div class="faq-question" (click)="toggleFaq(faq)">
        {{ faq.question }}
        <span class="toggle-icon">{{ faq.open ? '-' : '+' }}</span>
      </div>
      <div class="faq-answer" *ngIf="faq.open">
        <p [innerHtml]="faq.answer | safeHtml"></p>
      </div>
    </div>
    <!-- Show All Questions Button -->
    <div *ngIf="!showAll && (faqList.length > defaultDisplayCount)"
        class="view-all-btn" >
      <button
          [ngStyle]="{
            'background-color': colorConfig ? colorConfig.secondaryColor : '#B6E7B0',
            'color': colorConfig ? '#000000' : '#FFFFFF'
          }"
          (click)="showAllQuestions()">
          View All Questions
      </button>
    </div>
  </div>
</div>