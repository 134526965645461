<button class="search-button relative hover:text-primary-100" (click)="doSearch('')">
  <fa-icon [icon]="['fas', 'search']" class="search-icon h-5 w-5 text-gray-400 hover:text-gray-800 hover:font-bold"></fa-icon>
</button>

<div *ngIf="showOverlay" class="search-overlay">
  <div class="relative flex flex-row justify-between"
      [ngStyle]="{'width': isMobile ? '85%' : '50%'}"
      style="max-width: 800px;">
      <input type="text" [formControl]="searchTerm" (keydown.enter)="doSearch(searchTerm.value)"
             class="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
             placeholder="Search..." aria-label="Search input">
      <button class="absolute inset-y-0 right-0 pr-3 flex items-center" (click)="doSearch(searchTerm.value)">
        <fa-icon [icon]="['fas', 'search']" class="h-5 w-5 text-gray-400"></fa-icon>
      </button>
  </div>
  <button class="close-button" (click)="closeOverlay()">
      <fa-icon [icon]="['fas', 'times']" class="h-5 w-5 text-gray-400"></fa-icon>
  </button>
</div>