import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { AddressFragment, OrderAddressFragment } from '../../../common/generated-types';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'vsf-address-card',
    templateUrl: './address-card.component.html',
    // styleUrls: ['./address-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressCardComponent implements OnInit {

    @Input() address: OrderAddressFragment | AddressFragment;
    @Input() title = '';

    constructor(private logger: NGXLogger) {
        this.logger.warn('constructor AddressCardComponent');
    }


    ngOnInit(): void {
        this.logger.warn('Load AddressCardComponent');
    }

    getCountryName(): string {
        if (!this.address?.country) {
            return '';
        }
        if (typeof this.address?.country === 'string') {
            return this.address?.country;
        } else {
            return this.address?.country?.name;
        }
    }
}
