<div id="mobile-indicator" class="is-mobile"></div>
<vsf-layout *ngIf="returnRequestForm$ | async; else normalPage">
    <router-outlet></router-outlet>
</vsf-layout>
<ng-template #normalPage>
    <vsf-layout *ngIf="(readyToDisplay$ | async) && (uiState.isMobile !== null)">
        <vsf-layout-header style="position: relative; z-index: 12;" id="page-header">
            <!-- top promotion message -->
            <div *ngIf="uiState.isForStore; else nonStore">
                <div *ngIf="!uiState.isMobile; else storeMobileHeader">
                    <div *ngIf="pageMessages.headerMessage">
                        <div class="header-message text-center text-2xl py-1 px-2 xl:px-0"
                            [ngStyle]="{'background-color': storeColor?.headerBackgroundColor, 'color': storeColor?.headerFontColor}">
                            <div class="max-w-6xl mx-2 md:mx-auto flex items-center justify-center">
                                <p> {{pageMessages.headerMessage}} </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="uiState.displayHeaderMenu && storeInfo.identifier"
                        class="bg-white max-w-6xl mx-auto px-2 py-2 items-center justify-between"
                        style="min-height: 60px;">
                        <div class="flex flex-row">
                            <vsf-store-logo class="flex-shrink-0"
                                [normalLogo]="storeLogoConfig.normalLogo"
                                [nameLogo]="storeLogoConfig.nameLogo"
                                [smallLogo]="storeLogoConfig.smallLogo"
                                [name]="storeInfo.name"
                                [path]="['/store', storeInfo.identifier]"
                                [logoHeight]="'50px'"
                                [displayName]="storeLogoConfig.displayName"
                                [isMobile]="uiState.isMobile"
                            >
                            </vsf-store-logo>
                            <div class="nav-menus flex items-center space-x-4 ml-auto">
                                <nav *ngIf="storeNavMenu.length > 0" class="py-2 flex flex-row">
                                  <div *ngFor="let item of storeNavMenu" class="flex items-center">
                                    <button (click)="navigateToPage(item)" class="nav-banner-button">{{ item.label }}</button>
                                  </div>
                                </nav>
                            </div>
                            <div class="control-icons grid grid-cols-3 col-span2 ml-2 justify-end items-center gap-x-2  flex-shrink-0">
                                <vsf-product-search-bar [storeIdentifier]="storeInfo.identifier"></vsf-product-search-bar>
                                <vsf-cart-toggle class="text-white" (toggle)="openCartDrawer()"></vsf-cart-toggle>
                                <vsf-account-button
                                    class="text-black"
                                    [storeIdentifier]="storeInfo.identifier"
                                ></vsf-account-button>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #storeMobileHeader>
                    <ng-container *ngIf="uiState.displayHeaderMenu">
                        <div class="flex flex-row justify-center pt-4 pb-2 bg-white" style="min-height: 60px;">
                            <div class="grid grid-cols-2 justify-center items-center gap-x-6">
                                <button id="navButton" class="nav-button ml-3" (click)="toggleMobileMenu()">
                                    <fa-icon icon="bars" class="h-5 w-5 text-gray-400"></fa-icon>
                                </button>
                                <vsf-product-search-bar [storeIdentifier]="storeInfo.identifier"></vsf-product-search-bar>
                            </div>
                            <div class="flex flex-grow felx-grow items-center justify-center">
                                <vsf-store-logo
                                    [normalLogo]="storeLogoConfig.normalLogo"
                                    [nameLogo]="storeLogoConfig.nameLogo"
                                    [smallLogo]="storeLogoConfig.smallLogo"
                                    [name]="storeInfo.name"
                                    [path]="['/store', storeInfo.identifier]"
                                    [logoHeight]="'50px'"
                                    [displayName]="storeLogoConfig.displayName"
                                    [isMobile]="uiState.isMobile"
                                >
                                </vsf-store-logo>
                            </div>
                            <div class="grid grid-cols-2 justify-center items-center gap-x-6">
                                <vsf-cart-toggle class="text-white" (toggle)="openCartDrawer()"></vsf-cart-toggle>
                                <vsf-account-button
                                    class="text-black"
                                    [storeIdentifier]="storeInfo.identifier"
                                ></vsf-account-button>
                            </div>
                        </div>
                        <hr>
                        <div *ngIf="uiState.isNavExpanded && uiState.isMobile" class="bg-white nav-container">
                            <div class="flex justify-center items-center">
                                <nav *ngIf="storeNavMenu.length > 0" class="mx-auto py-2 flex flex-row justify-between">
                                    <div *ngFor="let item of storeNavMenu" class="flex items-center">
                                        <button (click)="navigateToPage(item)" class="nav-banner-button">{{item.label}}</button>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </div>
            <ng-template #nonStore>
                <div>
                    <div *ngIf="pageMessages?.headerMessage">
                        <div class="header-message bg-black text-white text-center text-2xl py-1 px-2 xl:px-0">
                            <div class="max-w-6xl mx-2 md:mx-auto flex items-center justify-center">
                                <p class="block"> {{pageMessages?.headerMessage}} </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white max-w-6xl mx-auto px-2 py-2 flex items-center justify-between">
                    <div style="width: 85%;">
                        <div class="flex">
                            <div   class="flex flex-row">
                                <div  class="flex flex-row items-center w-auto" [ngStyle]="{'min-width': uiState.isMobile ? '60px' : '250px'}">
                                    <vsf-store-logo
                                        [normalLogo]="storeLogoConfig.normalLogo"
                                        [nameLogo]="storeLogoConfig.nameLogo"
                                        [smallLogo]="storeLogoConfig.smallLogo"
                                        [name]="storeInfo.name"
                                        [path]="['/']"
                                        [logoHeight]="'30px'"
                                        [displayName]="false"
                                        [isMobile]="uiState.isMobile"
                                    >
                                    </vsf-store-logo>
                                    <a *ngIf="!uiState.isMobile" [routerLink]="['/']">
                                        <div class="justify-start items-center">
                                            <div *ngIf="!storeLogoConfig.nameLogo && storeInfo.name">
                                                <p class="header-title mr-5 ml-2 text-left">{{ storeInfo.name }}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div *ngIf="uiState.isMobile && uiState.isForHomepage">
                                    <button id="navButton" class="nav-button ml-3" (click)="clickOnNavButton()">
                                        <fa-icon icon="bars" class="h-5 w-5 text-gray-400"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="(!uiState.isMobile) && productName" class="flex flex-grow header-title text-center justify-center items-center w-full">
                                <p>{{ productName }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center space-x-4 ml-4">
                        <div class="grid grid-cols-3 col-span2 ml-2 justify-end items-center gap-x-2">
                            <vsf-product-search-bar [isMobile]="uiState.isMobile"></vsf-product-search-bar>
                            <vsf-cart-toggle class="text-white" (toggle)="openCartDrawer()"></vsf-cart-toggle>
                            <vsf-account-button class="text-black"></vsf-account-button>
                        </div>
                    </div>
                </div>
                <ng-template [ngIf]="uiState.isForHomepage">
                    <div *ngIf="channelInfo$ | async as channel">
                        <hr>
                        <div *ngIf="uiState.isNavExpanded && uiState.isMobile" class="bg-white nav-container">
                            <div class="max-w-6xl mx-auto py-2 flex justify-between">
                                <button (click)="scrollToSection('new')" class="nav-banner-button">{{channel.customFields?.NewArrivalsCollectionLabel}}</button>
                                <button (click)="scrollToSection('for-you')" class="nav-banner-button">{{channel.customFields?.DefaultCollectionLabel}}</button>
                                <button (click)="scrollToSection('sales-offers')" class="nav-banner-button">{{channel.customFields?.HotSalesCollectionLabel}}</button>
                            </div>
                        </div>
                        <div *ngIf="!uiState.isMobile" class="nav-banner bg-white">
                            <div class="max-w-6xl mx-auto py-2 flex items-left">
                                <button (click)="scrollToSection('new')" class="nav-banner-button">{{channel.customFields?.NewArrivalsCollectionLabel}}</button>
                                <button (click)="scrollToSection('for-you')" class="nav-banner-button">{{channel.customFields?.DefaultCollectionLabel}}</button>
                                <button (click)="scrollToSection('sales-offers')" class="nav-banner-button">{{channel.customFields?.HotSalesCollectionLabel}}</button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
            <hr>
        </vsf-layout-header>

        <!-- Cart Drawer -->
        <div *ngIf="uiState.isForStore; else nonStoreCart">
            <vsf-cart-drawer (close)="closeCartDrawer()" [storeIdentifier]="storeInfo?.identifier" [visible]="cartDrawerVisible$ | async"></vsf-cart-drawer>
        </div>
        <ng-template #nonStoreCart>
            <vsf-cart-drawer (close)="closeCartDrawer()" [visible]="cartDrawerVisible$ | async"></vsf-cart-drawer>
        </ng-template>

        <!-- Mobile menu overlay -->
        <div *ngIf="uiState.isMobile && uiState.isForStore && (orderStatusHref$ | async)">
            <vsf-mobile-menu-overlay
                [isOpen]="uiState.isNavExpanded"
                [navMenu]="storeNavMenu"
                [orderStatusHref]="orderStatusHref$ | async"
                (onClose)="closeMobileMenu()"
                (onNavigate)="navigateToPage($event)"
                (onContactUs)="handleAction('Contact Us')"
            ></vsf-mobile-menu-overlay>
        </div>

        <!-- Page Content -->
        <div class="contents" class="max-w-6xl mx-auto"  [ngStyle]="{'min-height': uiState.isMobile ? '800px' : '1200px'}">
            <router-outlet></router-outlet>
        </div>

        <!-- Page Footer -->
        <vsf-layout-footer *ngIf="uiState.displayFooterMenu" style="min-height: 425px;">
            <footer aria-labelledby="footer-heading"
                *ngIf="(uiState.isForStore) || (!uiState.isForStore && (channelInfo$ | async))"
                [ngStyle]="{
                    'background-color': uiState.isMobile ? '#F2F4F7': ((uiState.isForStore && storeColor?.footerBackgroundColor) || '#29319C'),
                    'color': uiState.isMobile ? 'inherit' : ((uiState.isForStore && storeColor?.footerFontColor) || 'inherit')
                }"
                class="border-t">
                <div class="max-w-6xl mx-auto mt-6 md:mt-0 py-0 md:py-12 px-4 sm:px-6 lg:py-16 lg:px-8 footer-text">
                    <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 xl:col-span-2 mb-4 md:mb-10">
                            <div class="md:grid md:grid-cols-2 gap-2 md:gap-8">
                                <div *ngIf="navigation?.services?.length > 0">
                                    <div>
                                        <a [routerLink]="uiState.isForStore?['/store', storeInfo.identifier]:['/']" class="l">
                                            <div *ngIf="storeLogoConfig.normalLogo" class="rounded shadow pb-4" style="width:80px; height: auto;">
                                                <img [src]="storeLogoConfig.normalLogo | assetPreview:'small'" style="pointer-events: none;" [width]="storeLogoConfig.normalLogo.width" [height]="storeLogoConfig.normalLogo.height" alt="logo"/>
                                            </div>
                                        </a>
                                    </div>
                                    <ul class="mt-4 space-y-4 footer-subtext" role="list">
                                        <li *ngFor="let item of navigation?.services">
                                          <span *ngIf="item.type === 'action'"
                                            (click)="handleAction(item.name)"
                                            class="text-base hover:text-gray-100 cursor-pointer"
                                          >
                                            {{item.name}}
                                          </span>
                                          <a *ngIf="item.type === 'route' || item.type === 'external'"
                                            [href]="(item.type === 'external'||item.type === 'route') ? ((item.href$ | async) || item.href) : null"
                                            [attr.target]="item.type === 'external' ? '_blank' : null"
                                            class="text-base hover:text-gray-100 cursor-pointer"
                                          >
                                            {{item.name}}
                                          </a>
                                        </li>
                                    </ul>
                                    <div *ngIf="uiState.isForStore && storeInfo?.snsInfo" class="mt-8 mb-8 text-sm font-semibold tracking-wider flex flex-col">
                                        <span>Follow us On</span>
                                        <div class="mt-4 flex flex-row space-x-2">
                                            <a *ngIf="storeInfo?.snsInfo?.instagram"  [href]="storeInfo?.snsInfo?.instagram" target="_blank" class="text-base hover:text-gray-100 cursor-pointer">
                                                <fa-icon [icon]="['fab', 'instagram']" class="text-2xl"></fa-icon>
                                            </a>
                                            <a *ngIf="storeInfo?.snsInfo?.facebook"  [href]="storeInfo?.snsInfo?.facebook" target="_blank" class="text-base hover:text-gray-100 cursor-pointer">
                                                <fa-icon [icon]="['fab', 'facebook']" class="text-2xl"></fa-icon>
                                            </a>
                                            <a *ngIf="storeInfo?.snsInfo?.xTwitter"  [href]="storeInfo?.snsInfo?.xTwitter" target="_blank" class="text-base hover:text-gray-100 cursor-pointer">
                                                <fa-icon [icon]="['fab', 'x-twitter']" class="text-2xl"></fa-icon>
                                            </a>
                                            <a *ngIf="storeInfo?.snsInfo?.youtube"  [href]="storeInfo?.snsInfo?.youtube" target="_blank" class="text-base hover:text-gray-100 cursor-pointer">
                                                <fa-icon [icon]="['fab', 'youtube']" class="text-2xl"></fa-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="navigation?.orders?.length > 0" class="mt-4 md:mt-0">
                                    <h3 class="text-sm font-semibold tracking-wider uppercase">
                                        Orders
                                    </h3>
                                    <ul class="mt-4 space-y-4 footer-subtext" role="list">
                                        <li *ngFor="let item of navigation?.orders">
                                          <span *ngIf="item.type === 'action'"
                                            (click)="handleAction(item.name)"
                                            class="text-base hover:text-gray-100 cursor-pointer"
                                          >
                                            {{item.name}}
                                          </span>
                                          <a *ngIf="item.type === 'route' || item.type === 'external'"
                                            [href]="(item.type === 'external'||item.type === 'route') ? ((item.href$ | async) || item.href) : null"
                                            [attr.target]="item.type === 'external' ? '_blank' : null"
                                            class="text-base hover:text-gray-100 cursor-pointer"
                                          >
                                            {{item.name}}
                                          </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="navigation?.company?.length > 0" class="md:grid md:grid-cols-1 gap-2 md:gap-8">
                                <div>
                                    <h3 class="text-sm font-semibold tracking-wider uppercase">
                                        {{uiState.isForStore?'POWERED BY DEALOMEGA.COM':'Company'}}
                                    </h3>
                                    <ul class="mt-4 space-y-4 footer-subtext" role="list">
                                        <li *ngFor="let item of navigation?.company">
                                          <span *ngIf="item.type === 'action'"
                                            (click)="handleAction(item.name)"
                                            class="text-base hover:text-gray-100 cursor-pointer"
                                          >
                                            {{item.name}}
                                          </span>
                                          <a *ngIf="item.type === 'route' || item.type === 'external'"
                                            [href]="(item.type === 'external'||item.type === 'route') ? ((item.href$ | async) || item.href) : null"
                                            [attr.target]="item.type === 'external' ? '_blank' : null"
                                            class="text-base hover:text-gray-100 cursor-pointer"
                                          >
                                            {{item.name}}
                                          </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ng-template [ngIf]="!uiState.isMobile">
                            <div *ngIf="pageMessages.disclaimer" class="rounded shadow p-4 footer-subtext">
                                <p [innerHtml]="pageMessages.disclaimer | safeHtml"></p>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="channelInfo$ | async as channel">
                    <div *ngIf="channel.customFields?.StoreCopyrightNotice && channel.customFields?.StoreCopyrightNotice !== null"
                        class="disclaimer-html text-center text-sm py-2 footer-subtext">
                        <div *ngIf="uiState.isForHomepage; else nonHomePage">
                                <span  [innerHtml]="channel.customFields?.StoreCopyrightNotice | safeHtml"></span>
                        </div>
                        <ng-template #nonHomePage>
                            <div class="mb-14 md:mb-0">
                                <span  [innerHtml]="channel.customFields?.StoreCopyrightNotice | safeHtml"></span>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </footer>
        </vsf-layout-footer>
    </vsf-layout>
</ng-template>
<ng-container *ngIf="fullscreenPreviewOpen$ | async">
    <vsf-vimeo-fullscreen-preview
        [videoId]="vimeoVideoId"
        (close)="closeFullscreenPreview()">
    </vsf-vimeo-fullscreen-preview>
</ng-container>