<div>
  <div id="return-form" class="printable flex flex-col mt-20 ml-2 mr-2">
    <div class="text-center font-bold text-3xl">
      <h1>Order Return Form</h1>
    </div>

    <hr class="mt-10 mb-4">

    <div>
      <h2 class="font-bold text-xl">Return Address:</h2>
      <div class="ml-20">
        <p>{{sellerAddress.fullName}}</p>
        <p>{{sellerAddress.streetLine1}}</p>
        <p>{{sellerAddress.streetLine2}}</p>
        <p>{{sellerAddress.city}}</p>
        <p>{{sellerAddress.province}}</p>
        <p>{{sellerAddress.postalCode}}</p>
      </div>
    </div>

    <hr class="mt-4 mb-4">

    <div class="flex flex-row text-xl">
      <h2 class="font-bold">RMA #:</h2>
      <p class="ml-8">{{formData.RMA}}</p>
    </div>
    <div class="mt-4">
      <h2 class="font-bold text-xl">Return Details:</h2>
      <div class="ml-10">
        <div class="flex flex-row">
          <h3 class="font-bold mr-4">Order Number:</h3>
          <p>{{formData.order.code}}</p>
        </div>
        <div class="flex flex-row">
          <h3 class="font-bold mr-4">Order Date:</h3>
          <p>{{formData.order.createdAt}}</p>
        </div>
        <div class="flex flex-row">
          <h3 class="font-bold mr-4">Return Request Date:</h3>
          <p>{{formData.createdAt}}</p>
        </div>
        <div class="flex flex-col">
          <h3 class="font-bold mr-4">Items to be Returned:</h3>
          <div *ngFor="let item of formData.items" class="ml-20">
            <p>{{ item.line.productVariant.name }}<span *ngIf="item?.line?.productVariant?.sku"> [ SKU:{{item.line.productVariant.sku}} ]</span> x {{ item.quantity }}</p>
          </div>
        </div>
      </div>
    </div>

    <hr class="mt-4 mb-4">

    <div>
      <h2 class="text-xl font-bold">Return Instructions:</h2>
      <ul class="ml-4">
        <li>Ensure the items are in their original condition, with all tags and packaging intact.</li>
        <li>Print out this return form and include it in your return package.</li>
        <li>Ship the return package to the Return Address shown above.</li>
        <li>The Customer is responsible for return shipping charges. We recommend insuring and/or sending merchandise through a traceable shipping method. C.O.D. packages will not be accepted.</li>
      </ul>
    </div>
  </div>

  <hr class="mt-4 mb-4">

  <div class="flex flex-grow flex-row justify-between ml-10 mr-10 mt-3 mb-10">
    <div class="flex-1">
      <button class="btn btn-secondary" (click)="close()">Close</button>
    </div>
    <div class="flex-1">
      <button class="btn btn-secondary" (click)="printForm()">Print Form</button>
    </div>
  </div>
</div>