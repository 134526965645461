<div id="mobile-indicator" class="is-mobile"></div>
<div class="max-w-6xl mx-auto px-4 w-full">
    <ng-container *ngIf="collection$ | async as collection">
        <div *ngIf="!channelId">
            <div class="flex justify-between items-center">
                <p class="text-3xl sm:text-5xl font-light tracking-tight text-gray-900 my-8">
                    {{collection.name}}
                </p>
            </div>
            <vsf-collection-breadcrumbs [breadcrumbs]="breadcrumbs$ | async"></vsf-collection-breadcrumbs>    
        </div>

        <ng-container *ngIf="collection.children.length">
            <div class="max-w-2xl mx-auto py-16 sm:py-16 lg:max-w-none border-b mb-16">
                <p class="text-2xl font-light text-gray-900">
                    Collections
                </p>
                <div class="mt-6 grid max-w-xs sm:max-w-none mx-auto sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-4">
                    <vsf-collection-card *ngFor="let child of collection.children"
                                         [collection]="child">
                    </vsf-collection-card>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- Original control panel -->
    <div class="original-control-panel">
        <div class="flex justify-between mb-4">
            <div class="flex items-center control-panel">
                <button class="mr-4 px-4 py-2 border border-[#6b7280]"
                        (click)="toggleFacetMenu()">
                    <div class="flex flex-row justify-center items-center">
                        <p class="mr-4">{{ facetMenuVisible ? 'Hide' : 'Show' }} Filters</p>
                        <fa-icon [icon]="['fas', 'bars']" class="ml-2 color-[#6b7280] icon-size"></fa-icon>
                    </div>
                </button>
                <select (change)="onSortChange($event.target.value)" class="form-select">
                    <option value="">Sort by</option>
                    <option value="price-high">Price: High to Low</option>
                    <option value="price-low">Price: Low to High</option>
                    <option value="name-a-to-z">Name: A to Z</option>
                    <option value="name-z-to-a">Name: Z to A</option>
                    <option value="time-newest">New Arrivals</option>
                </select>
            </div>
        </div>
    </div>

    <!-- Floating control panel -->
    <div *ngIf="isMobile && showFloatingPanel"
        class="floating-control-panel"
        [ngClass]="{
            'visible': showFloatingPanel,
            'header-visible': isHeaderVisible,
            'header-hidden': !isHeaderVisible
        }">
        <div class="flex items-center control-panel">
            <button class="mr-4 px-4 py-2 border border-[#6b7280]"
                    (click)="toggleFacetMenu()">
                <div class="flex flex-row justify-center items-center">
                    <p class="mr-4">{{ facetMenuVisible ? 'Hide' : 'Show' }} Filters</p>
                    <fa-icon [icon]="['fas', 'bars']" class="ml-2 color-[#6b7280] icon-size"></fa-icon>
                </div>
            </button>
            <select (change)="onSortChange($event.target.value)" class="form-select">
                <option value="">Sort by</option>
                <option value="price-high">Price: High to Low</option>
                <option value="price-low">Price: Low to High</option>
                <option value="name-a-to-z">Name: A to Z</option>
                <option value="name-z-to-a">Name: Z to A</option>
                <option value="time-newest">New Arrivals</option>
            </select>
        </div>
    </div>

    <div class="w-100 relative flex flex-row text-center overflow-hidden " 
        [ngClass]="{ 'justify-center grid sm:grid-cols-4 gap-x-4': facetMenuVisible && (isMobile===false),
                     'justify-between grid sm:grid-cols-4': isMobile,
                     'justify-center ': !facetMenuVisible && (isMobile===false)}">
        <!-- Facet Menu for Desktop -->
        <div *ngIf="facetMenuVisible && (isMobile===false)" class="flex justify-start items-start align-left text-left whitespace-nowrap mr-8">
            <vsf-product-list-controls
                class="mb-4 pl-1"
                [facetValues]="facetValues"
                [activeFacetValueIds]="activeFacetValueIds$ | async"
                [totalResults]="unfilteredTotalItems">
            </vsf-product-list-controls>
        </div>

        <!-- Facet Menu Overlay for Mobile -->
        <div *ngIf="isMobile && facetMenuVisible" class="facet-menu-overlay" (click)="onBackdropClick()">
            <div class="facet-menu-content" (click)="$event.stopPropagation()">
                <button class="close-button" (click)="toggleFacetMenu()">
                    <fa-icon [icon]="['fas', 'times']"></fa-icon>
                </button>
                <div class="mt-15">
                    <vsf-product-list-controls
                        [facetValues]="facetValues"
                        [activeFacetValueIds]="activeFacetValueIds$ | async"
                        [totalResults]="unfilteredTotalItems">
                    </vsf-product-list-controls>
                </div>
            </div>
        </div>
        <!-- Product List Panel -->
        <div class="flex flex-col col-span-3 w-full">
            <div class="product-list-panel grid grid-cols-2 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                <ng-container *ngIf="(totalResults$ | async) !== null; else placeholders">
                    <vsf-product-card-v2 *ngFor="let product of products$ | async; trackBy: trackByProductId"
                                      [item]="product"
                                      [displaySubtitle]="false"
                                      [displayPrice]="true"
                                      [displayBuyButton]="true"
                                      [displayAlternativeImage]="true"
                                      [imgType]="'square'"></vsf-product-card-v2>
                </ng-container>
                <ng-template #placeholders>
                    <vsf-product-card-v2 *ngFor="let product of placeholderProducts"
                                      [item]="product"></vsf-product-card-v2>
                </ng-template>
            </div>
            <div *ngIf="totalResults$ | async" class="showing-text">
                Showing {{ (products$ | async)?.length }} of {{ totalResults$ | async }}
            </div>
            <div class="load-more flex-fill mt-8" *ngIf="displayLoadMore$ | async">
                <button class="btn btn-primary btn-lg d-inline-flex align-items-center"
                        [ngStyle]="{
                            'background-color': '#F5F5F5',
                            'color': '#677583',
                            'font-size': '14px',
                            'padding': '10px 22px',
                        }"
                        (click)="loadMore()"
                        [disabled]="loading$ | async">
                    View More
                    <span [class.show]="loading$ | async"
                          class="loading-indicator spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"></span>
                </button>
            </div>
            <div class="move-to-top">
                <button class="move-to-top-button" (click)="scrollToTop()">
                    Move to Top
                </button>
            </div>
        </div>
    </div>

    <ng-template #noResults>
        <div class="no-results col-12">
            <p class="h1">No results</p>
            <fa-icon icon="sad-cry" size="10x"></fa-icon>
        </div>
    </ng-template>
</div>
