<div class="flex h-full flex-col bg-white shadow-xl">
    <div #title class="flex items-center justify-center py-8 px-4">
        <p class="font-bold text-2xl md:text-4xl text-gray-900">Cart</p>
    </div>
    <div class="flex flex-col md:flex-row">
        <div #cartContent class="flex-1 height-full flex flex-col overflow-y-hidden py-2 px-4 sm:px-6">
            <div *ngIf="!(isEmpty$ | async); else empty" class="mt-6 px-4 overflow-y-auto">
                <vsf-cart-contents [cart]="cart$ | async"
                                    [canAdjustQuantities]="true"
                                    (setQuantity)="setQuantity($event)">
                </vsf-cart-contents>
            </div>
            <ng-template #empty>
                <div class="flex items-center justify-center h-48 text-xl text-gray-400">
                    Your cart is empty
                </div>
            </ng-template>
        </div>
        <div #cartTotal class="height-full overflow-y-hidden py-2 md:w-1/2">
            <div class="border-t border-gray-200 py-6 px-4 sm:px-6" *ngIf="!(isEmpty$ | async)">
                <div *ngIf="cart$ | async as cart">
                    <div *ngIf="cart.discounts && cart.discounts.length > 0" class="applied-coupon">
                        <p class="text-base font-medium text-gray-900">Your Discounts</p>
                        <div *ngFor="let discount of cart.discounts" class="flex justify-between text-sm text-gray-900">
                            <p>{{ discount.description }}</p>
                            <p>{{ discount.amount | formatPrice }}</p>
                        </div>
                    </div>
                    <div class="flex justify-between text-base font-medium text-gray-900 mt-8">
                        <p>Subtotal</p>
                        <p>
                            {{ cart.subTotal | formatPrice }}
                        </p>
                    </div>
                    <p class="mt-0.5 text-sm text-gray-500">
                        Shipping and Tax will be calculated during
                        checkout.
                    </p>
                    <div class="mt-6">
                        <button (click)="continueToCheckout()"
                                class="btn-primary flex w-full">
                            Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>