import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavMenuOption } from '../../../common/interfaces';

@Component({
    selector: 'vsf-mobile-menu-overlay',
    templateUrl: './mobile-menu-overlay.component.html',
    styleUrls: ['./mobile-menu-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('expandCollapse', [
          state('collapsed', style({ height: '0', overflow: 'hidden' })),
          state('expanded', style({ height: '*' })),
          transition('collapsed <=> expanded', animate('300ms ease-in-out'))
        ])
    ]
})
export class MobileMenuOverlayComponent {
    @Input() isOpen = false;
    @Input() navMenu: NavMenuOption[] = [];
    @Input() orderStatusHref: string = '';

    @Output() onClose = new EventEmitter<void>();
    @Output() onNavigate = new EventEmitter<NavMenuOption>();
    @Output() onContactUs = new EventEmitter<void>();

    closeMenu(): void {
        this.onClose.emit();
    }

    handleNavigation(item: NavMenuOption): void {
        this.onNavigate.emit(item);
        this.closeMenu();
    }

    handleContactUs(): void {
        this.onContactUs.emit();
        this.closeMenu();
    }

    handleOrderStatus(): void {
        if (this.orderStatusHref) {
            this.closeMenu();
            setTimeout(() => {
                window.location.href = this.orderStatusHref;
            }, 150);
        }
    }
}
