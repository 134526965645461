import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CartService } from '../../providers/cart/cart.service';
import { Asset } from '../../../common/generated-types';
import { ButtonData, HeroSectionData, KeySellingPointData, MarketingBannerData } from '../../../common/interfaces';
import { SwiperContainer } from 'swiper/element';
import Swiper from 'swiper';
import { extractStoreUrl } from '../../../common/utils/extract-store-url';

@Component({
    selector: 'vsf-marketing-banner',
    templateUrl: './marketing-banner.component.html',
    styleUrls: ['./marketing-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingBannerComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() marketingBanner: MarketingBannerData;
    @Input() isMobile: boolean | null;

    @ViewChild('leftMediaDesktopSwiper') leftMediaDesktopSwiper: ElementRef<SwiperContainer>;
    @ViewChild('leftMediaMobileSwiper') leftMediaMobileSwiper: ElementRef<SwiperContainer>;
    @ViewChild('rightMediaDesktopSwiper') rightMediaDesktopSwiper: ElementRef<SwiperContainer>;
    @ViewChild('rightMediaMobileSwiper') rightMediaMobileSwiper: ElementRef<SwiperContainer>;
    @ViewChild('belowMediaMultipleSwiper') belowMediaMultipleSwiper: ElementRef<SwiperContainer>;
    @ViewChild('belowMediaSingleSwiper') belowMediaSingleSwiper: ElementRef<SwiperContainer>;
    @ViewChild('mediaOnlySwiper') mediaOnlySwiper: ElementRef<SwiperContainer>;
    @ViewChild('titledMediaSwiper') titledMediaSwiper: ElementRef<SwiperContainer>;
    @ViewChild('rightSlideSwiper') rightSlideSwiper: ElementRef<SwiperContainer>;
    @ViewChild('leftSlideSwiper') leftSlideSwiper: ElementRef<SwiperContainer>;
    @ViewChild('mobileSlideSwiper') mobileSlideSwiper: ElementRef<SwiperContainer>;
    private swiperInstance: Swiper;

    constructor(private cartService: CartService,
                private changeDetector: ChangeDetectorRef,
                private router: Router,
                private logger: NGXLogger,
                private route: ActivatedRoute,
                private el: ElementRef) { }

    type: string;
    medias: Asset[] = [];
    mobileMedias: Asset[] = [];
    mobileMediaPosition: string;
    bannerMedias: Asset[] = [];
    title: string;
    text: string;
    textColor: string;
    textAlignment: string;
    textWidth: string;
    textIndentation: string;
    leftRightPartRatio: number;
    shadowTransparency: number;
    backgroundMedia: Asset | null;
    backgroundMobileMedia: Asset | null;
    backgroundColor: string;
    button: ButtonData | null;
    buttonPosition: string;
    buttonLocationX: number;
    buttonLocationY: number;
    buttonPositionMobile: string;
    buttonLocationXMobile: number;
    buttonLocationYMobile: number;
    keyPointsLayoutType: string;
    keyPoints: KeySellingPointData[] = [];
    heroSection: HeroSectionData;
    slidesPerView: number;
    inFlight: boolean = false;
    baseUrl = '';
    readyToDisplay$ = new BehaviorSubject<boolean>(false);
    mediaContainerWidth: number;
    textContainerWidth: number;
    private subscription = new Subscription();
    backgroundMediaHeight = 400;
    backgroundMediaWidth = 0;
    backgroundMobileMediaHeight = 0;
    backgroundMobileMediaWidth = document.documentElement.clientWidth;
    mediasHeight = 400;
    mediasWidth = 540;

    ngOnInit(): void {
        this.baseUrl = extractStoreUrl(this.route.snapshot);
        const marketingBanner = this.marketingBanner;
        if(marketingBanner) {
            this.type = marketingBanner.type;
            this.medias = marketingBanner.medias;
            this.mobileMedias = marketingBanner.mobileMedias;
            this.mobileMediaPosition = marketingBanner.mobileMediaPosition;
            this.title = marketingBanner.title;
            this.text = marketingBanner.text;
            this.textColor = marketingBanner.textColor;
            this.textAlignment = marketingBanner.textAlignment;
            this.textWidth = marketingBanner.textWidth*100 + '%';
            this.textIndentation = marketingBanner.textIndentation + 'px';
            this.leftRightPartRatio = marketingBanner.leftRightPartRatio || 0.5;
            this.shadowTransparency = marketingBanner.shadowTransparency || 1.0;
            this.backgroundMedia = marketingBanner.backgroundMedia;
            this.backgroundMobileMedia = marketingBanner.backgroundMobileMedia;
            this.backgroundColor = marketingBanner.backgroundColor;
            this.button = marketingBanner.button;
            this.buttonPosition = marketingBanner.buttonPosition;
            this.buttonLocationX = marketingBanner.buttonLocationX;
            this.buttonLocationY = marketingBanner.buttonLocationY;
            this.buttonPositionMobile = marketingBanner.buttonPositionMobile;
            this.buttonLocationXMobile = marketingBanner.buttonLocationXMobile;
            this.buttonLocationYMobile = marketingBanner.buttonLocationYMobile;
            this.keyPointsLayoutType = marketingBanner.keyPointsLayoutType;
            this.keyPoints = marketingBanner.keyPoints as KeySellingPointData[];
            this.heroSection = marketingBanner.heroSection;
            this.bannerMedias = (this.isMobile && this.mobileMedias?.length > 0) ? this.mobileMedias : this.medias;
            this.slidesPerView = Math.min(this.medias.length, 3);
        }
    }

    ngOnChanges() {
        if(this.swiperInstance) {
            this.swiperInstance.update();
            this.changeDetector.detectChanges();
        }
    }


    ngAfterViewInit() {
        if(this.type === 'left-media-right-text') {
            if(this.isMobile) {
                this.swiperInstance = new Swiper(this.leftMediaMobileSwiper.nativeElement);
            } else if(this.isMobile === false) {
                this.swiperInstance = new Swiper(this.leftMediaDesktopSwiper.nativeElement);
            }
        } else if(this.type === 'left-text-right-media') {
            if(this.isMobile) {
                this.swiperInstance = new Swiper(this.rightMediaMobileSwiper.nativeElement);
            } else if(this.isMobile === false) {
                this.swiperInstance = new Swiper(this.rightMediaDesktopSwiper.nativeElement);
            }
        }  else if(this.type === 'below-media-multiple') {
            if(this.isMobile) {
                this.swiperInstance = new Swiper(this.belowMediaMultipleSwiper.nativeElement);
            } else if(this.isMobile === false) {
                this.swiperInstance = new Swiper(this.belowMediaSingleSwiper.nativeElement);
            }
        } else if(this.type === 'media-only') {
            if(this.isMobile !== null) {
                this.swiperInstance = new Swiper(this.mediaOnlySwiper.nativeElement);
            }
        } else if(this.type === 'titled-media') {
            if(this.isMobile !== null) {
                this.swiperInstance = new Swiper(this.titledMediaSwiper.nativeElement);
            }
        } else if(this.type === 'left-media-slide-with-background' || this.type === 'right-media-slide-with-background') {
            if(this.isMobile) {
                this.swiperInstance = new Swiper(this.mobileSlideSwiper.nativeElement);
            } else if(this.isMobile === false) {
                this.swiperInstance = this.type === 'left-media-slide-with-background' ?
                    new Swiper(this.leftSlideSwiper.nativeElement) :
                    new Swiper(this.rightSlideSwiper.nativeElement);
            }
        }
        this.readyToDisplay$.next(true);
        this.changeDetector.detectChanges();
        setTimeout(() => {
            this.calculateSwiperSize();
        });
    }

    calculateWidths() {
        const totalRatio = 1.0;
        let textWidthPercentage = 50;
        let mediaWidthPercentage = 50;
        if (this.type === 'left-media-right-text' || this.type ==='left-media-slide-with-background') {
            mediaWidthPercentage = this.leftRightPartRatio * 100;
            textWidthPercentage = (totalRatio - this.leftRightPartRatio) * 100;
        } else if (this.type === 'left-text-right-media' || this.type ==='right-media-slide-with-background') {
            textWidthPercentage = this.leftRightPartRatio * 100;
            mediaWidthPercentage = (totalRatio - this.leftRightPartRatio) * 100;
        }
        return { text: `${textWidthPercentage}%`, media: `${mediaWidthPercentage}%` };
    }

    getMediaContainerClass(position: string): string {
        switch (position) {
            case 'top':
                return 'align-top';
            case 'bottom':
                return 'align-bottom';
            case 'center':
            default:
                return 'align-center';
        }
    }

    getButtonStyles() {
        const position = this.isMobile ? this.buttonPositionMobile : this.buttonPosition;
        const locationX = (this.isMobile ? this.buttonLocationXMobile : this.buttonLocationX) || 0.5;
        const locationY = (this.isMobile ? this.buttonLocationYMobile : this.buttonLocationY) || 0.5;
        if (position === 'custom') {
          return {
            position: 'absolute',
            left: `${locationX * 100}%`,
            top: `${locationY * 100}%`,
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'auto',
            zIndex: 10,
          };
        } else {
          return {};
        }
    }

    buttonClick() {
        this.logger.debug('Button clicked', this.button);
        if(this.button?.enabled && this.button?.actionType) {
            if(this.button.actionType === 'navigate-to') {
                if(this.button.navigateType === 'navigate-to-product') {
                    this.router.navigate([`${this.baseUrl}/dp`, this.button.targetProduct?.slug]);
                } else if(this.button.navigateType === 'navigate-to-collection') {
                    this.router.navigate([`${this.baseUrl}/gp`, this.button.targetCollection?.slug]);
                } else if(this.button.navigateType === 'navigate-to-page') {
                    this.router.navigate([this.button.targetPage]);
                } else if(this.button.navigateType === 'navigate-to-website') {
                    window && window.open(this.button.targetPage, '_blank');
                }
            } else if(this.button.actionType === 'add-variant-to-cart') {
                this.addToCartAndOpenDrawer(this.button.selectedVariantId);
            }
        }
    }

    addToCartAndOpenDrawer(productVariantId: string) {
        this.inFlight = true;
        this.changeDetector.markForCheck();

        this.subscription.add(
          this.cartService.addToCartAndOpenDrawer(productVariantId).subscribe({
            next: () => {
              this.inFlight = false;
              this.changeDetector.markForCheck();
            },
            error: err => {
              this.inFlight = false;
              this.logger.error('Error in addToCartAndOpenDrawer', err);
              this.changeDetector.markForCheck();
            },
          }),
        );
    }

    // calculate the width of the media container
    private calculateSwiperSize() {
        const mediaContainer = this.el.nativeElement.querySelector('.media-container');
        if (!mediaContainer) {
            this.logger.warn('Media container not found');
            return { width: 540, height: 400 }; // Default fallback sizes
        }

        const rect = mediaContainer.getBoundingClientRect();
        if (this.isMobile) {
            this.backgroundMobileMediaHeight = rect.height;
            this.backgroundMobileMediaWidth = rect.width;
        } else {
            this.mediasWidth = rect.width;
            this.mediasHeight = rect.height;
            this.backgroundMediaWidth = rect.width;
            this.backgroundMediaHeight = rect.height;
        }
        // detect changes
        this.changeDetector.detectChanges();
    }

}
