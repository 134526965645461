<div *ngIf="cart$ | async as cart;" class="mr-4">
    <button class="text-[#2E3742] font-bold relative hover:text-primary-100"
            (click)="toggle.emit()"
            title="cart">
        <svg width="18" height="20" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-1 icon-size">
            <path d="M2.28877 9.82474C2.37765 9.06932 3.01787 8.5 3.7785 8.5H18.2215C18.9821 8.5 19.6224 9.06932 19.7112 9.82474L21.2406 22.8247C21.3455 23.7165 20.6488 24.5 19.7509 24.5H2.24909C1.3512 24.5 0.654451 23.7165 0.759362 22.8247L2.28877 9.82474Z" stroke="#2E3742"/>
            <path d="M5.5 6C5.5 2.96243 7.96243 0.5 11 0.5C14.0376 0.5 16.5 2.96243 16.5 6V8.5H5.5V6Z" stroke="#2E3742"/>
        </svg>
        <span class="absolute -top-2 -right-4 bg-[#29319C] text-white rounded-full w-5 h-5 flex items-center justify-center text-xs"
              [class.empty]="cart.quantity === 0"
              [class.cart-changed]="cartChangeIndication$ | async">{{ cart.quantity }}</span>
    </button>
</div>
