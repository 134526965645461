import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

import {
    Collection,
    GetProductAllDetailQuery,
    GetProductAllDetailQueryVariables,
    StoreSite,
    HeroSectionData
} from '../../../common/generated-types';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { DataService } from '../../../core/providers/data/data.service';
import { StateService } from '../../../core/providers/state/state.service';

import { GET_PRODUCT_ALL_DETAIL } from '../../../common/graphql/documents.graphql';
import { ActiveService } from '../../../core/providers/active/active.service';
import { NGXLogger } from 'ngx-logger';
import { CombinedAnalyticsService } from '../../../core/providers/analytics/combined-analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { setCanonicalURL, removeCanonicalURL } from '../../../common/utils/canonical-url';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { CollectionGroupData, ColorConfig, FeaturedCollectionData,  } from '../../../common/interfaces';
import { extractStoreUrl } from '../../../common/utils/extract-store-url';

type Variant = NonNullable<GetProductAllDetailQuery['product']>['variants'][number];

declare let document: Document & typeof globalThis;

@Component({
    selector: 'vsf-product-showcase',
    templateUrl: './product-showcase.component.html',
    styleUrls: ['./product-showcase.component.scss'],
})
export class ProductShowcaseComponent implements OnInit, OnDestroy {

    product: GetProductAllDetailQuery['product'];
    qtyInCart: { [id: string]: number; } = {};
    selectedVariant: Variant;
    qty = 1;
    breadcrumbs: Collection['breadcrumbs'] | null = null;
    inFlight = false;
    inStock = true;
    @ViewChild('addedToCartTemplate', {static: true})
    private addToCartTemplate: TemplateRef<any>;
    private sub: Subscription;
    private orderSub: Subscription;
    selectedVariantMSRP: number | null = null;
    discountPercentage: number | null = null;
    isMobile = true;
    baseUrl = '';
    currentCanonicalURL: string | null = null;
    heroSection: HeroSectionData | null = null;
    colorConfig: ColorConfig | null = null;
    collectionListForShopByGroupList: CollectionGroupData[] = [];
    featuredCollectionDataList: FeaturedCollectionData[] = [];
    crossSellingFeaturedCollection: FeaturedCollectionData | null;
    storeSite: StoreSite | null = null;

    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private dataService: DataService,
                private stateService: StateService,
                private activeService: ActiveService,
                private route: ActivatedRoute,
                private router: Router,
                private titleService: Title,
                private metaService: Meta,
                private changeDetector: ChangeDetectorRef,
                private analyticsService: CombinedAnalyticsService,
                private logger: NGXLogger) {
    }

    ngOnInit() {
        this.checkViewport();
        this.baseUrl = extractStoreUrl(this.route.snapshot);
        const lastCollectionSlug$ = this.stateService.select(state => state.lastCollectionSlug);
        const productSlug$ = this.route.paramMap.pipe(
            map(paramMap => paramMap.get('slug')),
            filter(notNullOrUndefined),
        );
        const variantId$ = this.route.queryParamMap.pipe(
            map(params => params.get('variantid')),
        );

        this.sub = combineLatest([productSlug$, variantId$]).pipe(
            switchMap(([slug, variantId]) => {
                return this.dataService.query<GetProductAllDetailQuery, GetProductAllDetailQueryVariables>(GET_PRODUCT_ALL_DETAIL, {
                    slug,
                });
            }),
            map(data => data.product),
            withLatestFrom(lastCollectionSlug$, variantId$),
        ).subscribe(([product, lastCollectionSlug, variantId]) => {
            if (!product) {
                // Redirect to homepage if no valid product is found
                this.router.navigate([`${this.baseUrl}/`]);
                return;
            }
            this.product = product;
            if (variantId) {
                const variant = product.variants.find(v => v.id === variantId);
                if (variant) {
                    this.selectedVariant = variant;
                } else {
                    this.selectedVariant = product.variants[0];
                }
            } else {
                this.selectedVariant = product.variants[0];
            }
            const canonicalURL = `${this.baseUrl}/dp/${product.slug}`;
            setCanonicalURL(canonicalURL, this.platformId);
            this.currentCanonicalURL = canonicalURL;
            try {
                this.selectItemEvent(this.product);
            } catch (e) {
                this.logger.error('Error in select ItemEvent', e);
            }

            this.logger.debug(`[productDetails]isMobile:${this.isMobile} product:${JSON.stringify(this.product)}`);
            this.heroSection = this.product?.heroSection as HeroSectionData || null;
            this.storeSite = this.product.storeSite as StoreSite || null;
            this.colorConfig = safeJSONParse<ColorConfig>(this.storeSite?.colorConfig, this.logger);
            this.collectionListForShopByGroupList = this.storeSite?.collectionGroupDataList as CollectionGroupData[] || [];

            const featuredCollections = this.storeSite?.featuredCollectionDataList as FeaturedCollectionData[] || null;
            this.crossSellingFeaturedCollection = (featuredCollections?.length > 0) ? featuredCollections[0] : null;
            if(!variantId) {
                this.selectedVariant = product.variants[0];
            }
            this.inStock = (this.selectedVariant.stockLevel !== 'OUT_OF_STOCK');
            this.updateMSRP();
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { variantid: this.selectedVariant.id },
                queryParamsHandling: 'merge',
            });
            const collection = this.getMostRelevantCollection(product.collections as Collection[], lastCollectionSlug);
            this.breadcrumbs = collection ? collection.breadcrumbs : [];
            this.changeDetector.detectChanges();
        });

        this.orderSub = this.activeService.activeOrder$.subscribe(order => {
            this.qtyInCart = {};
            for (const line of order?.lines ?? []) {
                this.qtyInCart[line.productVariant.id] = line.quantity;
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.orderSub) {
            this.orderSub.unsubscribe();
        }
        this.removeMetadata();
        if (this.currentCanonicalURL) {
            removeCanonicalURL(this.currentCanonicalURL, this.platformId);
            this.currentCanonicalURL = null;
        }
    }

    @HostListener('window:resize', [])
    onResize() {
        this.checkViewport();
    }

    private checkViewport() {
        if(isPlatformBrowser(this.platformId)) {
            const mobileIndicator = document.getElementById('mobile-indicator');
            if(mobileIndicator && window) {
                this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
            }
        }
    }

    private removeMetadata() {
        this.titleService.setTitle(`${this.storeSite?.name? this.storeSite.name : ''}`);
        this.metaService.removeTag('name="description"');
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:description"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('name="twitter:card"');
        this.metaService.removeTag('name="twitter:title"');
        this.metaService.removeTag('name="twitter:description"');
        this.metaService.removeTag('name="twitter:image"');
    }

    selectItemEvent(product: any) {
        if(product) {
            this.analyticsService.viewItem(product);
        }
    }

    viewCartFromNotification(closeFn: () => void) {
        this.stateService.setState('cartDrawerOpen', true);
        closeFn();
    }

    updateMSRP() {
        if (this.selectedVariant && this.selectedVariant.customFields?.MSRP && this.selectedVariant.customFields.MSRP !== 0) {
            this.selectedVariantMSRP = this.selectedVariant.customFields.MSRP;
            const price = this.selectedVariant.price;
            const msrp = this.selectedVariantMSRP;
            if (msrp && msrp > price) {
                this.discountPercentage = ((msrp - price) / msrp) * 100;
            } else {
                this.discountPercentage = null;
            }
        } else {
            this.selectedVariantMSRP = null;
            this.discountPercentage = null;
        }
    }

    onVariantChange(event: { variant: Variant }) {
        this.selectedVariant = event.variant;
        this.inStock = (this.selectedVariant.stockLevel !== 'OUT_OF_STOCK');
        this.updateMSRP();
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { variantid: this.selectedVariant.id },
            queryParamsHandling: 'merge',
        });
    }

    /**
     * If there is a collection matching the `lastCollectionId`, return that. Otherwise return the collection
     * with the longest `breadcrumbs` array, which corresponds to the most specific collection.
     */
    private getMostRelevantCollection(collections: Collection[], lastCollectionSlug: string | null) {
        const lastCollection = collections.find(c => c.slug === lastCollectionSlug);
        if (lastCollection) {
            return lastCollection;
        }
        return collections.slice().sort((a, b) => {
            if (a.breadcrumbs.length < b.breadcrumbs.length) {
                return 1;
            }
            if (a.breadcrumbs.length > b.breadcrumbs.length) {
                return -1;
            }
            return 0;
        })[0];
    }

}
