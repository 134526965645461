import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { catchError, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { DataService } from '../data/data.service';
import { TransitionToAddingItemsMutation } from '../../../common/generated-types';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { TRANSITION_TO_ADDING_ITEMS } from '../../../checkout/components/checkout-process/checkout-process.graphql';
import { NGXLogger } from 'ngx-logger';
import { ActiveService } from '../active/active.service';

@Injectable({
  providedIn: 'root'
})
export class OrderStateService {
  constructor(
    private router: Router,
    private dataService: DataService,
    private activeService: ActiveService,
    private logger: NGXLogger
  ) {
    this.subscribeToRouteChanges();
  }

  orderState$: Observable<string | undefined>;

  private subscribeToRouteChanges() {
    const initialUrl = this.router.url;
    const initialSegments = this.extractSegments(initialUrl);
    const routeChanges$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd).urlAfterRedirects || (event as NavigationEnd).url),
      map(url => this.extractSegments(url)),
      startWith(initialSegments)
    );
    const orderState$ = this.activeService.activeOrder$.pipe(map(activeOrder => activeOrder?.state));
    const combined$ = combineLatest([routeChanges$, orderState$]).pipe(
      filter(([segments, orderState]) => {
        const { baseSegment, lastSegment } = segments;
        return (
          orderState === 'ArrangingPayment' &&
          (baseSegment !== 'checkout' ||
          !(['payment', 'confirmation', 'shipping', 'payment?error=payment_failed'].includes(lastSegment) || lastSegment.includes('payment_intent')))
        );
      }),
      switchMap(() => {
        return this.dataService.mutate<TransitionToAddingItemsMutation>(TRANSITION_TO_ADDING_ITEMS).pipe(
          take(1),
          switchMap((result) => {
            const transitionResult = result.transitionOrderToState;
            switch (transitionResult?.__typename) {
              case 'Order':
                return of(transitionResult);
              case 'OrderStateTransitionError':
                this.logger.error('Order state transition error:', transitionResult.message);
                return EMPTY;
              default:
                this.logger.error('Unexpected result from TransitionToAddingItemsMutation');
                return EMPTY;
            }
          }),
          catchError((error) => {
            this.logger.error('Error transitioning order state:', error);
            return EMPTY;
          })
        );
      })
    );
    combined$.subscribe();
  }

  private extractSegments(url: string): { baseSegment: string; lastSegment: string } {
    const urlSegments = url.split('/').filter(segment => segment !== '');
    let baseSegment = '';
    let lastSegment = '';

    if (urlSegments[0] === 'store' && urlSegments.length > 2) {
      // URL is '/store/:store/...'
      baseSegment = urlSegments[2]; // Could be 'checkout' or another section
      lastSegment = urlSegments[urlSegments.length - 1];
    } else {
      // URL is '/checkout/...'
      baseSegment = urlSegments[0];
      lastSegment = urlSegments[urlSegments.length - 1];
    }

    return { baseSegment, lastSegment };
  }
}
