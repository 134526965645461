import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AddressFragment, CountryFragment, OrderAddressFragment } from '../../../common/generated-types';

@Component({
    selector: 'vsf-address-form',
    templateUrl: './address-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnChanges {

    @Input() availableCountries: CountryFragment[];
    @Input() address: OrderAddressFragment | AddressFragment;

    addressForm: UntypedFormGroup;
    availableStates: {code: string, name: string}[] = [];

    statesByCountry: { [countryCode: string]: { code: string, name: string }[] } = {
        'US': [
            {code: 'AL', name: 'Alabama'},
            {code: 'AK', name: 'Alaska'},
            {code: 'AZ', name: 'Arizona'},
            {code: 'AR', name: 'Arkansas'},
            {code: 'CA', name: 'California'},
            {code: 'CO', name: 'Colorado'},
            {code: 'CT', name: 'Connecticut'},
            {code: 'DE', name: 'Delaware'},
            {code: 'FL', name: 'Florida'},
            {code: 'GA', name: 'Georgia'},
            {code: 'HI', name: 'Hawaii'},
            {code: 'ID', name: 'Idaho'},
            {code: 'IL', name: 'Illinois'},
            {code: 'IN', name: 'Indiana'},
            {code: 'IA', name: 'Iowa'},
            {code: 'KS', name: 'Kansas'},
            {code: 'KY', name: 'Kentucky'},
            {code: 'LA', name: 'Louisiana'},
            {code: 'ME', name: 'Maine'},
            {code: 'MD', name: 'Maryland'},
            {code: 'MA', name: 'Massachusetts'},
            {code: 'MI', name: 'Michigan'},
            {code: 'MN', name: 'Minnesota'},
            {code: 'MS', name: 'Mississippi'},
            {code: 'MO', name: 'Missouri'},
            {code: 'MT', name: 'Montana'},
            {code: 'NE', name: 'Nebraska'},
            {code: 'NV', name: 'Nevada'},
            {code: 'NH', name: 'New Hampshire'},
            {code: 'NJ', name: 'New Jersey'},
            {code: 'NM', name: 'New Mexico'},
            {code: 'NY', name: 'New York'},
            {code: 'NC', name: 'North Carolina'},
            {code: 'ND', name: 'North Dakota'},
            {code: 'OH', name: 'Ohio'},
            {code: 'OK', name: 'Oklahoma'},
            {code: 'OR', name: 'Oregon'},
            {code: 'PA', name: 'Pennsylvania'},
            {code: 'RI', name: 'Rhode Island'},
            {code: 'SC', name: 'South Carolina'},
            {code: 'SD', name: 'South Dakota'},
            {code: 'TN', name: 'Tennessee'},
            {code: 'TX', name: 'Texas'},
            {code: 'UT', name: 'Utah'},
            {code: 'VT', name: 'Vermont'},
            {code: 'VA', name: 'Virginia'},
            {code: 'WA', name: 'Washington'},
            {code: 'WV', name: 'West Virginia'},
            {code: 'WI', name: 'Wisconsin'},
            {code: 'WY', name: 'Wyoming'},
            {code: 'DC', name: 'District of Columbia'},
        ],
    };

    constructor(private formBuilder: UntypedFormBuilder) {
        this.addressForm = this.formBuilder.group({
            fullName: ['', Validators.required],
            company: '',
            streetLine1: ['', Validators.required],
            streetLine2: '',
            city: ['', Validators.required],
            province: '',
            postalCode: ['', Validators.required],
            countryCode: ['US', Validators.required],
            phoneNumber: '',
        });
        this.updateAvailableStates(this.addressForm.get('countryCode')?.value);
        this.addressForm.get('countryCode')?.valueChanges.subscribe(countryCode => {
            this.updateAvailableStates(countryCode);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('address' in changes && this.addressForm && this.address) {
            this.addressForm.patchValue(this.address, { });
        }
        const country = this.address && this.address.country;
        if (country && this.availableCountries) {
            if (country && typeof country !== 'string') {
                this.addressForm.patchValue({
                    countryCode: country.code,
                });
                this.updateAvailableStates(country.code);
            } else {
                const matchingCountry = this.availableCountries.find(c => c.name === country);
                if (matchingCountry) {
                    this.addressForm.patchValue({
                        countryCode: matchingCountry.code,
                    });
                    this.updateAvailableStates(matchingCountry.code);
                }
            }
        }
    }

    normalizeCountryCode(countryCode: string): string {
        const normalized = countryCode.toUpperCase().replace(/\./g, '');
        if (['USA', 'US', 'U.S', 'U.S.', 'U.S.A'].includes(normalized)) {
            return 'US';
        }
        return countryCode;
    }

    updateAvailableStates(countryCode: string) {
        const normalizedCountryCode = this.normalizeCountryCode(countryCode);
        this.availableStates = this.statesByCountry[normalizedCountryCode] || [];
        const provinceControl = this.addressForm.get('province');
        if (this.availableStates.length === 0) {
            provinceControl?.enable();
        } else {
            const currentProvince = provinceControl?.value;
            if (currentProvince) {
                // Check if the current province is in the list of available states
                const stateMatch = this.availableStates.find(
                    state => state.code === currentProvince || state.name === currentProvince
                );
                if (stateMatch) {
                    provinceControl.setValue(stateMatch.code);
                } else {
                    provinceControl.setValue('');
                }
            } else {
                provinceControl?.setValue('');
            }
            provinceControl?.enable();
        }
    }
}
