<div class="collection-carousel">
  <div *ngIf="groupName" class="group-name flex flex-col items-center">
    <p>Shop by {{ groupName }}</p>
    <div class="title-underline my-2"></div>
  </div>
  <div class="w-100 ml-1 mr-1">
    <ng-container *ngIf="useSwiper; else simpleList">
      <swiper-container
        appSwiper
        #swiper
        [slidesPerView]="slidesPerView"
        [pagination]="{ clickable: true }"
        spacenBetween="30"
        [centeredSlides]="false"
        [rewind]="true"
        class="flex object-cover"
      >
        <swiper-slide
          *ngFor="let collection of displayedCollections; trackBy: trackByCollectionId"
        >
          <div class="slide-item">
            <vsf-collection-card-v2
              [collection]="collection"
              [isMobile]="true"
              class="card"
            ></vsf-collection-card-v2>
          </div>
        </swiper-slide>
      </swiper-container>
    </ng-container>
    <ng-template #simpleList>
      <div class="flex flex-wrap justify-center items-center mx-auto">
        <div *ngFor="let collection of displayedCollections" class="w-auto m-2 flex-shrink-0">
          <vsf-collection-card-v2
            [collection]="collection"
            [isMobile]="true"
          ></vsf-collection-card-v2>
        </div>
      </div>
    </ng-template>
  </div>
</div>
