import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order, OrderLine, ReturnRequest } from '../../../common/generated-types';

@Component({
    selector: 'vsf-return-card',
    templateUrl: './return-card.component.html',
    styleUrls: ['./return-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ReturnCardComponent {
    @Input() returnRequest: ReturnRequest;
    @Input() order: Order;
    ngOnInit() {}

    get items(): Array<{ name: string; quantity: number }> {
        return (
            this.returnRequest?.items.map(item => ({
                name: this.order.lines.find((line:OrderLine) => line.id === item.orderLineId)?.productVariant.name ?? '',
                quantity: item.quantity,
            })) ?? []
        );
    }
}
