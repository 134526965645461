import { Routes } from '@angular/router';
import { StoreMainPageComponent } from './store-main-page/store-main-page.component';
import { StoreShoppingPageComponent } from './store-shopping-page/store-shopping-page.component';
import { ProductListComponent } from '../core/components/product-list/product-list.component';
import { AboutPageComponent } from '../core/components/about-page/about-page.component';
import { FaqPageComponent } from '../core/components/faq-page/faq-page.component';
import { ContactPageComponent } from '../core/components/contact-page/contact-page.component';
import { StorePolicyPageComponent } from './store-policy-page/store-policy-page.component';
import { OrderStatusFormComponent } from '../core/components/order-status-form/order-status-form.component';
import { CartPageComponent } from '../core/components/cart-page/cart-page.component';
import { ProductShowcaseComponent } from '../product/components/product-showcase/product-showcase.component';
import { CollectionMainPageComponent } from '../collection/collection-main-page/collection-main-page.component';


export const routes: Routes = [
    {
        path: 'store/:identifier',
        component: StoreMainPageComponent,
    },
    {
         path: 'store/:identifier/shopping',
         component: StoreShoppingPageComponent,
    },
    {
        path: 'store/:identifier/search',
        component: StoreShoppingPageComponent,
    },
    {
        path: 'store/:identifier/about',
        component: AboutPageComponent,
    },
    {
        path: 'store/:identifier/faq',
        component: FaqPageComponent,
    },
    {
        path: 'store/:identifier/contact',
        component: ContactPageComponent,
    },
    {
        path: 'store/:identifier/cart',
        component: CartPageComponent,
    },
    {
        path: 'store/:identifier/policy',
        loadChildren: () => import('../policy/policy.module').then(m => m.PolicyModule),
    },
    {
        path: 'store/:identifier/dp/:slug',
        component: ProductShowcaseComponent,
    },
    {
        path: 'store/:identifier/gp/:slug',
        component: CollectionMainPageComponent,
    },
    {
        path: 'store/:identifier/store-policy/:policy',
        component: StorePolicyPageComponent,
    },
    {
        path: 'store/:identifier/orderstatus',
        component: OrderStatusFormComponent,
    },
    {
        path: 'store/:identifier/account',
        loadChildren: () => import('../account/account.module').then(m => m.AccountModule),
    },
    {
        path: 'store/:identifier/checkout',
        loadChildren: () => import('../checkout/checkout.module').then(m => m.CheckoutModule),
    },
];
