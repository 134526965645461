import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReturnItem, GetOrderQuery, OrderLine } from '../../../common/generated-types';
import { DataService } from '../../../core/providers/data/data.service';
import { StateService } from '../../../core/providers/state/state.service';
import { Dialog } from '../../../core/providers/modal/modal-types';

type SelectionLine = { quantity: number; cancel: boolean };
type OrderLineInput = { orderLineId: string; quantity: number; };

export enum ReturnReasons {
    ArriveTooLate = "Item arrived too late",
    IncorrectItem = "Incorrect item received",
    ProductShippingDamaged = "Product and shipping box both damaged",
    ProductDamaged = "Product damaged , but shipping box OK",
    ItemNotMatchDesciption = "Item does not match description",
    NoLongerNeeded = "No longer needed",
    InaccurateWebDescription = "Inaccurate website description",
    MistakelyBought = "Bought by mistake",
    ItemDefective = "Item defective or doesn't work",
    MissingBrokenParts = "Missing or broken parts",
    NoApprovePurchase = "Didn't approve purchase",
    Other = "Other (please specify)"
}

export enum CancellationReasons {
    ChangeOfMind = "Change of mind",
    FoundBetterDeal = "Found better deal",
    PlacedWrongOrder = "Placed wrong order",
    OrderErrors = "Order errors",
    BetterAlternatives = "Better alternatives",
    PersonalFinancialIssues = "Personal financial issues",
    UnsatisfactoryCustomerService = "Unsatisfactory customer service",
    Other = "Other (please specify)"
}

@Component({
    selector: 'vsf-return-request-modal',
    templateUrl: './return-request-modal.component.html',
    styleUrls: ['./return-request-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ReturnRequestModalComponent
    implements OnInit, Dialog<{ reason: string; returnItems: ReturnItem[] }>{
    order: GetOrderQuery['orderByCode'];
    returnableItems: { [lineId: string]: number };
    cancellableItems: { [lineId: string]: number };
    type: string;
    resolveWith: (result?: { reason: string; returnItems: ReturnItem[] }) => void;
    isSigned: boolean;
    reason: string;
    lineQuantities: { [lineId: string]: SelectionLine };

    returnReasonValues = Object.values(ReturnReasons);
    otherReturnReasonValue: string = ReturnReasons.Other;
    selectedReturnReason: string = '';
    otherReturnReason: string = '';

    cancellationReasonValues = Object.values(CancellationReasons);
    otherCancellationReasonValue: string = CancellationReasons.Other;
    selectedCancellationReason: string = '';
    otherCancellationReason: string = '';

    constructor(private dataService: DataService,
                private stateService: StateService,
                private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        this.stateService.select(state => state.signedIn).subscribe((isSigned) => {
            this.isSigned = isSigned;
        });
        this.lineQuantities = this.order?.lines.reduce(
            (result, line) => ({
                ...result,
                [line.id]: {
                    quantity: 0,
                    refund: true,
                    cancel: false,
                },
            }),
            {},
        )??{};
    }

    onRefundQuantityChange(orderLineId: string, quantity: number) {
        const selectionLine = this.lineQuantities[orderLineId];
        if (selectionLine) {
            const previousQuantity = selectionLine.quantity;
            if (quantity === 0) {
                selectionLine.cancel = false;
            } else if (previousQuantity === 0 && quantity > 0) {
                selectionLine.cancel = true;
            }
            selectionLine.quantity = quantity;
        }
    }
    maxRefundableQuantity(orderLineId: string): number {
        if(this.type === 'Return') {
            return this.returnableItems[orderLineId] ?? 0;
        } else if (this.type === 'Cancellation') {
            return this.cancellableItems[orderLineId] ?? 0;
        }
        return 0;
    }

    onReasonChange() {
        if(this.type === 'Return' && this.selectedReturnReason !== ReturnReasons.Other) {
            this.otherReturnReason = '';
        } else if(this.type === 'Cancellation' && this.selectedCancellationReason !== CancellationReasons.Other) {
            this.otherCancellationReason = '';
        }
    }

    getReturnReason(): string {
        if (this.type === 'Return' && this.selectedReturnReason === ReturnReasons.Other) {
            this.reason = `Other: ${this.otherReturnReason}`;
        } else if (this.type === 'Cancellation' && this.selectedCancellationReason === CancellationReasons.Other) {
            this.reason = `Other: ${this.otherCancellationReason}`;
        } else if (this.type === 'Return') {
            this.reason = this.selectedReturnReason;
        } else if (this.type === 'Cancellation') {
            this.reason = this.selectedCancellationReason;
        }
        return this.reason;
    }

    select() {
        const refundLines = this.getOrderLineInput(() => true);
        this.resolveWith({
            reason: this.getReturnReason(),
            returnItems: refundLines.map((line: OrderLineInput) => ({ orderLineId: line.orderLineId, quantity: line.quantity })),
        });

    }

    cancel() {
        this.resolveWith();
    }

    getOrderLineInput(filterFn: (line: SelectionLine) => boolean): OrderLineInput[] {
        return Object.entries(this.lineQuantities)
            .filter(([orderLineId, line]) => 0 < line.quantity && filterFn(line))
            .map(([orderLineId, line]) => ({
                orderLineId,
                quantity: line.quantity,
            }));
    }

    isQuantitySelected(): boolean {
        return Object.values(this.lineQuantities).some(line => line.quantity > 0);
    }
}
