import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  Asset,
  GetActiveOrderQuery,
  GetChannelInfoQuery,
  GetCollectionHeaderInfoQuery,
  GetCollectionHeaderInfoQueryVariables,
  GetProductHeaderInfoQuery,
  GetProductHeaderInfoQueryVariables,
  GetStoreSiteHeaderInfoQuery,
  GetStoreSiteHeaderInfoQueryVariables
} from './common/generated-types';
import { GET_COLLECTION_HEADER_INFO, GET_PRODUCT_HEADER_INFO, GET_STORESITE_HEADER_INFO } from './common/graphql/documents.graphql';
import { DataService } from './core/providers/data/data.service';
import { StateService } from './core/providers/state/state.service';
import { OrderStateService } from './core/providers/order-state/order-state.service';
import { register } from 'swiper/element/bundle';
import { GET_ACTIVE_ORDER } from './core/providers/active/active.service.graphql';
import { NGXLogger } from 'ngx-logger';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { notNullOrUndefined } from './common/utils/not-null-or-undefined';
import { safeJSONParse } from './common/utils/safe-json-parser';
import { ColorConfig, HeroSectionData, LogoSectionData, NavMenuOption, PolicyContentData, SnsInfo } from './common/interfaces';
import { Meta, Title } from '@angular/platform-browser';
import { stripHtml } from './common/utils/strip-html';
import { GET_CHANNEL_INFO } from './common/graphql/documents.graphql';

register();
declare global {
  interface Window {
    displayPreferenceModal: () => void;
  }
}

interface StoreInfo {
  name: string;
  identifier: string;
  sellerChannelId: string;
  email: string;
  snsInfo: SnsInfo | null;
}

interface PageMessages {
  headerMessage: string;
  disclaimer: string;
}

interface StorePolicies {
  policyContentList: PolicyContentData[];
  useReturnPolicy: boolean;
  useShippingPolicy: boolean;
}

interface UIState {
  isForHomepage: boolean;
  isForStore: boolean;
  isSearchBarExpanded: boolean;
  isNavExpanded: boolean;
  isMobile: boolean | null;
  displayHeaderMenu: boolean;
  displayFooterMenu: boolean;
}

interface NavigationItem {
  name: string;
  type: string;
  href?: string | null;
  href$?: Observable<string> | null;
}

interface Navigation {
  services: NavigationItem[];
  orders: NavigationItem[];
  company: NavigationItem[];
}

@Component({
  selector: 'vsf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  // Observables
  cartDrawerVisible$: Observable<boolean>;
  mobileNavVisible$: Observable<boolean>;
  slugOrIdentifier$: Observable<{ type: string; slug: string }>;
  returnRequestForm$: Observable<boolean>;
  channelInfo$: Observable<GetChannelInfoQuery['activeChannel']>;
  orderStatusHref$: Observable<string>;
  readyToDisplay$ = new BehaviorSubject<boolean>(false);
  // Direct properties
  productName: string = '';
  storeInfo: StoreInfo = {
    name: '',
    identifier: '',
    sellerChannelId: '',
    email: '',
    snsInfo: null,
  };
  pageMessages: PageMessages = {
    headerMessage: '',
    disclaimer: '',
  };
  storeColor: ColorConfig | null = null;
  storeLogoConfig: LogoSectionData = {
    pageTitle: '',
    displayHeaderMenu: true,
    displayFooterMenu: true,
    normalLogo: null,
    smallLogo: null,
    nameLogo: null,
    displayName: true,
    browserIcon: null,
  };
  storeNavMenu: NavMenuOption[] = [];
  storePolicies: StorePolicies = {
    policyContentList: [],
    useReturnPolicy: false,
    useShippingPolicy: false,
  };
  uiState: UIState = {
    isForHomepage: false,
    isForStore: false,
    displayHeaderMenu: true,
    displayFooterMenu: true,
    isSearchBarExpanded: false,
    isNavExpanded: false,
    isMobile: null,
  };
  navigation: Navigation;

  private static readonly supportEmail = 'support@dealomega.com';
  // vimeo fullscreen preview
  fullscreenPreviewOpen$: Observable<number | null>;
  vimeoVideoId: number | null = null;

  constructor(
    private router: Router,
    private stateService: StateService,
    private dataService: DataService,
    private changeDetector: ChangeDetectorRef,
    private orderStateService: OrderStateService,
    private titleService: Title,
    private metaService: Meta,
    private logger: NGXLogger,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.checkViewport();
    this.channelInfo$ = this.dataService.query<GetChannelInfoQuery>(GET_CHANNEL_INFO)
      .pipe(map(result => result.activeChannel));
    this.cartDrawerVisible$ = this.stateService.select(state => state.cartDrawerOpen);
    this.mobileNavVisible$ = this.stateService.select(state => state.mobileNavMenuIsOpen);

    const navigationEndEvents$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    );

    this.returnRequestForm$ = navigationEndEvents$.pipe(
      map((event: RouterEvent) => event.url.includes('return-form?info='))
    );

    navigationEndEvents$.pipe(
      map((event: RouterEvent) => event.url === '/')
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe((isHomePage) => {
      this.uiState.isForHomepage = isHomePage;
    });

    navigationEndEvents$.pipe(
      map((event: NavigationEnd) => this.extractSlugOrIdentifier(event.urlAfterRedirects || event.url))
    ).pipe(
      switchMap(({ type, slug, isRoot }) => {
        this.logger.debug(`type:${type}, slug:${slug}`);
        if (type === 'product') {
          return this.dataService.query<GetProductHeaderInfoQuery, GetProductHeaderInfoQueryVariables>(
            GET_PRODUCT_HEADER_INFO, { slug }
          ).pipe(take(1), map(result => ({
            type,
            storeInfo: result.product?.storeSite,
            productInfo: result.product
          })));
        } else if (type === 'collection') {
          return this.dataService.query<GetCollectionHeaderInfoQuery, GetCollectionHeaderInfoQueryVariables>(
            GET_COLLECTION_HEADER_INFO, { slug }
          ).pipe(take(1), map(result => ({
            type,
            storeInfo: result.collection?.storeSite
          })));
        } else if (type === 'store') {
          return this.dataService.query<GetStoreSiteHeaderInfoQuery, GetStoreSiteHeaderInfoQueryVariables>(
            GET_STORESITE_HEADER_INFO, { identifier: slug }
          ).pipe(take(1), map(result => ({
            type,
            storeInfo: result.storeSite,
            isRoot
          })));
        } else {
          return of(null);
        }
      })
    ).subscribe((result: any) => {
      this.logger.debug(`headerInfo:${JSON.stringify(result)}`);
      if( result && result.type === 'product' ) {
        this.updateProductMetadata(result.productInfo);
      } else if (result && result.type === 'store' && result.isRoot) {
        this.updateStoreMetadata(result.storeInfo);
      }
      if (result && result.storeInfo) {
        this.updateHeaderInfo(
          result.type,
          result.storeInfo,
          result.productInfo?.name,
          result.productInfo?.customFields?.ProductDisclaimer
        );
      } else {
        // If no specific data is found, fall back to using the channel's custom fields
        this.channelInfo$.pipe(takeUntil(this.destroy$)).subscribe(channel => {
          this.updateHomePageMetadata(channel);
          this.updateHeaderInfo('channel', channel, result?.name ? result.name : null);
        });
      }

      const storePolicyUrlPrefix = this.uiState.isForStore ? `/store/${this.storeInfo.identifier}` : '';
      this.navigation = {
        services: [
          { name: 'Contact Us', type: 'action', href: null as null },
        ],
        orders: [
          { name: 'Shipping', type: 'route', href: this.storePolicies.useShippingPolicy?`${storePolicyUrlPrefix}/store-policy/shipping`: `${storePolicyUrlPrefix}/policy/shipping`},
          { name: 'Order Status', type: 'route', href$: null as null | Observable<string> },
          { name: 'Returns & Exchanges', type: 'route', href: this.storePolicies.useReturnPolicy?`${storePolicyUrlPrefix}/store-policy/returning`:`${storePolicyUrlPrefix}/policy/return`},
        ],
        company: [
          { name: 'Payment', type: 'route', href: `${storePolicyUrlPrefix}/policy/payment` },
          { name: 'Terms of Service', type: 'route', href: `${storePolicyUrlPrefix}/policy/terms` },
          { name: 'Privacy Policy', type: 'route', href: `${storePolicyUrlPrefix}/policy/privacy` },
          { name: 'Cookie Policy', type: 'route', href: `${storePolicyUrlPrefix}/policy/cookie` },
          { name: 'CA Transparency in Supply Chain Act', type: 'route', href: `${storePolicyUrlPrefix}/policy/ca-transparency-supplychain-act` },
          { name: 'Accessibility', type: 'route', href: `${storePolicyUrlPrefix}/policy/accessibility` },
        ],
      };
      this.setupOrderStatusHref();
    });

    this.loadClarityScript(environment.clarityTrackingId);

    // get the vimeo video id from the state
    this.fullscreenPreviewOpen$ = this.stateService.select(state => state.fullscreenPreviewOpen)
      .pipe(switchMap(id => {
        this.vimeoVideoId = id;
        return of(id);
    }));


  }
  
  private extractSlugOrIdentifier(url: string): { type: string, slug: string, isRoot: boolean } {
    this.logger.debug(`url:${url}`);
    url = url.split('?')[0];
    const productMatch = url.match(/^(?:\/store\/[^\/]+)?\/dp\/([^\/]+)$/);
    if (productMatch) return { type: 'product', slug: productMatch[1], isRoot: false };

    const collectionMatch = url.match(/^(?:\/store\/[^\/]+)?\/gp\/([^\/?]+)(\/|$|\?)/);
    if (collectionMatch) return { type: 'collection', slug: collectionMatch[1], isRoot: false };

    const storeMatch = url.match(/^\/store\/([^\/?]+)(\/|$|\?)/);
    if (storeMatch) return { type: 'store', slug: storeMatch[1], isRoot: url.split('/').pop() === storeMatch[1] };

    return { type: 'home', slug: '', isRoot: false };
  }

  private updateHeaderInfo(type: string, data: any, name?: string, disclaimer?: string): void {
    this.logger.info(`${type.charAt(0).toUpperCase() + type.slice(1)} Header Info:${JSON.stringify(data)} Name:${name} Disclaimer:${disclaimer}`);
    this.productName = name || '';
    if (type === 'channel') {
      this.setChannelData(data);
    } else if (type === 'product' || type === 'collection' || type === 'store') {
      this.setStoreData(data);
    }
    this.uiState.isForStore = !!this.storeInfo.identifier;
    this.uiState.displayHeaderMenu = (this.storeLogoConfig?.displayHeaderMenu === false) ? false : true;
    this.uiState.displayFooterMenu = (this.storeLogoConfig?.displayFooterMenu === false) ? false : true;
    this.storeLogoConfig?.browserIcon && this.setFavicon(this.storeLogoConfig.browserIcon);
    if(this.storeLogoConfig?.pageTitle) {
      this.titleService.setTitle(this.storeLogoConfig?.pageTitle);
    }
    if(disclaimer) {
      this.pageMessages.disclaimer = disclaimer;
    }
    this.readyToDisplay$.next(true);
    this.changeDetector.markForCheck();
  }

  private setChannelData(data: GetChannelInfoQuery['activeChannel']): void {
    // If type is 'channel', we directly use the data from channel.customFields
    this.storeInfo = {
      name: '',
      identifier: '',
      sellerChannelId: '',
      email: '',
      snsInfo: null,
    };
    this.pageMessages.headerMessage = data?.customFields?.HeaderMessage || '';
    this.pageMessages.disclaimer = data?.customFields?.StoreDisclaimer || '';
    if(data?.logoSection) {
      this.storeLogoConfig.normalLogo = data.logoSection.normalLogo as Asset;
      this.storeLogoConfig.smallLogo = data.logoSection.smallLogo as Asset || null;
      this.storeLogoConfig.nameLogo = data.logoSection.nameLogo as Asset || null;
      this.storeLogoConfig.browserIcon = data.logoSection.browserIcon as Asset || null;
      this.storeLogoConfig.displayName = (data.logoSection.displayName !== undefined) ? data.logoSection.displayName : true;
    } else {
      this.storeLogoConfig.normalLogo = data?.customFields?.StoreLogo as Asset || null;
      this.storeLogoConfig.smallLogo = data?.customFields?.StoreSmallLogo as Asset || null;
      this.storeLogoConfig.nameLogo = data?.customFields?.StoreNameLogo as Asset || null;
      this.storeLogoConfig.browserIcon = data?.customFields?.StoreBrowserIcon as Asset || null;
      this.storeLogoConfig.displayName = true;
    }
    this.storeColor = null;
    this.storeNavMenu = [];
    this.storePolicies ={
      policyContentList: [],
      useReturnPolicy: false,
      useShippingPolicy: false,
    }
  }

  private setStoreData(data: GetStoreSiteHeaderInfoQuery['storeSite']): void {
    this.storeInfo.name = data?.name || '';
    this.storeInfo.identifier = data?.identifier || '';
    this.storeInfo.email = data?.seller?.customFields?.email || '';
    this.storeInfo.sellerChannelId = data?.sellerChannelId || '';
    this.pageMessages.headerMessage = data?.headerMessage || '';
    this.storeColor = safeJSONParse<ColorConfig>(data?.colorConfig, this.logger) || null;
    this.storeInfo.snsInfo = safeJSONParse<SnsInfo>(data?.snsInfoSection, this.logger) || null;
    const navMenuJson = data?.navigationMenu || [];
    this.storeNavMenu = navMenuJson
      .map((menuJson: string) => safeJSONParse<NavMenuOption>(menuJson, this.logger))
      .filter(notNullOrUndefined);
    const policyContentListJson = data?.policyContentList || [];
    if (policyContentListJson.length > 0) {
      this.storePolicies.policyContentList = policyContentListJson
        .map((jsonString:string) => safeJSONParse<PolicyContentData>(jsonString, this.logger))
        .filter(notNullOrUndefined);
      this.storePolicies.useReturnPolicy = !!this.storePolicies.policyContentList.find(
        (item: PolicyContentData) => item.type === 'return-policy'
      );
      this.storePolicies.useShippingPolicy = !!this.storePolicies.policyContentList.find(
        (item: PolicyContentData) => item.type === 'shipping-policy'
      );
      this.pageMessages.disclaimer = this.storePolicies.policyContentList.find(
        (item: PolicyContentData) => item.type === 'store-disclaimer'
      )?.content || '';
      this.logger.debug(
        `storePolicies: ${this.storePolicies.policyContentList}, useReturnPolicy: ${this.storePolicies.useReturnPolicy}`
      );
    }
    const logoSection = data?.logoSectionData;
    if (logoSection) {
      this.storeLogoConfig.displayHeaderMenu = (logoSection.displayHeaderMenu === false) ? false : true;
      this.storeLogoConfig.displayFooterMenu = (logoSection.displayFooterMenu === false) ? false : true;
      this.storeLogoConfig.normalLogo = logoSection.normalLogo as Asset;
      this.storeLogoConfig.smallLogo = logoSection.smallLogo as Asset;
      this.storeLogoConfig.nameLogo = logoSection.nameLogo as Asset;
      this.storeLogoConfig.browserIcon = logoSection.browserIcon as Asset;
      this.storeLogoConfig.displayName = (logoSection.displayName !== undefined) ? logoSection.displayName : true;
      this.storeLogoConfig.pageTitle = logoSection.pageTitle || '';
      this.logger.debug(
        `storeLogo: ${this.storeLogoConfig.normalLogo}, storeSmallLogo: ${this.storeLogoConfig.smallLogo}, storeNameLogo: ${this.storeLogoConfig.nameLogo}`
      );
    }
  }

  private updateProductMetadata(product: any): void {
    if(!product) return;
    const heroSection = product?.heroSection;
    const storeUrl = product.storeSite?.identifier ? `/store/${product.storeSite.identifier}` : '';
    // Set the page title
    this.titleService.setTitle(`${product.name}`);
    // Set meta description
    const description = product.customFields?.OGDescription ? product.customFields?.OGDescription :
      ( product.customFields?.ProductSubtitle ? product.customFields?.ProductSubtitle :
      ( stripHtml( product.description, this.platformId )));
    this.metaService.updateTag({ name: 'description', content: description });
    // Set Open Graph meta tags
    this.metaService.updateTag({ property: 'og:title', content: product.name });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:type', content: 'product' });
    this.metaService.updateTag({ property: 'og:url', content: `${environment.shopHost}${storeUrl}/product/${product.slug}` });
    // Set Twitter Card meta tags
    this.metaService.updateTag({ name: 'twitter:title', content: product.name });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
    // Set image meta tags
    const fp = product.featuredAsset?.focalPoint ? `&fpx=${product.featuredAsset.focalPoint.x}&fpy=${product.featuredAsset.focalPoint.y}` : '';
    const imageUrl = product.customFields.OGImage ? (`${product.customFields.OGImage.preview}?preset='full'&format=webp`) :
      (( heroSection?.medias && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') ?
        (`${heroSection?.medias[0].preview}?preset='full'&format=webp`) :
        ( product.featuredAsset ? (`${product.featuredAsset?.preview}?w=600&h=315${fp}&format=webp`) : ''));
    if (imageUrl) {
        this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaService.updateTag({ property: 'og:image', content: imageUrl });
        this.metaService.updateTag({ property: 'og:image:alt', content: product.name });
        this.metaService.updateTag({ name: 'twitter:image', content: imageUrl });
        this.metaService.updateTag({ name: 'twitter:image:alt', content: product.name });
    } else {
        this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    }
    this.logger.debug('Updated metadata for product:', product.name);
  }

  private updateStoreMetadata(store: any): void {
    if(!store) return;
    const heroSection = store?.heroSectionData;
    const logoConfig = safeJSONParse<LogoSectionData>(store?.logoSection, this.logger);
    const titleHtml = heroSection?.title  || store.name;
    const descriptionHtml = heroSection?.subtitle || '';
    const title = stripHtml(titleHtml, this.platformId);
    const description = stripHtml(descriptionHtml, this.platformId);
    // Set the page title
    if(logoConfig?.pageTitle) {
      this.titleService.setTitle(logoConfig?.pageTitle);
    } else {
      this.titleService.setTitle(`${title}`);
    }
    // Set meta description
    this.metaService.updateTag({ name: 'description', content: description });
    // Set Open Graph meta tags
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({ property: 'og:url', content: `${environment.shopHost}/store/${store.identifier}` });
    // If StoreHeroImages is available and is an array, use the first image
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') {
        this.metaService.updateTag({ property: 'og:image', content: `${heroSection?.medias[0].preview}?preset=large` });
    }
    // Set Twitter Card meta tags
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
    // If StoreHeroImages is available and is an array, use the first image
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') {
        this.metaService.updateTag({ name: 'twitter:image', content: heroSection?.medias[0].preview });
    }
    // If there's a hero video, you might want to include it in the metadata
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'VIDEO') {
      this.metaService.updateTag({ property: 'og:video', content: heroSection?.medias[0].source });
    }
    this.logger.debug('Updated metadata for store:', store.identifier);
  }

  private updateHomePageMetadata(channel: GetChannelInfoQuery['activeChannel']) {
    if (!channel) return;
    const heroSection = channel?.heroSection;
    const titleHtml = heroSection?.title || '';
    const descriptionHtml = heroSection?.subtitle || '';
    const title = stripHtml(titleHtml, this.platformId) || 'Welcome to DealOmega';
    const description = stripHtml(descriptionHtml, this.platformId) || 'Discover amazing deals and products at DealOmega';
    // Set the page title
    this.titleService.setTitle(`${title}`);
    // Set meta description
    this.metaService.updateTag({ name: 'description', content: description });
    // Set Open Graph meta tags
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({ property: 'og:url', content: environment.shopHost });
    // If StoreHeroImages is available and is an array, use the first image
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') {
        this.metaService.updateTag({ property: 'og:image', content: `${heroSection?.medias[0].preview}?preset=large` });
    }
    // If there's a hero video, you might want to include it in the metadata
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'VIDEO') {
        this.metaService.updateTag({ property: 'og:video', content: heroSection?.medias[0].source });
    }
    // Set Twitter Card meta tags
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: description });
    // If StoreHeroImages is available and is an array, use the first image
    if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') {
      this.metaService.updateTag({ name: 'twitter:image', content: heroSection?.medias[0].preview });
    }
    this.logger.debug('Updated metadata for Homepage');
  }

  private setupOrderStatusHref(): void {
    const baseUrl = this.uiState.isForStore ? `/store/${this.storeInfo.identifier}` : '';
    this.storeInfo.identifier = this.storeInfo.identifier || '';
    this.orderStatusHref$ = this.stateService.select(state => state.signedIn).pipe(
      takeUntil(this.destroy$),
      map(isSignedIn => isSignedIn ? `${baseUrl}/account/orders` : `${baseUrl}/orderstatus`)
    );
    const orderStatusItem = this.navigation?.orders.find((item: NavigationItem) => item.name === 'Order Status');
    if (orderStatusItem) {
      orderStatusItem.href$ = this.orderStatusHref$;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkViewport();
  }

  private checkViewport() {
    if(isPlatformBrowser(this.platformId)){
      const mobileIndicator = document.getElementById('mobile-indicator');
      if(mobileIndicator && window) {
          this.uiState.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
      }
    }
  }

  setFavicon(asset: Asset) {
    const link: HTMLLinkElement | null = this.document.querySelector('#app-favicon');
    if (link) {
      link.href = asset.source;
    }
  }

  openCartDrawer() {
    this.readyToDisplay$.pipe(
      filter(ready => ready),
      take(1),
      switchMap(() => this.dataService.query<GetActiveOrderQuery>(GET_ACTIVE_ORDER, {}, 'network-only')),
      map(order => order?.activeOrder?.state),
      take(1)
    ).subscribe(orderState => {
      if (orderState !== null) {
        const baseUrl = this.uiState.isForStore ? `/store/${this.storeInfo.identifier}` : '';
        if (orderState === 'ArrangingPayment') {
          this.router.navigate([baseUrl, 'cart']);
        } else {
          this.stateService.setState('cartDrawerOpen', true);
        }
      }
    });
  }

  closeCartDrawer() {
    this.stateService.setState('cartDrawerOpen', false);
  }

  clickOnSearchButton() {
    this.uiState.isSearchBarExpanded = !this.uiState.isSearchBarExpanded;
    this.changeDetector.detectChanges();
  }

  clickOnNavButton() {
    this.uiState.isNavExpanded = !this.uiState.isNavExpanded;
    this.changeDetector.detectChanges();
  }

  toggleMobileMenu() {
    this.uiState.isNavExpanded = !this.uiState.isNavExpanded;
    if (this.uiState.isNavExpanded) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
    this.changeDetector.detectChanges();
  }

  closeMobileMenu() {
    this.uiState.isNavExpanded = false;
    document.body.style.overflow = '';
    this.changeDetector.detectChanges();
  }

  handleAction(actionName: string) {
    switch (actionName) {
      case 'Contact Us':
        if(this.uiState.isForStore) {
          window.location.href = `mailto:${this.storeInfo.email}`;
        } else {
          window.location.href = `mailto:${AppComponent.supportEmail}`;
        }
        break;
    }
  }

  scrollToSection(sectionId: string) {
    const element = this.document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  navigateToPage(navMenuOption: NavMenuOption) {
    this.logger.debug(`navigateToPage:${navMenuOption}`);
    switch(navMenuOption.type) {
      case 'home-page':
        this.router.navigate(['/store/', this.storeInfo.identifier]);
        break;
      case 'shopping-list':
        this.router.navigate(['/store/', this.storeInfo.identifier, 'shopping']);
        break;
      case 'about-page':
        this.router.navigate(['/store/', this.storeInfo.identifier, 'about']);
        break;
      case 'faq-page':
        this.router.navigate(['/store/', this.storeInfo.identifier, 'faq']);
        break;
      case 'contact-page':
        this.router.navigate(['/store/', this.storeInfo.identifier, 'contact']);
        break;
      case 'other-page':
        this.router.navigate([navMenuOption.url]);
        break;
      }
  }

  private loadClarityScript(trackingId: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const logger = this.logger;
    (function (c: any, l: Document, a: string, r: string, i: string) {
      c[a] = c[a] || function () {
        (c[a].q = c[a].q || []).push(arguments);
      };

      const t = l.createElement(r) as HTMLScriptElement;
      t.async = true;
      t.src = "https://www.clarity.ms/tag/" + i;

      const y = l.getElementsByTagName(r)[0];
      if (y && y.parentNode) {
        y.parentNode.insertBefore(t, y);
        logger.debug('loadClarityScript done');
      }
    })(window, document, "clarity", "script", trackingId);
  }
}


