<div class="card border rounded bg-white p-4 text-sm">
    <div class="card-header returnRequest-header">
        <div class="returnRequest-state">
            RMA: <strong>{{ returnRequest?.RMA }}</strong>
            <br>
            State: <strong>{{ returnRequest?.status }}</strong>
            <br>
            Updated On: <strong>{{ returnRequest?.updatedAt | date: 'medium' }}</strong>
        </div>
    </div>
    <div class="card-block">
        <div>
            Content:
            <div *ngFor="let item of items">
                <p><strong>{{ item.quantity }} x {{ item.name }}</strong></p>
            </div>
        </div>
    </div>
</div>
