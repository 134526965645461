import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Asset, GetStoreFaqInfoQuery, GetStoreFaqInfoQueryVariables } from '../../../common/generated-types';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../providers/data/data.service';
import { filter, map, take } from 'rxjs/operators';
import { GET_STORESITE_FAQ_INFO } from '../../../common/graphql/documents.graphql';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { isPlatformBrowser } from '@angular/common';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { ColorConfig, FaqPageData, QAData } from '../../../common/interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'vsf-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqPageComponent implements OnInit {
    identifier: string;
    faqPage: FaqPageData | null;
    colorConfig: ColorConfig | null;
    posterAsset: Asset;
    title: string;
    faqList: QAData[];
    showAll = false;
    defaultDisplayCount = 3;
    isMobile: boolean | null = null;
    readyToDisplay$ = new BehaviorSubject<boolean>(false);
  
    constructor(private logger: NGXLogger,
                private route: ActivatedRoute,
                private dataService: DataService,
                private changeDetectorRef: ChangeDetectorRef,
                @Inject(PLATFORM_ID) private platformId: object) { }
  
    ngOnInit(): void {
        this.checkViewport();
        this.logger.info('[FAQ page] init');
        this.route.paramMap.pipe(
            map(paramMap => paramMap.get('identifier')),
            filter(notNullOrUndefined),
            take(1),
        ).subscribe(identifier => {
            this.logger.info('[FAQ page] identifier:', identifier);
            this.dataService.query<GetStoreFaqInfoQuery, GetStoreFaqInfoQueryVariables>(GET_STORESITE_FAQ_INFO, {
                identifier,
            }).subscribe(result => {
                this.colorConfig = safeJSONParse<ColorConfig>(result?.storeSite?.colorConfig, this.logger);
                this.faqPage = result?.storeSite?.faqPageData as FaqPageData;
                if(this.faqPage) {
                    this.logger.info('[FAQ page] content:', this.faqPage);
                    this.posterAsset = this.faqPage.posterAsset || this.posterAsset;
                    this.title = this.faqPage.title || this.title;
                    this.faqList = this.faqPage.qaList.map(item => {
                        return {
                            question: item?.question,
                            answer: item?.answer,
                            open: false,
                        };
                    });
                    if(this.faqList.length >= this.defaultDisplayCount) {
                        this.showAll = false;
                    }
                }
                this.readyToDisplay$.next(true);
                this.changeDetectorRef.markForCheck();
            });
        });
    }

    @HostListener('window:resize', [])
    onResize() {
      this.checkViewport();
    }

    private checkViewport() {
      if(isPlatformBrowser(this.platformId)){
        const mobileIndicator = document.getElementById('mobile-indicator');
        if(mobileIndicator && window) {
            this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
        }
      }
    }

    // Toggle the open state of the FAQ item
    toggleFaq(faq: QAData): void {
        faq.open = !faq.open;
    }

    // Get visible FAQs based on the 'showAll' state
    getVisibleFaqs(): QAData[] {
        return this.showAll ? this.faqList : this.faqList.slice(0, this.defaultDisplayCount);
    }

    // Show all questions when button is clicked
    showAllQuestions(): void {
        this.showAll = true;
    }
}
