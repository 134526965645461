import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order, FulfillmentFragment, OrderLine } from '../../../common/generated-types';
import { DataService } from '../../../core/providers/data/data.service';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'vsf-tracking-card',
    templateUrl: './tracking-card.component.html',
    styleUrls: ['./tracking-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TrackingCardComponent {
    @Input() fulfillment: FulfillmentFragment;
    @Input() order: Order;
    trackingUrl: string;

    constructor(private dataService: DataService,
                private logger: NGXLogger) {}

    ngOnInit() {
        this.logger.debug(`[fulfillment]${this.fulfillment?.state} ${JSON.stringify(this.fulfillment?.lines)}`);
        if(this.fulfillment && this.fulfillment.method) {
            switch(this.fulfillment.method.toLowerCase()) {
                case 'ups':
                    this.trackingUrl = this.fulfillment?.trackingCode ? `https://www.ups.com/track?tracknum=${this.fulfillment.trackingCode}&requester=ST/` : '';
                    break;
                case 'usps':
                    this.trackingUrl = this.fulfillment?.trackingCode ? `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${this.fulfillment.trackingCode}` : '';
                    break;
                case 'fedex':
                    this.trackingUrl = this.fulfillment?.trackingCode ? `https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=${this.fulfillment.trackingCode}` : '';
                    break;
                case 'dhl':
                    this.trackingUrl = this.fulfillment?.trackingCode ? `https://www.dhl.com/en/express/tracking.html?AWB=${this.fulfillment.trackingCode}&brand=DHL` : '';
                    break;
                default:
                    this.trackingUrl = '';
                    break;
            }
        }
     }

    get items(): Array<{ name: string; quantity: number }> {
        return (
            this.fulfillment?.lines.map(row => ({
                name: this.order?.lines.find((line: OrderLine) => line.id === row.orderLineId)?.productVariant.name ?? '',
                quantity: row.quantity,
            })) ?? []
        );
    }
}
