import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { GetCollectionQuery, GetCollectionQueryVariables } from '../../../common/generated-types';
import { AssetPreviewPipe } from '../../pipes/asset-preview.pipe';
import { DataService } from '../../../core/providers/data/data.service';
import { GET_COLLECTION } from '../../../common/graphql/documents.graphql';
import { ColorConfig } from '../../../common/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { extractStoreUrl } from '../../../common/utils/extract-store-url';

@Component({
    selector: 'vsf-collection-card',
    templateUrl: './collection-card.component.html',
    styleUrls: ['./collection-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCardComponent implements OnInit, OnChanges {
    @Input() collection: NonNullable<GetCollectionQuery['collection']>;
    @Input() collectionId: string;
    @Input() colorConfig: ColorConfig | null;

    backgroundImage: SafeStyle;
    showOverlay: boolean = false;
    collectionBaseUrl = '';
    static readonly CARD_IMAGE_WIDTH = 200;
    static readonly CARD_IMAGE_HEIGHT = 500;

    constructor(private sanitizer: DomSanitizer,
                private dataService: DataService,
                private router: Router,
                private route: ActivatedRoute,
                private changeDetector: ChangeDetectorRef,
                private logger: NGXLogger,
    ) {}

    ngOnInit() {
        this.collectionBaseUrl = `${extractStoreUrl(this.route.snapshot)}/gp`;
        if(!this.collection && this.collectionId) {
            this.dataService.query<GetCollectionQuery, GetCollectionQueryVariables>(GET_COLLECTION, {
                id: this.collectionId,
            }).subscribe((result:any) => {
                this.collection = result.collection;
                this.changeDetector.markForCheck();
                this.logger.debug('[CollectionCardComponent] collection:', this.collection);
            });
        } else {
            this.logger.debug('[CollectionCardComponent] collection:', this.collection);
        }
    }

    ngOnChanges() {
        if (this.collection.featuredAsset) {
            const assetPreviewPipe = new AssetPreviewPipe();
            this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
                `url('${assetPreviewPipe.transform(
                    this.collection.featuredAsset,
                    CollectionCardComponent.CARD_IMAGE_WIDTH,
                    CollectionCardComponent.CARD_IMAGE_HEIGHT)}'
                )`,
            );
        }
    }

    navigateToCollectionPage() {
        this.router.navigate([this.collectionBaseUrl, this.collection.slug]);
    }
}
