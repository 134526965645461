<ng-template [ngIf]="(isMobile===false)">
<div class="customer-reviews flex flex-row">
    <div class="customer-say">
        <div>
            <p>Customer say</p>
        </div>
        <div class="quote-container">
          <div class="quote-left-container">
            <fa-icon icon="quote-left" class="quote-left"></fa-icon>
          </div>
          <p class="customer-say-content" [title]="customerSay">{{ customerSay }}</p>
          <div class="quote-right-container">
            <fa-icon icon="quote-right" class="quote-right"></fa-icon>
          </div>
        </div>
        <div>
            <small>A featured review from our customer.</small>
        </div>
    </div>
    <div class="z-10 nav-button">
      <fa-icon
        icon="chevron-left"
        class="cursor-pointer left-button p-2"
        (click)="swipeToPrev()">
      </fa-icon>
    </div>
    <div class="rounded-lg relative">
      <div class="reviews-list px-1" *ngIf="slidesPerView > 0">
        <swiper-container
          appSwiper
          #desktopReviewSwiper
          [slidesPerView]="slidesPerView"
          [spaceBetween]="20"
          [rewind]="true">
          <swiper-slide *ngFor="let review of reviews; let i = index" class="swiper-slide h-full">
            <div class="review-card px-4 py-2 border border-[#E7E9ED] rounded flex flex-col h-full">
              <p class="font-bold">{{ review.name?review.name:'Dealomega Customer' }}</p>
              <vsf-product-review-indicator
                class="mb-2"
                [inputScore]="review.rating"
                [displayReviewNumbers]="false">
              </vsf-product-review-indicator>
              <p class="font-bold review-summary" [title]="review.summary">{{ review.summary }}</p>
              <p #reviewContent [overflowCheck]=i class="mt-1 review-content"  [title]="review.content">
                {{ review.content }}
              </p>
              <span *ngIf="overflow[i]" (click)="openReviewDialog(review)" class="more-link">more</span>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
    <div class="z-10 nav-button">
      <fa-icon
        icon="chevron-right"
        class="cursor-pointer right-button p-2"
        (click)="swipeToNext()">
      </fa-icon>
    </div>
</div>
</ng-template>
<ng-template [ngIf]="isMobile">
<div class="customer-reviews flex flex-col">
  <div class="customer-say">
      <div>
          <p>Customer say</p>
      </div>
      <div class="quote-container">
        <div class="quote-left-container">
          <fa-icon icon="quote-left" class="quote-left"></fa-icon>
        </div>
        <p class="customer-say-content" [title]="customerSay">{{ customerSay }}</p>
        <div class="quote-right-container">
          <fa-icon icon="quote-right" class="quote-right"></fa-icon>
        </div>
      </div>
      <div>
          <small>A featured review from our customer.</small>
      </div>
  </div>
  <div>
    <swiper-container appSwiper #mobileReviewSwiper
                      [slidesPerView]="1.5" [spaceBetween]="20"
                      rewind="true"
                      class="flex-grow">
        <swiper-slide *ngFor="let review of reviews; let i = index" class="swiper-slide h-full">
          <div class="review-card px-4 py-2 shadow border border-[#E7E9ED] rounded flex flex-col h-full">
            <p class="font-bold">{{ review.name?review.name:'Dealomega Customer' }}</p>
            <vsf-product-review-indicator
              class="mb-2"
              [inputScore]="review.rating"
              [displayReviewNumbers]="false">
            </vsf-product-review-indicator>
            <p class="font-bold review-summary" [title]="review.summary">{{ review.summary }}</p>
            <p #reviewContent [overflowCheck]=i class="mt-1 review-content" [title]="review.content">
              {{ review.content }}
            </p>
            <span *ngIf="overflow[i]" (click)="openReviewDialog(review)" class="more-link">more</span>
          </div>
        </swiper-slide>
    </swiper-container>
  </div>
</div>
</ng-template>