<div *ngIf="collection">
    <a
        [routerLink]="[collectionBaseUrl, collection.slug]"
        class="block max-w-[300px] relative rounded-lg overflow-hidden hover:opacity-75 xl:w-auto"
        (mouseenter)="showOverlay = true" 
        (mouseleave)="showOverlay = false"
    >
        <span aria-hidden="true">
            <div class="collection-image">
                <img loading="lazy"
                     [src]="collection.featuredAsset | assetPreview:'small'"
                     alt="Collection Image"
                     style="pointer-events: none;"
                     class="w-full h-full object-cover aspect-[2/5]"/>
                <div *ngIf="showOverlay && collection.description && (isMobile===false)" class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80" [style.z-index]="2">
                    <div class="mb-10">
                        <p class="text-white px-4 hover-text"
                            [style.z-index]="3"
                            [style.line-height]="'20px'"
                            [style.font-size]="'16px'"
                            [style.display]="'-webkit-box'"
                            [style.-webkit-line-clamp]="15"
                            [style.-webkit-box-orient]="'vertical'"
                            [style.overflow]="'hidden'"
                            [style.text-overflow]="'ellipsis'"
                            [title]="stripHtml(collection.description)"
                            [innerHtml]="collection.description | safeHtml">
                        </p>
                    </div>
                </div> 
            </div>
        </span>
        <span
            aria-hidden="true"
            [style.z-index]="1"
            class="absolute w-full bottom-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
        ></span>
        <span class="absolute w-full bottom-8 mt-auto text-center text-white"
            [style.z-index]="1"
            [style.font-size]="'20px'"
            [style.font-weight]="600">
            {{ collection.name }}
        </span>
    </a>
</div>