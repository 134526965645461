import {
  Component,
  OnInit,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Collection } from '../../../common/generated-types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwiperContainer } from 'swiper/element';
import { isPlatformBrowser } from '@angular/common';
import { CollectionGroupData } from '../../../common/interfaces';

@Component({
  selector: 'vsf-collection-group-swiper',
  templateUrl: './collection-group-swiper.component.html',
  styleUrls: ['./collection-group-swiper.component.scss'],
})
export class CollectionSwiperComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  @Input() collectionGroup: CollectionGroupData;
  @Input() displayPagination: boolean = false;

  collectionsList: Collection[] = [];
  displayedCollections: Collection[] = [];
  itemsPerSlide: number = 5;
  slidesPerView: number = 1;
  selectedIndex = 0;
  totalSlides = 0;
  groupName: string;
  private destroy$ = new Subject();
  useSwiper: boolean = false;
  swiperConfig: any = {};

  private static readonly BREAKPOINT_CONFIG = [
    { query: '(max-width: 170px)', slidesPerView: 1 },
    { query: '(max-width: 320px)', slidesPerView: 1.5 },
    { query: '(max-width: 440px)', slidesPerView: 2.5 },
    { query: '(max-width: 590px)', slidesPerView: 3.5 },
    { query: '(max-width: 680px)', slidesPerView: 4.5 },
    { query: '(min-width: 681px)', slidesPerView: 5 },
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private changeDetector: ChangeDetectorRef,
              private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.groupName = this.collectionGroup?.groupName;
    this.displayedCollections = this.collectionGroup?.collections;

    if (isPlatformBrowser(this.platformId)) {
      const breakpointQueries = CollectionSwiperComponent.BREAKPOINT_CONFIG.map(config => config.query);
      this.breakpointObserver
        .observe(breakpointQueries)
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          const breakpoints = result.breakpoints;

          const matchedBreakpoint = CollectionSwiperComponent.BREAKPOINT_CONFIG.find(config => breakpoints[config.query]);
          if (matchedBreakpoint) {
            this.slidesPerView = matchedBreakpoint.slidesPerView;
          } else {
            this.slidesPerView = 5;
          }
          this.useSwiper = this.displayedCollections.length > this.slidesPerView;
          this.changeDetector.detectChanges();
      });
    }
  }

  ngAfterViewInit() {
    if (this.swiper?.nativeElement.swiper) {
      this.swiper.nativeElement.swiper.onAny((eventName: string) => this.slideEvent(eventName));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  slideEvent(eventName: string) {
    if (eventName === 'slideChange') {
      this.selectedIndex = this.swiper.nativeElement.swiper.activeIndex + 1;
    }
  }

  trackByCollectionId(index: number, item: any): string {
    return item.id;
  }
}
