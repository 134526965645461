import {gql} from 'apollo-angular';

export const CREATE_RETURN_REQUEST = gql`
    mutation CreateReturnRequest($input: CreateReturnRequestInput!) {
        requestReturn(input: $input) {
            id
            type
            RMA
            status
            items {
                orderLineId,
                quantity
            }
            reason
            updatedAt
        }
    }
`;

export const GET_RETURN_REQUESTS_BY_ORDER_ID = gql`
    query GetReturnRequestsByOrderId($options: ReturnRequestListOptions) {
        returnRequests(options: $options) {
            items {
                ... on ReturnRequest {
                    id
                    type
                    RMA
                    status
                    items {
                        orderLineId
                        quantity
                    }
                    reason
                    createdAt
                    updatedAt
                    seller {
                        name
                        customFields {
                            mailAddressTo
                        }
                    }
                }
            }
        }
    }
`;

export const GUEST_CREATE_RETURN_REQUEST = gql`
    mutation GuestCreateReturnRequest($input: CreateReturnRequestInput!) {
        guestRequestReturn(input: $input) {
            id
            RMA
            status
            items {
                orderLineId,
                quantity
            }
            reason
            updatedAt
        }
    }
`;

export const GUEST_GET_RETURN_REQUESTS = gql`
    query GuestGetReturnRequests {
        guestReturnRequests {
            items {
                ... on ReturnRequest {
                    id
                    RMA
                    type
                    status
                    items {
                        orderLineId
                        quantity
                    }
                    reason
                    createdAt
                    updatedAt
                    seller {
                        name
                        customFields {
                            mailAddressTo
                        }
                    }
                }
            }
            totalItems
        }
    }
`;