<div class="collections-carousel">
    <div class="collection-list-container">
      <vsf-collection-card *ngFor="let collection of displayedCollections; trackBy: trackByCollectionId"
        [collection]="collection"
        [colorConfig]="colorConfig">
      </vsf-collection-card>
    </div>
    <button class="arrow arrow-left" (click)="showPreviousItems()" *ngIf="startIndex > 0">
      <fa-icon icon="chevron-left"></fa-icon>
    </button>
    <button class="arrow arrow-right" (click)="showNextItems()" *ngIf="startIndex + itemsPerPage < collectionLength">
      <fa-icon icon="chevron-right"></fa-icon>
    </button>
</div>