import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Asset } from '../../../common/generated-types';
import { EmailSubscriptionData } from '../../../common/interfaces';
import { OmniSendService } from '../../providers/analytics/omnisend.service';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
    selector: 'vsf-email-subscription-form',
    templateUrl: './email-subscription-form.component.html',
    styleUrls: ['./email-subscription-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailSubscriptionFormComponent implements OnInit {
    @Input() emailSubscriptionData: EmailSubscriptionData;

    type: string;
    backgroundColor: string;
    title: string;
    subtitle: string;
    backgroundImage: Asset | null;
    shadowOpacity = 0;
    omnisendFormId: string;
    omnisendFormReady = false;
    private subscriptions = new Subscription();

    constructor(
        private logger: NGXLogger,
        private changeDetector: ChangeDetectorRef,
        private omnisenService: OmniSendService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    email: string = '';

    ngOnInit() {
        this.logger.info('Email Subscription Section', JSON.stringify(this.emailSubscriptionData));
        this.type = this.emailSubscriptionData?.type;
        this.backgroundColor = this.emailSubscriptionData?.backgroundColor;
        this.title = this.emailSubscriptionData?.title;
        this.subtitle = this.emailSubscriptionData?.subtitle;
        this.backgroundImage = this.emailSubscriptionData?.backgroundImage;
        this.shadowOpacity = (1.0-this.emailSubscriptionData?.shadowTransparency) || this.shadowOpacity;
        this.omnisendFormId = this.emailSubscriptionData?.omnisendFormId;
        if(this.type === 'omnisend' && isPlatformBrowser(this.platformId)) {
            const scriptLoadedSub = this.omnisenService.scriptLoaded$.subscribe({
                next: (isLoaded) => {
                    if (isLoaded) {
                        this.logger.info('Omnisend scripts loaded');
                        this.omnisendFormReady = true;
                        this.changeDetector.markForCheck();
                    }
                },
                error: (error) => {
                    this.logger.error('Error loading Omnisend scripts', error);
                    this.changeDetector.markForCheck();
                }
            });
            this.subscriptions.add(scriptLoadedSub);
        }
        this.changeDetector.markForCheck();
    }

    ngOnDestroy() {
        if (isPlatformBrowser(this.platformId)) {
            this.subscriptions.unsubscribe();
        }
    }

    onSubmit() {
        if (this.email) {
          this.logger.debug('Email submitted:', this.email);
        }
    }
}
