<dl class="border-t mt-6 border-gray-200 py-6 space-y-6">
    <div class="applied-coupon">
        <div *ngIf="cart.discounts && cart.discounts.length > 0">
            <p class="text-gray-600 text-sm">Your Discounts</p>
            <div *ngFor="let discount of cart.discounts" class="flex justify-between text-sm text-gray-900">
                <p>{{ discount.description }}</p>
                <p>{{ discount.amount | formatPrice }}</p>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-between">
        <dt class="text-sm">Subtotal</dt>
        <dd class="text-sm font-medium text-gray-900">{{ cart.subTotal | formatPrice }}</dd>
    </div>
    <hr>
    <!-- <div *ngIf="cart.shippingLines && cart.shippingLines.length > 0">
        <p class="text-gray-600 text-sm">Shipping</p>
        <div *ngFor="let line of cart.shippingLines" class="flex justify-between text-sm text-gray-900">
            <p>
                <span *ngIf="sellerInformationForShippingMethods && sellerInformationForShippingMethods[line.shippingMethod?.id]">
                    For {{ sellerInformationForShippingMethods[line.shippingMethod?.id] }}:
                </span>
                <strong>({{ line.shippingMethod.name }})</strong>
            </p>
            <p>{{ line.priceWithTax | formatPrice }}</p> 
        </div>
    </div> -->
    <div class="flex items-center justify-between">
        <dt class="text-sm">
            Shipping
        </dt>
        <dd class="text-sm font-medium text-gray-900">
            {{ cart.shippingWithTax | formatPrice }}
        </dd>
    </div>
    <hr>
    <div class="flex items-center justify-between">
        <!-- Conditionally display Tax value or 'calculate later' message -->
        <ng-container *ngIf="cart.state==='AddingItems' && (cart.subTotalWithTax-cart.subTotal) == 0; else taxValue">
            <dt class="text-sm">Tax(Calculate Later)</dt>
            <dd class="text-sm font-medium text-gray-900"> ---- </dd>
        </ng-container>
        <ng-template #taxValue>
            <dt class="text-sm">Estimated tax</dt>
            <dd class="text-sm font-medium text-gray-900">{{ (cart.subTotalWithTax-cart.subTotal)  | formatPrice }}</dd>
        </ng-template>
    </div>
    <div class="flex items-center justify-between border-t border-gray-200 pt-6">
        <dt class="text-base font-medium">Total</dt>
        <dd class="text-base font-medium text-gray-900">
            {{ cart.totalWithTax | formatPrice }}
        </dd>
    </div>
</dl>
