export enum ButtonActionType {
    NavigateTo = 'navigate-to',
    AddVariantToCart = 'add-variant-to-cart',
}

export enum ButtonPositionType {
    BelowText = 'below-text',
    Custom = 'custom',
}

export enum ButtonNavigationType {
    NavigateToProduct = 'navigate-to-product',
    NavigateToCollection = 'navigate-to-collection',
    NavigateToPage = 'navigate-to-page',
    NavigateToWebsite = 'navigate-to-website',
}

export enum HeroLayoutType {
    LeftTextFullMedia = 'left-text-full-media',
    RightTextFullMedia = 'right-text-full-media',
}

export enum MarketingBannerType {
    LeftMediaRightText = 'left-media-right-text',
    LeftTextRightMedia = 'left-text-right-media',
    TopTextBelowMedia = 'top-text-below-media',
    MediaOnly = 'media-only',
    TitledMedia = 'titled-media',
    KeyPointList = 'key-point-list',
    HeroSection = 'hero-section',
}

export enum AddToCartResult {
    SUCCESS,
    OUT_OF_STOCK,
    ERROR,
}

export enum CollectionItemType {
    Product = 'Product',
    ProductVariant = 'ProductVariant',
}
