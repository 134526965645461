import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ApolloModule } from 'apollo-angular';


import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { buildIconLibrary } from '../core/icon-library';
import { DefaultInterceptor } from '../core/providers/data/interceptor';
import { APOLLO_CLIENT_PROVIDER } from '../core/apollo-client-provider';
import { AssetSwiperGalleryComponent } from './components/asset-swiper-gallery/asset-swiper-gallery.component';
import { AssetSwiperGalleryV2Component } from './components/asset-swiper-gallery-v2/asset-swiper-gallery-v2.component';
import { ProductShowcaseComponent } from './components/product-showcase/product-showcase.component';
import { ProductShopNowBannerComponent } from './components/product-shop-now-banner/product-shop-now-banner.component';

import { ProductInfoComponent } from './components/product-info/product-info.component';
import { ReviewSectionComponent } from './components/review-section/review-section.component';
import { CoreModule } from '../core/core.module';

const DECLARATIONS = [
    AssetSwiperGalleryComponent,
    AssetSwiperGalleryV2Component,
    ProductShowcaseComponent,
    ProductShopNowBannerComponent,
    ProductInfoComponent,
    ReviewSectionComponent,
];

@NgModule({
    declarations: [DECLARATIONS],
    imports: [
        HttpClientModule,
        SharedModule,
        CoreModule,
        ApolloModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
        { provide: APP_BASE_HREF, useValue: environment.baseHref },
        APOLLO_CLIENT_PROVIDER,
    ],
})
export class ProductModule {
    constructor(library: FaIconLibrary) {
        buildIconLibrary(library);
    }
}
