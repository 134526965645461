import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_STORESITE_POLICY_LIST } from '../../common/graphql/documents.graphql';
import { GetStorePolicyListQuery, GetStorePolicyListQueryVariables } from '../../common/generated-types';
import { DataService } from '../../core/providers/data/data.service';
import { notNullOrUndefined } from '../../common/utils/not-null-or-undefined';
import { safeJSONParse } from '../../common/utils/safe-json-parser';
import { PolicyContentData } from '../../common/interfaces';
import { removeCanonicalURL, setCanonicalURL } from '../../common/utils/canonical-url';

@Component({
  selector: 'vsf-store-policy-page',
  templateUrl: './store-policy-page.component.html',
  styleUrls: ['./store-policy-page.component.scss'],
})
export class StorePolicyPageComponent implements OnInit, OnDestroy {
  policyContent: PolicyContentData | null = null;
  canonicalURL = '';
  identifier = '';

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private route: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private changeDetector: ChangeDetectorRef,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.logger.info('[Store Policy page] init');
    const routeInfo$ = combineLatest([
      this.route.paramMap,
      this.route.url
    ]).pipe(
      map(([params, urlSegments]) => {
        const identifier = params.get('identifier') || '';
        this.identifier = identifier;
        const policyTypePath = urlSegments[urlSegments.length - 1].path;
        this.identifier = identifier;
        return {
          identifier,
          policyTypePath
        };
      })
    );
    routeInfo$.subscribe(data => {
        this.logger.info('[Policy page] identifier:', data.identifier, 'type:', data.policyTypePath);
        this.canonicalURL = `/store/${this.identifier}/store-policy/${data.policyTypePath}`;
        setCanonicalURL(this.canonicalURL, this.platformId);
        this.dataService.query<GetStorePolicyListQuery, GetStorePolicyListQueryVariables>(GET_STORESITE_POLICY_LIST, {
          identifier: data?.identifier,
        }).subscribe(result => {
            const policyPageListJson = result.storeSite?.policyContentList || [];
            if(policyPageListJson?.length > 0) {
              const policyPageList = policyPageListJson?.map(jsonString => safeJSONParse<PolicyContentData>(jsonString, this.logger))
                .filter(notNullOrUndefined);
              this.policyContent = policyPageList.find((policyPage: PolicyContentData) => policyPage?.path === data?.policyTypePath) || null;
              if(this.policyContent) {
                this.changeDetector.markForCheck();
              } else {
                this.logger.error('Failed to find policy content for path:', data?.policyTypePath);
                this.router.navigate(['/store', this.identifier]);
              }
            }
        });
    });
  }

  ngOnDestroy(): void {
      removeCanonicalURL(this.canonicalURL, this.platformId);
  }
}