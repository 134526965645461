import { isPlatformBrowser } from '@angular/common';

export function stripHtml(html: string, platformId: object): string {
    if (isPlatformBrowser(platformId)) {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    } else {
        return html.replace(/<[^>]*>/g, '');
    }
}