<div *ngIf="cart" class="flow-root">
    <ul role="list" class="divide-y divide-gray-200">
        <ng-container *ngFor="let line of cart.lines; trackBy: trackByFn">
            <li class="py-6 flex">
                <div class="flex-shrink-0 w-24 flex flex-col">
                    <div class="h-24 border border-gray-200 rounded-md overflow-hidden">
                        <img [src]="(line.featuredAsset || line.productVariant.featuredAsset) | assetPreview:'thumb'"
                             [alt]="line.productVariant.name"
                             style="pointer-events: none;"
                             class="rounded w-full h-full aspect-[1/1]"/>
                    </div>
                    <div *ngIf="enableReview" class="mt-2 wide-button">
                        <button *ngIf="!reviewStatusMap.get(line.productVariant.product.id)"
                                class="btn border rounded bg-yellow-400 text-white hover:bg-blue-400 w-full text-xs cursor-pointer"
                                (click)="openInputProductReviewForm(line)">
                            Write a review
                        </button>
                        <button *ngIf="reviewStatusMap.get(line.productVariant.product.id) && reviewStatusMap.get(line.productVariant.product.id).status!=='loading'" 
                                class="btn border rounded bg-grey-700 text-black w-full text-xs"
                                disabled
                                >
                            Reviewed
                        </button>
                    </div>
                </div>
                <div class="ml-4 flex-1 flex flex-col">
                    <div>
                        <div class="flex justify-between text-base font-medium text-gray-900">
                            <p>
                                <a [routerLink]="[baseProductUrl, line.productVariant.product.slug]">
                                    {{ line.productVariant.name }}
                                </a>
                            </p>
                            <p class="ml-4">
                                {{ (line.unitPrice*line.quantity) | formatPrice }}
                            </p>
                        </div>
                    </div>
                    <div class="flex-1 flex items-center justify-between text-sm text-gray-600">
                        <div class="flex space-x-4">
                            <button class="btn btn-sm"
                                    *ngIf="canAdjustQuantities"
                                    (click)="decrement(line)">
                                <fa-icon aria-label="Remove 1" icon="minus"></fa-icon>
                            </button>
                            <div class="qty">{{ line.quantity }}</div>
                            <button class="btn btn-sm"
                                    *ngIf="canAdjustQuantities"
                                    (click)="increment(line)">
                                <fa-icon aria-label="Add 1" icon="plus"></fa-icon>
                            </button>
                        </div>
                        <div class="total">
                            <div class="">
                                {{ line.unitPrice | formatPrice }}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ng-container>
    </ul>
</div>
