import {gql} from 'apollo-angular';
import { CART_FRAGMENT, FULFILLMENT_FRAGMENT, ORDER_ADDRESS_FRAGMENT } from '../../../common/graphql/fragments.graphql';

export const GET_GUEST_ORDER_STATUS = gql`
    query GuestOrderStatus($orderCode: String!, $email: String!) {
        guestOrderStatus(orderCode: $orderCode, email: $email) {
            ...Cart
            shippingAddress {
                ...OrderAddress
            }
            fulfillments {
                ...Fulfillment
            }
        }
    }
    ${CART_FRAGMENT}
    ${ORDER_ADDRESS_FRAGMENT}
    ${FULFILLMENT_FRAGMENT}
`;
