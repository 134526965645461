import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';

import {
    AdjustItemQuantityMutation, AdjustItemQuantityMutationVariables,
    Discount,
    GetActiveOrderQuery,
    Order,
    RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables
} from '../../../common/generated-types';
import { DataService } from '../../providers/data/data.service';
import { NotificationService } from '../../providers/notification/notification.service';
import { StateService } from '../../providers/state/state.service';

import { ADJUST_ITEM_QUANTITY, REMOVE_ITEM_FROM_CART } from './cart-drawer.graphql';
import { ActiveService } from '../../providers/active/active.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { CombinedAnalyticsService } from '../../providers/analytics/combined-analytics.service';

@Component({
    selector: 'vsf-cart-drawer',
    templateUrl: './cart-drawer.component.html',
    styleUrls: ['./cart-drawer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('expandCollapse', [
          state('collapsed', style({ height: '0', overflow: 'hidden' })),
          state('expanded', style({ height: '*' })),
          transition('collapsed <=> expanded', animate('300ms ease-in-out'))
        ])
    ]
})
export class CartDrawerComponent implements OnInit {
    @Input() visible = false;
    @Input() storeIdentifier: string = '';
    @Output() close = new EventEmitter<void>();
    @ViewChild('overlay') private overlayRef: ElementRef<HTMLDivElement>;

    cart$: Observable<GetActiveOrderQuery['activeOrder']>;
    isEmpty$: Observable<boolean>;
    constructor(private dataService: DataService,
                private stateService: StateService,
                private activeService: ActiveService,
                private notificationService: NotificationService,
                private router: Router,
                private analyticsService: CombinedAnalyticsService,
                private logger: NGXLogger ) {}

    ngOnInit() {
        this.cart$ = merge(
            this.stateService.select(state => state.activeOrderId),
            this.stateService.select(state => state.signedIn),
        ).pipe(
            switchMap(() => this.activeService.activeOrder$),
            shareReplay(1),
        );
        this.isEmpty$ = this.cart$.pipe(
            map(cart => !cart || cart.lines.length === 0 || cart.active === false),
        );
    }

    beginCheckoutEvent(order: GetActiveOrderQuery['activeOrder'], callback:()=>void) {
        try {
            this.analyticsService.beginCheckout(order as Order, callback);
        } catch (err){
            this.logger.error('Error in beginCheckoutEvent', err);
            callback();
        }
    }

    calculateCurrentTotalDiscount(order: GetActiveOrderQuery['activeOrder']): number {
        let totalDiscount = 0;
        if(order && order.discounts && order.discounts.length > 0) {
            order.discounts.forEach((discount: Discount) => {
                if(discount?.amountWithTax) {
                    totalDiscount += discount?.amountWithTax || 0;
                }
            });
        }
        return totalDiscount;
    }

    async continueToCheckout() {
        this.cart$.pipe(take(1)).subscribe(async (order) => {
            if(order) {
                this.beginCheckoutEvent(order, () => {
                    this.router.navigate([this.storeIdentifier ? `/store/${this.storeIdentifier}` : '', 'checkout']);
                    this.close.emit();
                });
            }
        });
    };

    setQuantity(event: { itemId: string; quantity: number; }) {
        if (0 < event.quantity) {
            this.adjustItemQuantity(event.itemId, event.quantity);
        } else {
            this.removeItem(event.itemId);
        }
    }

    overlayClick(event: MouseEvent) {
        if (event.target === this.overlayRef.nativeElement) {
            this.close.emit();
        }
    }

    private adjustItemQuantity(id: string, qty: number) {
        this.dataService.mutate<AdjustItemQuantityMutation, AdjustItemQuantityMutationVariables>(ADJUST_ITEM_QUANTITY, {
            id,
            qty,
        }).pipe(
            take(1),
        ).subscribe(({ adjustOrderLine }) => {
            switch (adjustOrderLine.__typename) {
                case 'Order':
                    break;
                case 'InsufficientStockError':
                case 'NegativeQuantityError':
                case 'OrderLimitError':
                case 'OrderModificationError':
                    this.notificationService.error(adjustOrderLine.message).subscribe();
                    break;
            }
        });
    }

    private removeItem(id: string) {
        this.dataService.mutate<RemoveItemFromCartMutation, RemoveItemFromCartMutationVariables>(REMOVE_ITEM_FROM_CART, {
            id,
        }).pipe(
            take(1),
        ).subscribe();
    }
}
