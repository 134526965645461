import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';

export function setCanonicalURL(url: string, platformId: object) {
    if (isPlatformBrowser(platformId)) {
        const head = document.getElementsByTagName('head')[0];
        let element: HTMLLinkElement|null = head.querySelector(`link[rel='canonical']`) || null;
        if (element === null) {
            element = document.createElement('link');
            head.appendChild(element);
        }
        element.setAttribute('rel', 'canonical');
        element.setAttribute('href', `${environment.shopHost}${url}`);
    }
}

export function removeCanonicalURL(url: string, platformId: object) {
    if (isPlatformBrowser(platformId)) {
        const head = document.getElementsByTagName('head')[0];
        const elements: NodeListOf<HTMLLinkElement> = head.querySelectorAll(`link[rel='canonical']`);
        elements.forEach(element => {
            if (element.getAttribute('href') === `${environment.shopHost}${url}`) {
                head.removeChild(element);
            }
        });
    }
}
