import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { Asset, GetProductAllDetailQuery, SearchProductsQuery, SearchVariantsQuery } from '../../../common/generated-types';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { CartService } from '../../../core/providers/cart/cart.service';
import { AddToCartResult, CollectionItemType } from '../../../common/enums';
import { ActivatedRoute } from '@angular/router';
import { extractStoreUrl } from '../../../common/utils/extract-store-url';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'vsf-product-card-v2',
    templateUrl: './product-card-v2.component.html',
    styleUrls: ['./product-card-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardV2Component implements OnInit, AfterViewInit {
    @Input() item: SearchProductsQuery['search']['items'][number] | SearchVariantsQuery['search']['items'][number];
    @Input() displayType: CollectionItemType = CollectionItemType.Product;
    @Input() displaySubtitle: boolean = false;
    @Input() displayPrice: boolean = false;
    @Input() displayBuyButton: boolean = false;
    @Input() displayAlternativeImage: boolean = false;
    @Input() imgSize: 'sm-h' | 'sm-v' | 'md' | 'lg' = 'md';
    @Input() imgType: 'square' | 'rectangle' = 'rectangle';

    msrp: number | null = null;
    discountPercentage: number | null = null;
    inFlight = false;
    inStock = true;
    productBaseUrl = '';
    itemName: string;
    itemPrice: number | null = null;
    featuredAsset: Asset | null;
    hover: boolean = false;
    cardWidth = 0;
    cardHeight = 0;
    private subscription = new Subscription();

    constructor(
        private cartService: CartService,
        private changeDetector: ChangeDetectorRef,
        private route: ActivatedRoute,
        private logger: NGXLogger,
        private elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {}

    ngOnInit() {
        this.productBaseUrl = `${extractStoreUrl(this.route.snapshot)}/dp`;
        if (this.item) {
            this.inStock = this.item.inStock;
            if(this.displayType === CollectionItemType.Product) {
                const product = this.item as SearchProductsQuery['search']['items'][number];
                this.itemName = product.productName;
                this.featuredAsset = product.productAsset as Asset;
            } else if(this.displayType === CollectionItemType.ProductVariant) {
                const variant = this.item as SearchVariantsQuery['search']['items'][number];
                this.itemName = variant.productVariantName;
                this.featuredAsset = variant.productVariantAsset as Asset;
            }
            this.updatePricingInfo();
            this.changeDetector.markForCheck();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.calculateCardSize();
        });
    }

    updatePricingInfo() {
        if(this.displayType === CollectionItemType.Product) {
            const product = this.item as SearchProductsQuery['search']['items'][number];
            if(product.price?.__typename === 'PriceRange' && product.price.min !== null && product.price.max !== null && product.price.min === product.price.max) {
                this.itemPrice = product.price.min;
            } else if(product.price?.__typename === 'SinglePrice' && product.price.value !== null) {
                this.itemPrice = product.price.value;
            }
            if(product.productVariantMSRP) {
                this.msrp = product.productVariantMSRP;
            }
        } else if(this.displayType === CollectionItemType.ProductVariant) {
            const variant = this.item as SearchVariantsQuery['search']['items'][number];
            if(variant.price?.__typename === 'PriceRange' && variant.price.min !== null && variant.price.max !== null && variant.price.min === variant.price.max) {
                this.itemPrice = variant.price.min;
            } else if(variant.price?.__typename === 'SinglePrice' && variant.price.value !== null) {
                this.itemPrice = variant.price.value;
            }
            if(variant.productVariantMSRP) {
                this.msrp = variant.productVariantMSRP;
            }
        }
        if (this.itemPrice && this.msrp && this.msrp > this.itemPrice) {
            this.discountPercentage = ((this.msrp - this.itemPrice) / this.msrp) * 100;
        }
    }

    onMouseEnter() {
        this.hover = true;
        if(this.displayType === CollectionItemType.Product) {
            const product = this.item as SearchProductsQuery['search']['items'][number];
            if (this.displayAlternativeImage && product?.productAlternativeAsset) {
                this.featuredAsset = product?.productAlternativeAsset as Asset;
            }
        } else if(this.displayType === CollectionItemType.ProductVariant) {
            const variant = this.item as SearchVariantsQuery['search']['items'][number];
            if (this.displayAlternativeImage && variant?.productVariantAlternativeAsset) {
                this.featuredAsset = variant?.productVariantAlternativeAsset as Asset;
            }
        }
        this.changeDetector.markForCheck();
    }

    onMouseLeave() {
        this.hover = false;
        if(this.displayType === CollectionItemType.Product) {
            const product = this.item as SearchProductsQuery['search']['items'][number];
            this.featuredAsset = product.productAsset as Asset;
        } else if(this.displayType === CollectionItemType.ProductVariant) {
            const variant = this.item as SearchVariantsQuery['search']['items'][number];
            this.featuredAsset = variant.productVariantAsset as Asset;
        }
        this.changeDetector.markForCheck();
    }

    onButtonClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.addToCartAndOpenDrawer();
    }

    addToCartAndOpenDrawer() {
        if (!this.item) {
            return;
        }
        this.inFlight = true;
        this.changeDetector.markForCheck();

        this.subscription.add(
          this.cartService.addToCartAndOpenDrawer(this.item.productVariantId).subscribe({
            next: (result: AddToCartResult) => {
                this.inFlight = false;
                if (result === AddToCartResult.OUT_OF_STOCK) {
                    this.inStock = false;
                }
                this.changeDetector.markForCheck();
            },
            error: err => {
              this.inFlight = false;
              this.logger.error('Error in addToCartAndOpenDrawer', err);
              this.changeDetector.markForCheck();
            },
          }),
        );
    }

    calculateCardSize() {
        if (!isPlatformBrowser(this.platformId)) {
            // initialize card size
            this.cardWidth = this.route.snapshot.routeConfig?.path === 'search' ? 243 : 223;
            this.cardHeight = this.cardWidth;
            return;
        }
        const rect = this.elementRef.nativeElement.getBoundingClientRect();
        if (this.route.snapshot.routeConfig?.path === 'search') {
            this.cardWidth = rect.width;
            this.cardHeight = rect.width;
        } else {
            this.cardWidth = rect.width - 20;
            this.cardHeight = rect.width - 20;
        }
        this.changeDetector.markForCheck();
    }
}
