import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { notNullOrUndefined } from '../../common/utils/not-null-or-undefined';
import { GET_STORESITE_INFO } from '../../common/graphql/documents.graphql';
import { Collection, GetStoreInfoQuery, GetStoreInfoQueryVariables, ProductReview } from '../../common/generated-types';
import { DataService } from '../../core/providers/data/data.service';
import { NGXLogger } from 'ngx-logger';
import { isPlatformBrowser } from '@angular/common';
import { safeJSONParse } from '../../common/utils/safe-json-parser';
import { CollectionGroupData, ColorConfig, EmailSubscriptionData, FeaturedCollectionData, HeroSectionData, LayoutOptionData, MarketingBannerData } from '../../common/interfaces';
import { removeCanonicalURL, setCanonicalURL } from '../../common/utils/canonical-url';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'vsf-store-main-page',
  templateUrl: './store-main-page.component.html',
  styleUrls: ['./store-main-page.component.scss'],
})
export class StoreMainPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  storeInfo: GetStoreInfoQuery['storeSite'];
  identifier: string;
  sellerChannelId: string;

  layoutOrder: LayoutOptionData[];
  colorConfig: ColorConfig | null;

  heroSection: any | null;
  featuredProductReviewList: ProductReview[];
  emailSubscriptionSection: EmailSubscriptionData | null;

  marketingBanners: MarketingBannerData[];
  featuredCollectionList: FeaturedCollectionData[];
  collectionListForShopByGroupList: CollectionGroupData[];
  collectionForSeriesHeroSectionList: any[];

  isMobile: boolean | null = null;
  readyToDisplay$ = new BehaviorSubject<boolean>(false);

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private metaService: Meta,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: object,
              private logger: NGXLogger) { }

  ngOnInit() {
    this.checkViewport();
    const storeCode$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('identifier')),
      takeUntil(this.destroy$),
      filter(notNullOrUndefined),
    );
    storeCode$.subscribe(identifier => {
      setCanonicalURL(`/store/${identifier}`, this.platformId);
      this.dataService.query<GetStoreInfoQuery, GetStoreInfoQueryVariables>(GET_STORESITE_INFO, {
        identifier,
      }).subscribe(result => {
        this.storeInfo = result.storeSite;
        this.identifier = this.storeInfo?.identifier || '';
        this.sellerChannelId = this.storeInfo?.sellerChannelId || '';
        this.layoutOrder = (this.storeInfo?.layoutOrder || [])
          .map(jsonString => {
            const layoutOption =  safeJSONParse<LayoutOptionData>(jsonString, this.logger);
            if (layoutOption) {
              // Compute desktop width percentage
              layoutOption.widthPercentage = layoutOption.width && layoutOption.width > 0
                ? `${layoutOption.width * 100}%`
                : '100%';
              // Compute mobile width percentage
              layoutOption.mobileWidthPercentage = layoutOption.mobileWidth && layoutOption.mobileWidth > 0
                ? `${layoutOption.mobileWidth * 100}%`
                : '100%';
              // Ensure margin properties are numbers (fallback to 0 if undefined)
              layoutOption.topMargin = layoutOption.topMargin || 0;
              layoutOption.bottomMargin = layoutOption.bottomMargin || 0;
              layoutOption.mobileTopMargin = layoutOption.mobileTopMargin || 0;
              layoutOption.mobileBottomMargin = layoutOption.mobileBottomMargin || 0;
            }
            return layoutOption;
          })
          .filter(notNullOrUndefined);
        this.colorConfig = safeJSONParse<ColorConfig>(this.storeInfo?.colorConfig, this.logger);
        this.heroSection = this.storeInfo?.heroSectionData || null;
        this.featuredProductReviewList = this.storeInfo?.featuredProductReviewListData as ProductReview[] || [];
        this.emailSubscriptionSection = this.storeInfo?.emailSubscriptionData as EmailSubscriptionData;
        this.marketingBanners = this.storeInfo?.marketingBanners as any[] || [];
        this.featuredCollectionList = this.storeInfo?.featuredCollectionDataList as FeaturedCollectionData[] || [];
        this.collectionListForShopByGroupList = this.storeInfo?.collectionGroupDataList as CollectionGroupData[] || [];
        this.collectionForSeriesHeroSectionList = this.storeInfo?.collectionForSeriesListData?.map(collection => { return collection.heroSection })  || [];
        this.computeLayoutStyles();
        this.readyToDisplay$.next(true);
        this.logger.info('Store Info', JSON.stringify(this.storeInfo));
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  ngOnDestroy() {
    removeCanonicalURL(`/store/${this.identifier}`, this.platformId);
    this.removeMetadata();
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkViewport();
  }

  private checkViewport() {
    if (isPlatformBrowser(this.platformId)) {
      const mobileIndicator = document.getElementById('mobile-indicator');
      if (mobileIndicator && window) {
        const wasMobile = this.isMobile;
        this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
        if (this.isMobile !== wasMobile) {
          // Recompute styles when isMobile changes
          this.computeLayoutStyles();
          this.changeDetectorRef.detectChanges();
        }
      }
    }
  }

  private removeMetadata() {
    this.metaService.removeTag('name="description"');
    this.metaService.removeTag('property="og:title"');
    this.metaService.removeTag('property="og:description"');
    this.metaService.removeTag('property="og:type"');
    this.metaService.removeTag('property="og:url"');
    this.metaService.removeTag('property="og:image"');
    this.metaService.removeTag('name="twitter:card"');
    this.metaService.removeTag('name="twitter:title"');
    this.metaService.removeTag('name="twitter:description"');
    this.metaService.removeTag('name="twitter:image"');
  }

  computeLayoutStyles() {
    if (this.layoutOrder) {
      this.layoutOrder.forEach(layout => {
        const styles: { [key: string]: string } = {};

        if (this.isMobile) {
          styles.width = layout.mobileWidthPercentage || '100%';
          styles['margin-top'] = `${layout.mobileTopMargin}px`;
          styles['margin-bottom'] = `${layout.mobileBottomMargin}px`;
        } else {
          styles.width = layout.widthPercentage || '100%';
          styles['margin-top'] = `${layout.topMargin}px`;
          styles['margin-bottom'] = `${layout.bottomMargin}px`;
        }

        // Center the component
        styles['margin-left'] = 'auto';
        styles['margin-right'] = 'auto';

        layout.styles = styles;
      });
    }
  }
}