import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { GetChannelHeroQuery, GetProductAllDetailQuery } from '../../../common/generated-types';
import { setCanonicalURL, removeCanonicalURL } from '../../../common/utils/canonical-url';
import { DataService } from '../../../core/providers/data/data.service';
import { GET_CHANNEL_HERO } from '../../../common/graphql/documents.graphql';

@Component({
    selector: 'vsf-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    channel$: Observable<GetChannelHeroQuery['activeChannel']>;
    heroProductDetail$: Observable<GetProductAllDetailQuery['product']>;
    isMobile = true;
    channelHeroSection: any | null;
    currentCanonicalURL: string | null = null;

    constructor(
        private metaService: Meta,
        private dataService: DataService,
        private changeDetector: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: object
    ) { }

    ngOnInit() {
        this.checkViewport();
        this.channel$ = this.dataService.query<GetChannelHeroQuery>(GET_CHANNEL_HERO)
            .pipe(map((data:any) => data.activeChannel), take(1));
        this.channel$.subscribe((activeChannel) => {
            this.channelHeroSection = activeChannel?.heroSection;
            this.changeDetector.detectChanges();
        });
        const canonicalURL = '';
        setCanonicalURL(canonicalURL, this.platformId);
        this.currentCanonicalURL = canonicalURL;
    }

    ngOnDestroy(): void {
        this.removeHomePageMetadata();
        if (this.currentCanonicalURL) {
            removeCanonicalURL(this.currentCanonicalURL, this.platformId);
            this.currentCanonicalURL = null;
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    @HostListener('window:resize', [])
    onResize() {
        this.checkViewport();
    }

    private checkViewport() {
        if (isPlatformBrowser(this.platformId)) {
            const mobileIndicator = document.getElementById('mobile-indicator');
            if (mobileIndicator && window) {
                const isMobileTemp = this.isMobile;
                this.isMobile = window.getComputedStyle(mobileIndicator).display !== 'none';
                if (isMobileTemp !== this.isMobile) {
                    this.changeDetector.detectChanges();
                }
            }
        }
    }

    private removeHomePageMetadata() {
        this.metaService.removeTag('name="description"');
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:description"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('name="twitter:card"');
        this.metaService.removeTag('name="twitter:title"');
        this.metaService.removeTag('name="twitter:description"');
        this.metaService.removeTag('name="twitter:image"');
    }
}
